import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { Row } from "../Row";
import * as skTypes from "../../types/sk";
import * as materialTypes from "../../types/material";
import { CheckTree, Tree } from "../settings/CheckTree";
import { Check } from "../Check/Check";
import "../settings/settings.scss";
import "./AdditionalSKStyles.scss";

interface Props {
  purpose: "quizzer" | "team";
  seasonId: string;
  onGoBack: (settings: skTypes.StatsSettings) => void;
  defaultSettings: skTypes.StatsSettings;
  books?: number[];
  isPractice?: boolean;
}

export function StatsSettings(props: Props) {
  const { selectedSeason } = useSelector((state: RootStore) => state.sk);
  if (!selectedSeason) return <Navigate to="/sk/seasons" />;
  return <StatsSettingsComponent {...props} season={selectedSeason} />;
}

function StatsSettingsComponent(props: Props & { season: skTypes.Season }) {
  const { material } = useSelector((state: RootStore) => state.material);
  const { selectedEvent } = useSelector((state: RootStore) => state.sk);

  const getDefaultQuizzesSelected = (useProps: boolean): Tree => {
    return Object.fromEntries(
      props.season.events.map((event: skTypes.Event) => {
        return [
          event.eventId,
          {
            title: event.eventName,
            children: Object.fromEntries(
              event.quizzes.map((quiz: skTypes.QuizSummary) => {
                return [
                  quiz.quizId,
                  {
                    title: `${
                      quiz.quizName ? `${quiz.quizName} - ` : ""
                    }Round ${quiz.round}, Site ${quiz.site}`,
                    checked: useProps
                      ? props.defaultSettings.quizIds.has(quiz.quizId)
                      : false,
                  },
                ];
              })
            ),
          },
        ];
      })
    );
  };
  const [quizzesSelected, setQuizzesSelected] = useState<Tree>(
    getDefaultQuizzesSelected(true)
  );

  const getDefaultChaptersSelected = (useProps: boolean): Tree => {
    if (!props.books) return {};
    return Object.fromEntries(
      props.books.map((book: number) => {
        return [
          String(book),
          {
            title: material[book].bookName,
            children: Object.fromEntries(
              material[book].chapters.map(
                (chapter: materialTypes.Verse[], chapterIndex: number) => {
                  return [
                    String(chapterIndex),
                    {
                      title: `${material[book].bookName} ${chapterIndex + 1}`,
                      checked: useProps
                        ? props.defaultSettings.chapters.has(
                            `${book}-${chapterIndex}`
                          )
                        : false,
                    },
                  ];
                }
              )
            ),
          },
        ];
      })
    );
  };
  const [chaptersSelected, setChaptersSelected] = useState<Tree>(
    getDefaultChaptersSelected(true)
  );

  const [teamsSelected, setTeamsSelected] = useState<string[]>(
    Array.from(props.defaultSettings.teamIds)
  );
  const [minTeams, setMinTeams] = useState<number>(
    props.defaultSettings.minTeams
  );
  const getDefaultQuizzersSelected = (useProps: boolean): Tree => {
    return Object.fromEntries(
      props.season.teams.map((team: skTypes.Team) => {
        return [
          team.teamId,
          {
            title: team.teamName,
            children: Object.fromEntries(
              team.quizzers.map((quizzer: skTypes.Quizzer) => {
                return [
                  quizzer.quizzerId,
                  {
                    title: quizzer.quizzerName,
                    checked: useProps
                      ? props.defaultSettings.quizzerIds.has(quizzer.quizzerId)
                      : false,
                  },
                ];
              })
            ),
          },
        ];
      })
    );
  };
  const [quizzersSelected, setQuizzersSelected] = useState<Tree>(
    getDefaultQuizzersSelected(true)
  );
  const [minQuizzers, setMinQuizzers] = useState<number>(
    props.defaultSettings.minQuizzers
  );

  const [quizmasters, setQuizmasters] = useState<Set<string>>(
    new Set(props.defaultSettings.quizmasters)
  );

  const [jumpTypes, setJumpTypes] = useState<Set<skTypes.JumpType>>(
    new Set(props.defaultSettings.jumpTypes)
  );

  // Misc
  const [includeOvertime, setIncludeOvertime] = useState<boolean>(
    props.defaultSettings.includeOvertime
  );
  const [quoteOnly, setQuoteOnly] = useState<boolean>(
    props.defaultSettings.quoteOnly
  );
  const [afterPause, setAfterPause] = useState<boolean>(
    props.defaultSettings.afterPause
  );

  const getMinNumberInput = (
    getter: number,
    setter: (value: number) => void,
    max: number
  ): React.ReactElement => {
    return (
      <input
        type="number"
        value={getter}
        onChange={(e) => {
          const value = Number.parseInt(e.target.value);
          if (value >= 0 && (!max || value <= max)) setter(value);
        }}
        style={{ width: 40 }}
      />
    );
  };

  return (
    <>
      <Row style={{ marginBottom: 10 }}>
        <button
          className="back-button"
          onClick={() => {
            const quizIdList: Set<string> = new Set<string>();
            const quizzerIdList: Set<string> = new Set<string>();
            const includeChapterList: Set<string> = new Set<string>();
            Object.keys(quizzesSelected).forEach((key: string) => {
              Object.keys(quizzesSelected[key].children).forEach(
                (subKey: string) => {
                  if (quizzesSelected[key].children[subKey].checked)
                    quizIdList.add(subKey);
                }
              );
            });

            Object.keys(quizzersSelected).forEach((key: string) => {
              Object.keys(quizzersSelected[key].children).forEach(
                (subKey: string) => {
                  if (quizzersSelected[key].children[subKey].checked)
                    quizzerIdList.add(subKey);
                }
              );
            });

            Object.keys(chaptersSelected).forEach((key: string) => {
              Object.keys(chaptersSelected[key].children).forEach(
                (subKey: string) => {
                  if (chaptersSelected[key].children[subKey].checked) {
                    includeChapterList.add(`${key}-${subKey}`);
                  }
                }
              );
            });

            const getMinNumber = (enteredMin: number, list: any[]): number => {
              const length = list.length;
              if (enteredMin > length) return length;
              if (enteredMin === 0 && length > 0) return 1;
              return Math.min(enteredMin, length);
            };

            props.onGoBack({
              quizIds: quizIdList,
              teamIds: new Set<string>(teamsSelected),
              quizzerIds: quizzerIdList,
              chapters: includeChapterList,
              minQuizzers: getMinNumber(minQuizzers, Array.from(quizzerIdList)),
              minTeams: getMinNumber(minTeams, teamsSelected),
              quizmasters,
              includeOvertime,
              quoteOnly,
              afterPause,
              jumpTypes,
            });
          }}
        >
          Go Back
        </button>
        <h1 style={{ marginLeft: 10 }}>{`${
          props.purpose === "quizzer" ? "Quizzer" : "Team"
        } Stats Settings`}</h1>
      </Row>
      <Row
        style={{
          overflowX: "visible",
          flexWrap: "nowrap",
        }}
      >
        <div className="stats-settings-col">
          <div className="stats-settings-header">
            <h2>Filter by Quiz:</h2>
            <p>Only these quizzes will be included.</p>
          </div>
          <button
            className="link"
            onClick={() => {
              setQuizzesSelected(getDefaultQuizzesSelected(false));
            }}
          >
            Clear
          </button>
          <CheckTree
            data={quizzesSelected}
            height={500}
            onChange={setQuizzesSelected}
            autoOpen={new Set<string>([selectedEvent])}
          />
        </div>
        {!props.isPractice && (
          <>
            <div className="stats-settings-col">
              <div className="stats-settings-header">
                <h2>Filter Quizzes by up to Three Teams:</h2>
                <p>
                  Require at least {getMinNumberInput(minTeams, setMinTeams, 3)}{" "}
                  of the following teams to be in the quiz.
                </p>
              </div>
              <button
                className="link"
                onClick={() => {
                  setTeamsSelected([]);
                  setMinTeams(0);
                }}
              >
                Clear
              </button>
              <div className={"settings-scrolling stats-settings-scrolling"}>
                {props.season.teams.map((team: skTypes.Team) => (
                  <div key={team.teamId}>
                    <Check
                      checked={teamsSelected.includes(team.teamId)}
                      onClick={(checked: boolean) => {
                        if (checked) {
                          if (teamsSelected.length === 3) return;
                          const newTeams = [...teamsSelected];
                          newTeams.push(team.teamId);
                          setTeamsSelected(newTeams);
                        } else {
                          const newTeams = [...teamsSelected];
                          newTeams.splice(newTeams.indexOf(team.teamId), 1);
                          setTeamsSelected(newTeams);
                        }
                      }}
                    >
                      {team.teamName}
                    </Check>
                  </div>
                ))}
              </div>
            </div>
            <div className="stats-settings-col">
              <div className="stats-settings-header">
                <h2>Filter Questions by Quizzer:</h2>
                <p>
                  Require at least{" "}
                  {getMinNumberInput(minQuizzers, setMinQuizzers, 15)} of the
                  following quizzers to be on the chairs.
                </p>
              </div>
              <button
                className="link"
                onClick={() => {
                  setQuizzersSelected(getDefaultQuizzersSelected(false));
                  setMinQuizzers(0);
                }}
              >
                Clear
              </button>
              <CheckTree
                data={quizzersSelected}
                height={500}
                onChange={setQuizzersSelected}
                checkWholeTeam={false}
              />
            </div>
          </>
        )}
        {props.books && (
          <div className="stats-settings-col">
            <div className="stats-settings-header">
              <h2>Filter Questions by Material:</h2>
              <p>Include only questions from the following chapters:</p>
            </div>
            <button
              className="link"
              onClick={() => {
                setChaptersSelected(getDefaultChaptersSelected(false));
              }}
            >
              Clear
            </button>
            <CheckTree
              data={chaptersSelected}
              height={500}
              onChange={setChaptersSelected}
            />
          </div>
        )}
        <div className="stats-settings-col">
          <div className="stats-settings-header">
            <h2>Filter by Jump Type:</h2>
            <p>Only jumps with these jump types will be included.</p>
          </div>
          <button
            className="link"
            onClick={() => {
              setJumpTypes(new Set<skTypes.JumpType>());
            }}
          >
            Clear
          </button>
          <div className={"settings-scrolling stats-settings-scrolling"}>
            {(
              Object.keys(skTypes.jumpTypeDescription) as skTypes.JumpType[]
            ).map((key) => (
              <Check
                key={key}
                checked={jumpTypes.has(key)}
                onClick={(checked) => {
                  const newJumpTypes = new Set(jumpTypes);
                  if (checked) {
                    newJumpTypes.add(key);
                  } else {
                    newJumpTypes.delete(key);
                  }
                  setJumpTypes(newJumpTypes);
                }}
              >
                {skTypes.jumpTypeDescription[key]}
              </Check>
            ))}
          </div>
        </div>
        {props.season.quizmasters && props.season.quizmasters.length > 0 && (
          <div className="stats-settings-col">
            <div className="stats-settings-header">
              <h2>Filter Quizzes by Quizmaster:</h2>
              <p>Include only quizzes that had the following quizmaster(s):</p>
            </div>
            <button
              className="link"
              onClick={() => {
                setQuizmasters(new Set<string>());
              }}
            >
              Clear
            </button>
            <div className="settings-scrolling" style={{ marginLeft: 0 }}>
              {props.season.quizmasters.map((qm: string) => (
                <div key={qm}>
                  <Check
                    checked={quizmasters.has(qm)}
                    onClick={(checked: boolean) => {
                      const newSet = new Set(quizmasters);
                      if (checked) newSet.add(qm);
                      else newSet.delete(qm);

                      setQuizmasters(newSet);
                    }}
                  >
                    {qm}
                  </Check>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="stats-settings-col">
          <div className="stats-settings-header">
            <h2>Miscellaneous Settings</h2>
          </div>
          <button
            className="link"
            onClick={() => {
              setIncludeOvertime(props.defaultSettings.includeOvertime);
              setQuoteOnly(false);
            }}
          >
            Reset
          </button>
          <div className={"settings-scrolling stats-settings-scrolling"}>
            <Check checked={includeOvertime} onClick={setIncludeOvertime}>
              Include Overtime
            </Check>
            <Check
              checked={quoteOnly}
              onClick={setQuoteOnly}
              style={{ marginTop: 4 }}
            >
              Only Include Quotes
            </Check>
            <Check
              checked={afterPause}
              onClick={setAfterPause}
              style={{ marginTop: 4 }}
            >
              Only Questions After a Pause
            </Check>
            <p style={{ fontSize: 12, marginTop: 0 }}>
              Only include questions immediately following a quiz pause, such as
              halftime or substitution.
            </p>
          </div>
        </div>
      </Row>
    </>
  );
}
