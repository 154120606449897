import { Link } from "react-router-dom";
import { Row } from "../../../components/Row";
import { EditPageForm } from "./EditPageForm";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";

export function CreateTournamentPage() {
  useDocumentTitle("New Page - BQA Tournaments");
  return (
    <div className="page">
      <Row>
        <Link
          className="back-button"
          to="/tournaments"
          style={{ marginRight: 10 }}
        >
          Go Back
        </Link>
        <h1>Create Page</h1>
      </Row>
      <EditPageForm mode="CREATE" page={{}} />
    </div>
  );
}
