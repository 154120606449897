interface RulingProps {
  onClick: (type: number) => void; // 0=Correct, 1=Incorrect, 2=Throwout
}

export function Ruling(props: RulingProps) {
  return (
    <div style={{ display: "flex" }}>
      <button
        className="clickable"
        style={{ maxWidth: "calc(33vw - 20px)" }}
        onClick={() => props.onClick(0)}
      >
        Correct
      </button>
      <button
        className="clickable"
        style={{
          marginLeft: 15,
          marginRight: 15,
          maxWidth: "calc(33vw - 20px)",
        }}
        onClick={() => props.onClick(1)}
      >
        Incorrect
      </button>
      <button
        className="clickable"
        style={{ maxWidth: "calc(33vw - 20px)" }}
        onClick={() => props.onClick(2)}
      >
        Throwout
      </button>
    </div>
  );
}
