import React, { CSSProperties } from "react";

interface Props {
  children: React.ReactNode;
  onClick: () => void;
  style?: CSSProperties
}
export function PlusMinusButton(props: Props) {
  return (
    <button className="clickable"
      style={{fontSize: 20, width: 30, height: 30, paddingTop: 5, ...props.style}}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}