import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { DataTable } from "../../components/DataTable/DataTable";
import { TournamentLink } from "../../components/TournamentComponents/TournamentLink";
import { TournamentTrivia } from "../../components/TournamentComponents/TournamentTrivia";
import {
  getTournamentHeadToHead,
  getTournamentResults,
  setSelectedPage,
} from "../../state/actions/tournamentActions";
import { CircularProgress } from "@mui/material";
import { BQATabs } from "../../components/BQATabs/BQATabs";
import {
  Achievement,
  HeadToHead,
  QuizzerPage,
  TTierDescriptions,
  TTierRank,
  TeamPage,
  TournamentTiers,
} from "../../types/tournamentTypes";
import { getDateRangeDisplay } from "../../util/dateUtils";
import { DateInput, Selection, StringInput } from "../../components/Inputs";
import { PopoverContent } from "../../components/Popover";
import { xor } from "../../util/generalUtil";

export function CompetitorDetails({ page }: { page: QuizzerPage | TeamPage }) {
  const [tab, setTab] = useState("overview");

  const getCurrentTournamentContent = () => {
    if (!page.currentTournament) return null;

    if (page.pageType === "QUIZZER") {
      return (
        <div>
          <h3 style={{ marginTop: 0 }}>Currently Quizzing</h3>
          <p>
            {"Competing in "}
            <TournamentLink
              pageId={page.currentTournament.tId}
              secondaryId={page.currentTournament.sId}
            />
            {" on "}
            <TournamentLink
              pageId={
                page.currentTournament.teamId || page.currentTournament.team
              }
              text={page.currentTournament.team}
            />
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <h3 style={{ marginTop: 0 }}>Current Roster</h3>
          <p>
            {"Competing in "}
            <TournamentLink
              pageId={page.currentTournament.tId}
              secondaryId={page.currentTournament.sId}
            />
            {" with "}
            {page.currentTournament.quizzers.map((quizzer, index) => (
              <React.Fragment key={quizzer}>
                {index !== 0 &&
                  (index === page.currentTournament.quizzers.length - 1
                    ? ", and "
                    : ", ")}
                <TournamentLink pageId={quizzer} />
              </React.Fragment>
            ))}
          </p>
        </div>
      );
    }
  };

  const getAchievementsContent = () => {
    if (!page.achievements?.length) return null;
    return (
      <>
        <h3 style={{ marginTop: 0, fontSize: 16 }}>Top 10 Achievements</h3>
        <DataTable<Achievement>
          data={page.achievements}
          getKey={(obj) => `${obj.tournament.tId}-${obj.tournament.sId}`}
          columns={[
            {
              title: "Date",
              property: "date",
              render: (obj) => getDateRangeDisplay(obj.tournament.endDate),
            },
            {
              title: "Tier",
              property: "tier",
              render: (obj) => TTierDescriptions[obj.tournament.tier],
            },
            {
              title: "Place",
              property: "place",
              render: (obj) => {
                let placeBackground = "";
                const place = obj.place;
                if (place === 1) {
                  placeBackground = "gold";
                } else if (place === 2) {
                  placeBackground = "silver";
                } else if (place === 3) {
                  placeBackground = "#CD7F32";
                } else if (place <= 5) {
                  placeBackground = "lightgreen";
                } else if (place <= 9) {
                  placeBackground = "pink";
                } else {
                  placeBackground = "lightblue";
                }
                return (
                  <span
                    style={{
                      backgroundColor: placeBackground,
                      color: "black",
                      width: 24,
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    {place}
                  </span>
                );
              },
            },
            {
              title: "Tournament",
              property: "tournamentName",
              render: (obj) => (
                <TournamentLink
                  pageId={obj.tournament.tId}
                  secondaryId={obj.tournament.sId}
                  navigateState={{
                    tournamentSelectedTeam: obj.team?.teamName || page.pageId,
                  }}
                />
              ),
            },
            {
              title: "Team",
              property: "team",
              render: (obj) => (
                <TournamentLink
                  pageId={obj.team?.teamId || obj.team?.teamName}
                  text={obj.team?.teamName || obj.team?.teamId}
                  fallbackContent=""
                  navigateState={{
                    alternatePage: {
                      pageId: obj.tournament.tId,
                      secondaryId: obj.tournament.sId,
                    },
                  }}
                />
              ),
              visible: page.pageType === "QUIZZER",
            },
          ]}
          style={{ marginBottom: 10, maxWidth: 650 }}
          footer={
            <button
              className="link"
              onClick={() => setTab("results")}
              style={{ fontSize: 14, marginTop: 4 }}
            >
              Get More Results
            </button>
          }
        />
      </>
    );
  };

  return (
    <>
      <BQATabs
        tabs={[
          {
            title: "Overview",
            key: "overview",
          },
          {
            title: "Results",
            key: "results",
          },
          {
            title: "Head to Head",
            key: "head-to-head",
          },
        ]}
        value={tab}
        onChange={setTab}
      />
      {tab === "overview" && (
        <>
          {getCurrentTournamentContent()}
          {getAchievementsContent()}
          <TournamentTrivia page={page} />
        </>
      )}
      {tab === "results" && <ResultsDisplay page={page} />}
      {tab === "head-to-head" && <HeadToHeadDisplay page={page} />}
    </>
  );
}

function ResultsDisplay({ page }: { page: QuizzerPage | TeamPage }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTournamentResults(page));
  }, []);
  const hasPrize = useMemo(() => {
    return page.results?.some((result) => !!result.prize);
  }, [page.results]);

  return (
    <>
      <h3 style={{ marginTop: 0 }}>Results</h3>
      {page.results ? (
        <DataTable
          data={page.results || []}
          fallbackContent={<p>No Results Found</p>}
          columns={[
            {
              title: "Date",
              property: "date",
              render: (obj) => getDateRangeDisplay(obj.endDate),
              sortFunc: (obj) => obj.endDate,
            },
            {
              title: "Tournament",
              property: "tournament",
              render: (obj) => (
                <TournamentLink
                  pageId={obj.tournamentId}
                  secondaryId={obj.secondaryId}
                  navigateState={{
                    tournamentSelectedTeam: obj.teamName || page.pageId,
                  }}
                />
              ),
              sortFunc: (obj) => `${obj.tournamentId}-${obj.secondaryId}`,
            },
            {
              title: "Tier",
              property: "tier",
              render: (obj) => TTierDescriptions[obj.tier],
              sortFunc: (obj) => TTierRank[obj.tier],
              defaultSortDirection: "desc",
            },
            {
              title: "Place",
              property: "place",
              render: (obj) => (
                <span>
                  <b>{obj.place || "?"}</b> / {obj.totalTeams}
                </span>
              ),
              sortFunc: (obj) => obj.place || 1000,
            },
            {
              title: "Team",
              property: "team",
              visible: page.pageType === "QUIZZER",
              render: (obj) => (
                <TournamentLink
                  pageId={obj.teamId || obj.teamName}
                  text={obj.teamName}
                />
              ),
              sortFunc: (obj) => obj.teamName || "",
            },
            {
              title: "Individual",
              property: "indiv",
              visible: page.pageType === "QUIZZER",
              render: (obj) =>
                obj.totalQuizzers ? (
                  <span>
                    <b>{obj.indivPlace || "?"}</b> / {obj.totalQuizzers}
                  </span>
                ) : undefined,
              sortFunc: (obj) => obj.indivPlace || 10000,
            },
            {
              title: "Prize",
              property: "prize",
              visible: hasPrize,
              render: (obj) => (obj.prize ? `$${obj.prize}` : undefined),
              sortFunc: (obj) => obj.prize || 0,
              defaultSortDirection: "desc",
            },
          ]}
          getKey={(obj) => `${obj.tournamentId}-${obj.secondaryId}`}
        />
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

function HeadToHeadDisplay({ page }: { page: QuizzerPage | TeamPage }) {
  const [opponent, setOpponent] = useState("");
  const [showOptional, setShowOptional] = useState(false);
  const [tier, setTier] = useState<TournamentTiers | undefined>();
  const [minDate, setMinDate] = useState<string | undefined>();
  const [maxDate, setMaxDate] = useState<string | undefined>();
  const [searchOpponent, setSearchOpponent] = useState("");
  const [showH2H, setShowH2H] = useState(false);
  const dispatch = useDispatch();

  const getTotalRecord = () => {
    if (!page.headToHead) return "";
    const won = page.headToHead.filter((h2h) => h2h.wasWon).length;
    return `${won}-${page.headToHead.length - won}`;
  };

  const getTotalPoints = (isSelf: boolean) => {
    if (!page.headToHead) return "";
    const index = isSelf ? 0 : 1;
    let points = 0;
    let errors = 0;
    page.headToHead.forEach((h2h) => {
      if (h2h.indvResults) {
        points += h2h.indvResults[index].correct;
        errors += h2h.indvResults[index].errors;
      } else {
        points += h2h.quizResults[index].points;
        errors += h2h.quizResults[index].errors;
      }
    });
    return `${
      page.pageType === "QUIZZER" ? "Correct" : "Points"
    }: ${points}, Errors: ${errors}`;
  };

  const getScoreContent = (obj: HeadToHead, person: 1 | 2) => {
    const index = person - 1;
    return (
      <PopoverContent
        style={{
          backgroundColor: xor([obj.wasWon, person === 2])
            ? obj.quizResults[person - 1].p === 1
              ? "green"
              : "#999900"
            : "",
          padding: 2,
        }}
        content={
          <>
            <p>
              {`${obj.quizResults[0].teamName} vs. ${obj.quizResults[1].teamName}`}
            </p>
            {obj.indvResults && (
              <p>
                {`${obj.indvResults[index].name}: ${obj.indvResults[index].correct}-${obj.indvResults[index].errors}`}
              </p>
            )}
          </>
        }
      >
        <>
          {obj.quizResults[index].p}.&nbsp;
          <b>{obj.quizResults[index].points}</b>&nbsp;
          {obj.quizResults[index].errors}
        </>
      </PopoverContent>
    );
  };
  return (
    <>
      <h3 style={{ marginTop: 0 }}>Head to Head</h3>
      <p>{`Enter the name of a ${
        page.pageType === "QUIZZER" ? "quizzer" : "team"
      } to see all head-to-head matches.`}</p>
      <StringInput
        caption="Opponent"
        value={opponent}
        onChange={setOpponent}
        placeholder="Opponent"
      />
      {showOptional ? (
        <>
          <Selection
            caption="Minimum Tier"
            value={tier}
            values={TTierDescriptions}
            onChange={setTier}
            placeholder="Any"
          />
          <DateInput
            value={minDate}
            onChange={setMinDate}
            caption="Start Date"
          />
          <DateInput value={maxDate} onChange={setMaxDate} caption="End Date" />
        </>
      ) : (
        <div style={{ marginTop: 10 }}>
          <button
            className="link"
            style={{ paddingLeft: 0 }}
            onClick={() => setShowOptional(true)}
          >
            Show Optional Filters
          </button>
        </div>
      )}

      {opponent && (
        <div>
          <button
            className="clickable"
            style={{ marginTop: 10, marginBottom: 10 }}
            onClick={() => {
              dispatch(setSelectedPage({ ...page, headToHead: undefined }));
              setShowH2H(true);
              setSearchOpponent(opponent);
              dispatch(
                getTournamentHeadToHead(page, opponent, tier, minDate, maxDate)
              );
            }}
          >
            Find Quizzes
          </button>
        </div>
      )}
      {showH2H &&
        (page.headToHead ? (
          <>
            <p>{`Opponent: ${searchOpponent}`}</p>
            {page.headToHead.length > 0 && (
              <>
                <p>{`Total Record: ${getTotalRecord()}`}</p>
                <p>{`${page.pageId} Scores: ${getTotalPoints(true)}`}</p>
                <p>{`Opponent Scores: ${getTotalPoints(false)}`}</p>
              </>
            )}
            <DataTable
              fallbackContent={<p>No Results Found</p>}
              data={page.headToHead}
              columns={[
                {
                  title: "Date",
                  property: "date",
                  render: (obj) => obj.date,
                },
                {
                  title: "Tournament",
                  property: "tournament",
                  render: (obj) => (
                    <TournamentLink
                      pageId={obj.tournamentId}
                      secondaryId={obj.secondaryId}
                    />
                  ),
                },
                {
                  title: "Quiz",
                  property: "quiz",
                  render: (obj) => (
                    <TournamentLink
                      pageId={obj.tournamentId}
                      secondaryId={obj.secondaryId}
                      navigateState={{
                        tournamentPageTab: obj.event,
                        selectedQuiz: `${obj.round}-${obj.site}`,
                      }}
                      text={`${obj.event} ${obj.quizDescription}`}
                    />
                  ),
                },
                {
                  title: page.pageId,
                  property: "person1",
                  render: (obj) => getScoreContent(obj, 1),
                },
                {
                  title: "Opponent",
                  property: "person2",
                  render: (obj) => getScoreContent(obj, 2),
                },
              ]}
              getKey={(obj) =>
                `${obj.tournamentId}-${obj.secondaryId}-${obj.event}-${obj.quizDescription}`
              }
            />
          </>
        ) : (
          <div style={{ marginTop: 20 }}>
            <CircularProgress />
          </div>
        ))}
    </>
  );
}
