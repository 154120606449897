import { SettingsData } from "../../types/settings";
import { SET_LOGIN, SetLogin } from "../actions/authentication";

export const SET_SETTINGS = "SET_SETTINGS";
export const SET_SELECTED_CHAPTERS = "SET_SELECTED_CHAPTERS";
export const SET_QUIZ_NOW_QUIZZERS = "SET_QUIZ_NOW_QUIZZERS";

const initialState: SettingsData = {
  quizNowQuizzers: Array(15).fill(""),
  qnBookName: false,
  qmSpeed: "MEDIUM",
  qmPrecise: false,

  selectedChapters: [],
  selectedSKBooks: [],

  touchscreen: "ontouchstart" in window,
  lightMode: false,

  vcRandomize: true,
  vcReview: true,
  vcVoiceToText: false,

  skAdvanced: false,
  skIncludeOvertime: true,
  skDisableGraphs: false,
  skCompact: false,

  ...(JSON.parse(localStorage.getItem("settings") as string) || {}),
};

const saveSettings = async (settings: SettingsData) => {
  localStorage.setItem("settings", JSON.stringify(settings));
};

export interface SetSelectedChapters {
  type: "SET_SELECTED_CHAPTERS";
  payload: {
    chapters?: boolean[][];
    skBooks?: boolean[];
  };
}
export interface SetQuizNowQuizzers {
  type: "SET_QUIZ_NOW_QUIZZERS";
  payload: string[];
}
export interface SetSettings {
  type: "SET_SETTINGS";
  payload: SettingsData;
}
export type SettingsReducerTypes =
  | SetSelectedChapters
  | SetQuizNowQuizzers
  | SetSettings
  | SetLogin;

export default function settings(
  state = initialState,
  action: SettingsReducerTypes
) {
  let result = { ...state };
  switch (action.type) {
    case SET_SELECTED_CHAPTERS:
      result = {
        ...state,
        selectedChapters: action.payload.chapters || state.selectedChapters,
        selectedSKBooks: action.payload.skBooks || state.selectedSKBooks,
      };
      break;
    case SET_QUIZ_NOW_QUIZZERS:
      result = {
        ...state,
        quizNowQuizzers: action.payload,
      };
      break;
    case SET_SETTINGS:
      result = action.payload;
      break;
    case SET_LOGIN:
      result = {
        ...state,
        ...action.payload.user.preferences.settings,
      };
      break;
    default:
      result = state;
  }
  if (result !== initialState) saveSettings(result);
  return {
    ...state,
    ...result,
  };
}
