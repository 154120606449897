import { ReactElement } from "react";
import { Grid } from "@mui/material";
import {
  TTierDescriptions,
  Tournament,
  TournamentPage,
} from "../../types/tournamentTypes";
import { Row } from "../Row";
import { ReactComponent as HomePage } from "../../icons/tournamentIcons/homepage.svg";
import { ReactComponent as Twitter } from "../../icons/tournamentIcons/twitter.svg";
import { ReactComponent as Youtube } from "../../icons/tournamentIcons/youtube.svg";
import { ReactComponent as Twitch } from "../../icons/tournamentIcons/twitch.svg";
import { ReactComponent as Instagram } from "../../icons/tournamentIcons/instagram.svg";
import { TournamentText } from "./TournamentText";
import { containsUppercase } from "../../util/generalUtil";
import { Breakpoints } from "../../types/generalTypes";
import { TournamentLink } from "./TournamentLink";
import { getDateRangeDisplay } from "../../util/dateUtils";
import { Link } from "react-router-dom";

export function TournamentSidebar(props: {
  page: TournamentPage;
  breakpoints: Breakpoints;
}) {
  let hasSidebarContent = false;
  const selectedPage = props.page;
  const isTournament = selectedPage.pageType === "TOURNAMENT";

  const getTournamentTopInfo = () => {
    if (!isTournament) return null;
    hasSidebarContent = true;
    return (
      <>
        <p>{`Date${
          selectedPage.startDate === selectedPage.endDate ? "" : "s"
        }: ${getDateRangeDisplay(
          selectedPage.startDate,
          selectedPage.endDate
        )}`}</p>
        <p>
          <span>Tier:</span>
          &nbsp;
          <Link to="/tournaments" state={{ selectedTier: selectedPage.tier }}>
            {TTierDescriptions[selectedPage.tier]}
          </Link>
        </p>
        {selectedPage.teamCount && <p>{`Teams: ${selectedPage.teamCount}`}</p>}
      </>
    );
  };
  const getLinks = () => {
    if (!selectedPage.secondaryId || !isTournament) return null;
    hasSidebarContent = true;
    let previous = "";
    let next = "";
    const sIdNumeric = Number.parseInt(selectedPage.secondaryId);
    const isNumericSId = !isNaN(sIdNumeric);

    if (selectedPage.additionalData.previousTournament) {
      previous = selectedPage.additionalData.previousTournament;
    } else if (isNumericSId && sIdNumeric > 1) {
      previous = String(sIdNumeric - 1);
    }
    if (selectedPage.additionalData.nextTournament) {
      next = selectedPage.additionalData.nextTournament;
    } else if (isNumericSId) {
      next = String(sIdNumeric + 1);
    }

    if (!previous && !next) return null;

    const getALink = (whichOne: "previous" | "next") => {
      const linkText = whichOne === "previous" ? previous : next;
      let displayText = linkText;
      const linkParts = linkText.split("/");
      const differentTId = linkParts.length > 1;
      if (differentTId) displayText = linkParts[1];

      return (
        <TournamentLink
          pageId={differentTId ? linkParts[0] : selectedPage.pageId}
          secondaryId={differentTId ? linkParts[1] : linkText}
          text={`${whichOne === "previous" ? "< " : ""}${displayText}${
            whichOne === "next" ? " >" : ""
          }`}
          style={whichOne === "next" ? { position: "absolute", right: 0 } : {}}
        />
      );
    };
    return (
      <Row style={{ position: "relative", width: "100%", marginTop: 15 }}>
        {previous && getALink("previous")}
        <TournamentLink
          pageId={selectedPage.pageId}
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            whiteSpace: "nowrap",
          }}
        />
        {next && getALink("next")}
      </Row>
    );
  };

  const additionalDataContent = (): React.ReactNode => {
    const resultItems: ReactElement[] = [];
    Object.keys(selectedPage.additionalData).forEach((key) => {
      if (containsUppercase(key.substring(0, 1))) {
        resultItems.push(
          <div key={key} style={{ marginBottom: 4 }}>
            <TournamentText
              text={`${key}: ${selectedPage.additionalData[key]}`}
            />
          </div>
        );
      }
    });
    if (resultItems.length > 0) hasSidebarContent = true;
    return resultItems;
  };

  const additionalLinkContent = (
    key: string,
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
    url: (text: string) => string = (text) => text
  ): React.ReactNode => {
    const data = selectedPage.additionalData?.[key];
    if (!data) return null;
    hasSidebarContent = true;
    return (
      <div style={{ marginRight: 5 }}>
        <a
          href={url(data)}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={key}
          title={key}
        >
          <Icon className="link-icon" />
        </a>
      </div>
    );
  };

  const activeContent = () => {
    if ("firstActive" in selectedPage || "lastActive" in selectedPage) {
      let activeText = "";
      activeText +=
        "firstActive" in selectedPage ? selectedPage.firstActive : "-";
      if (
        !(
          "firstActive" in selectedPage &&
          "lastActive" in selectedPage &&
          selectedPage.firstActive === selectedPage.lastActive
        )
      ) {
        activeText += " ";
        activeText +=
          +"firstActive" in selectedPage && "lastActive" in selectedPage
            ? "-"
            : "to";
        activeText += " ";
        activeText +=
          "lastActive" in selectedPage ? selectedPage.lastActive : "Present";
      }
      const notActivePart =
        "notActive" in selectedPage
          ? "Not Active: " + selectedPage.notActive
          : null;
      hasSidebarContent = true;
      return (
        <p>
          {`Active: ${activeText}`}
          {notActivePart ? (
            <>
              <br />
              {notActivePart}
            </>
          ) : null}
        </p>
      );
    }
    return null;
  };

  const prizeContent = () => {
    // If total prize is specified, mainly for competitors
    if (selectedPage.additionalData.totalPrize) {
      hasSidebarContent = true;
      return <p>{`Total Prize: $${selectedPage.additionalData.totalPrize}`}</p>;
    }

    // For tournaments
    if (!isTournament) return null;
    let prize = 0;
    const tournament = selectedPage as Tournament;
    tournament.stats?.quizzer.forEach((quizzer) => {
      if (quizzer.prize) prize += quizzer.prize;
    });
    tournament.stats?.team.forEach((team) => {
      if (team.prize) prize += team.prize;
    });
    if (!prize) return null;
    return (
      <div>
        <TournamentText text={`Total Prize: $${prize}`} />
      </div>
    );
  };

  const result = (
    <Grid item {...props.breakpoints} style={{ maxWidth: 450 }}>
      <div className="main-box">
        {getTournamentTopInfo()}
        {activeContent()}
        {prizeContent()}
        <Row>
          {additionalLinkContent("website", HomePage)}
          {additionalLinkContent(
            "youtube",
            Youtube,
            (text) => `https://www.youtube.com/${text}`
          )}
          {additionalLinkContent(
            "twitch",
            Twitch,
            (text) => `https://www.twitch.tv/${text}`
          )}
          {additionalLinkContent(
            "twitter",
            Twitter,
            (text) => `https://www.twitter.com/${text}`
          )}
          {additionalLinkContent(
            "instagram",
            Instagram,
            (text) => `https://www.instagram.com/${text}`
          )}
        </Row>
        {additionalDataContent()}
        {getLinks()}
      </div>
    </Grid>
  );
  if (!hasSidebarContent) return null;
  return result;
}
