import { useState } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../../state/store";
import { Check } from "../../../components/Check/Check";
import { Row } from "../../../components/Row";
import { DataTable } from "../../../components/DataTable/DataTable";
import { DropdownMenu } from "../../../components/Dropdown/DropdownMenu";
import * as skTypes from "../../../types/sk";
import * as utils from "../../../util/skUtils";
import "./PlayByPlay.scss";

interface PBPProps {
  moments: skTypes.Moment[];
  teams: skTypes.Team[];
  books: Readonly<number[]>;
  onClose: () => void;
  onChangeMoments: (newMoments: skTypes.Moment[]) => void;
  onEdit: (momentIndex: number) => void;
  editable: boolean;
  isPractice?: boolean;
}

export function PlayByPlay(props: PBPProps) {
  const { material } = useSelector((state: RootStore) => state.material);
  const { skAdvanced } = useSelector((state: RootStore) => state.settings);

  const [showAll, setShowAll] = useState<boolean>(true);
  type ColorTypeOptions = "RESULT" | "TEAM" | "JUMP";
  const [colorType, setColorType] = useState<ColorTypeOptions | undefined>(
    "RESULT"
  );
  const scoresByMoment: skTypes.RunningScoreItem[] = utils.getRunningScore(
    props.teams,
    props.moments,
    true
  );

  const [momentSelected, setMomentSelected] = useState<number | undefined>();
  const [switchEnabled, setSwitchEnabled] = useState<boolean>(false);

  // Used to map for options to change what happened
  interface MomentTypeChangeOption {
    type: skTypes.QuestionType | skTypes.KErrorType;
    isErrorType: boolean;
    isKErrorType: boolean;
  }
  const momentTypeChangeOptions: Readonly<MomentTypeChangeOption[]> = [
    { type: skTypes.tp, isErrorType: false, isKErrorType: false },
    ...(!skAdvanced
      ? [
          {
            type: skTypes.error,
            isErrorType: false,
            isKErrorType: false,
          },
        ]
      : [
          {
            type: skTypes.light,
            isErrorType: true,
            isKErrorType: false,
          },
          {
            type: skTypes.split,
            isErrorType: true,
            isKErrorType: false,
          },
          { type: skTypes.ref, isErrorType: true, isKErrorType: false },
          {
            type: skTypes.fifty,
            isErrorType: true,
            isKErrorType: false,
          },
          {
            type: skTypes.wrongVerse,
            isErrorType: false,
            isKErrorType: true,
          },
          {
            type: skTypes.wrongWord,
            isErrorType: false,
            isKErrorType: true,
          },
          {
            type: skTypes.invalidQuestion,
            isErrorType: false,
            isKErrorType: true,
          },
          {
            type: skTypes.outOfTime,
            isErrorType: false,
            isKErrorType: true,
          },
          {
            type: skTypes.badCall,
            isErrorType: false,
            isKErrorType: true,
          },
        ]),
    { type: skTypes.foul, isErrorType: false, isKErrorType: false },
  ];

  let questionNum = 0;
  return (
    <div className="page" style={{ minWidth: 1000 }}>
      <Row>
        <button className="back-button" onClick={props.onClose}>
          Go Back
        </button>
        <Check
          checked={showAll}
          onClick={setShowAll}
          style={{ marginTop: 0, marginLeft: 10, marginRight: 10, width: 110 }}
        >
          Show All
        </Check>
        {momentSelected !== undefined ? (
          <>
            <button
              className="link"
              style={{ marginRight: 5 }}
              onClick={() => {
                const newMoments: skTypes.Moment[] = [...props.moments];
                newMoments.splice(momentSelected, 1);
                props.onChangeMoments(newMoments);
                setMomentSelected(undefined);
              }}
            >
              Delete Selected
            </button>
            <button
              className="link"
              style={{ marginRight: 5 }}
              onClick={() => props.onEdit(momentSelected)}
              data-testid="pbp-edit"
            >
              Edit
            </button>
            {!switchEnabled ? (
              <>
                {momentSelected > 0 && (
                  <button
                    className="link"
                    style={{ marginRight: 5 }}
                    onClick={() => {
                      const newMoments: skTypes.Moment[] = [...props.moments];
                      const temp = newMoments[momentSelected];
                      newMoments[momentSelected] =
                        newMoments[momentSelected - 1];
                      newMoments[momentSelected - 1] = temp;
                      props.onChangeMoments(newMoments);
                      setMomentSelected(momentSelected - 1);
                    }}
                  >
                    Move Up
                  </button>
                )}
                {momentSelected < props.moments.length - 1 && (
                  <button
                    className="link"
                    style={{ marginRight: 5 }}
                    onClick={() => {
                      const newMoments: skTypes.Moment[] = [...props.moments];
                      const temp = newMoments[momentSelected];
                      newMoments[momentSelected] =
                        newMoments[momentSelected + 1];
                      newMoments[momentSelected + 1] = temp;
                      props.onChangeMoments(newMoments);
                      setMomentSelected(momentSelected + 1);
                    }}
                  >
                    Move Down
                  </button>
                )}
                <button
                  className="link"
                  style={{ marginRight: 5 }}
                  onClick={() => setSwitchEnabled(true)}
                  key="start-switch"
                  data-testid="pbp-switch"
                >
                  Switch with another item
                </button>
              </>
            ) : (
              <>
                <span style={{ paddingTop: 10, marginRight: 5 }}>
                  Select another item to switch with.
                </span>
                <button
                  className="link"
                  onClick={() => setSwitchEnabled(false)}
                  key="cancel-switch"
                >
                  Cancel Switching
                </button>
              </>
            )}
          </>
        ) : (
          <>
            {skAdvanced && (
              <>
                <div style={{ marginTop: 10, marginLeft: 10, marginRight: 5 }}>
                  Color Code:
                </div>
                <Check
                  checked={colorType === "RESULT"}
                  onClick={(e) => setColorType(e ? "RESULT" : undefined)}
                  style={{ width: 90, marginTop: 0, marginRight: 10 }}
                >
                  Results
                </Check>
                <Check
                  checked={colorType === "JUMP"}
                  onClick={(e) => setColorType(e ? "JUMP" : undefined)}
                  style={{ width: 90, marginTop: 0, marginRight: 10 }}
                >
                  Jump
                </Check>
                {!props.isPractice && (
                  <Check
                    checked={colorType === "TEAM"}
                    onClick={(e) => setColorType(e ? "TEAM" : undefined)}
                    style={{ width: 90, marginTop: 0, marginRight: 10 }}
                  >
                    Team
                  </Check>
                )}
              </>
            )}
          </>
        )}
      </Row>
      <hr className="separator-line" />
      <DataTable
        data={props.moments}
        columns={[
          {
            title: "#",
            property: "questionNum",
            render: (obj) => {
              if (utils.isQuestion(obj)) questionNum += 1;
              return questionNum;
            },
          },
          {
            title: "Team",
            property: "teamName",
            visible: !props.isPractice,
            render: (obj, index) => {
              const teamName =
                obj.team !== undefined ? props.teams[obj.team].teamName : "";
              return obj.team !== undefined ? (
                props.editable ? (
                  <DropdownMenu
                    id={`pbp-teamname-${index}`}
                    items={props.teams.map((team, teamIndex, arr) => ({
                      title: team.teamName,
                      onClick: () => {
                        const moments: skTypes.Moment[] = [...props.moments];
                        moments[index].team = teamIndex;
                        if (moments[index].quizzerId)
                          moments[index].quizzerId =
                            props.teams[teamIndex].quizzers[0].quizzerId;
                        props.onChangeMoments(moments);
                      },
                    }))}
                  >
                    {teamName}
                  </DropdownMenu>
                ) : (
                  teamName
                )
              ) : (
                ""
              );
            },
          },
          {
            title: "Quizzer",
            property: "quizzerName",
            render: (obj, index) => {
              const quizzerName =
                obj.team !== undefined && obj.quizzerId !== undefined
                  ? props.teams[obj.team].quizzers.find(
                      (quizzer: skTypes.Quizzer) =>
                        quizzer.quizzerId === obj.quizzerId
                    )?.quizzerName
                  : "";
              return obj.team !== undefined && obj.quizzerId ? (
                props.editable ? (
                  <DropdownMenu
                    items={props.teams[obj.team].quizzers.map((quizzer) => ({
                      title: quizzer.quizzerName,
                      onClick: () => {
                        const moments: skTypes.Moment[] = [...props.moments];
                        moments[index].quizzerId = quizzer.quizzerId;
                        props.onChangeMoments(moments);
                      },
                    }))}
                    id={`pbp-quizzer-${index}`}
                  >
                    {quizzerName}
                  </DropdownMenu>
                ) : (
                  quizzerName
                )
              ) : (
                ""
              );
            },
          },
          {
            title: "Happened",
            property: "happened",
            render: (obj, index) => {
              // Get what happened
              let happened: string =
                obj.errorType !== undefined && skAdvanced
                  ? skTypes.errorDescription[obj.errorType]
                  : skTypes.momentTypeDescriptions[obj.type] || obj.type;
              if (obj.kErrorType && skAdvanced)
                happened = `${happened} (${
                  skTypes.kErrorDescription[obj.kErrorType]
                })`;
              if (!props.isPractice) {
                // Quiz Out or Error Out
                if (scoresByMoment[index].quizErrorOut)
                  happened = happened.concat(
                    ` (${obj.type === skTypes.tp ? "Q" : "E"}O)`
                  );
                // Bonus
                if (
                  obj.type === skTypes.tp &&
                  obj.team !== undefined &&
                  questionNum > 1 &&
                  scoresByMoment[index].scores[obj.team] ===
                    //@ts-ignore
                    scoresByMoment[index - 1].scores[obj.team] + 40
                )
                  happened = happened.concat(" (B)");
              }

              return obj.quizzerId && props.editable ? (
                <DropdownMenu
                  items={momentTypeChangeOptions.map((option) => {
                    // @ts-ignore
                    const momentType: skTypes.MomentType =
                      option.isErrorType || option.isKErrorType
                        ? skTypes.error
                        : option.type;
                    // @ts-ignore
                    const errorType: skTypes.ErrorType | undefined =
                      option.isErrorType
                        ? option.type
                        : option.isKErrorType
                        ? skTypes.knowledge
                        : undefined;
                    // @ts-ignore
                    const kErrorType: skTypes.KErrorType | undefined =
                      option.isKErrorType ? option.type : undefined;
                    return {
                      title: `${
                        kErrorType
                          ? `K-Error (${skTypes.kErrorDescription[kErrorType]})`
                          : ""
                      }
                      ${
                        errorType && !kErrorType
                          ? skTypes.errorDescription[errorType]
                          : ""
                      }
                      ${
                        !errorType
                          ? skTypes.momentTypeDescriptions[momentType]
                          : ""
                      }`,
                      onClick: () => {
                        const moments: skTypes.Moment[] = [...props.moments];
                        moments[index].type = momentType;
                        moments[index].errorType = errorType;
                        moments[index].kErrorType = kErrorType;
                        props.onChangeMoments(moments);
                      },
                    };
                  })}
                  id={`pbp-happened-${index}`}
                >
                  {happened}
                </DropdownMenu>
              ) : (
                happened
              );
            },
          },
          ...props.teams.map((team, teamIndex) => ({
            title: team.teamName,
            property: `team-${team.teamId}`,
            visible: !props.isPractice,
            render: (_: skTypes.Moment, index: number) =>
              scoresByMoment[index].scores[teamIndex],
          })),
          {
            title: "Book",
            property: "book",
            render: (obj) =>
              obj.book !== undefined ? material[obj.book]?.bookName || "" : "",
          },
          {
            title: "Ch.",
            property: "chapter",
            render: (obj) =>
              obj.chapter !== undefined && obj.chapter >= 0
                ? obj.chapter + 1
                : "",
          },
          {
            title: "V.",
            property: "verse",

            render: (obj) =>
              obj.verse !== undefined && obj.verse >= 0 ? obj.verse + 1 : "",
          },
          {
            title: "Question",
            property: "question",
            visible: skAdvanced,
            render: (obj) => (
              <>
                {obj.receivedCharacters !== undefined &&
                  obj.receivedCharacters < 0 && <span>[S]&nbsp;</span>}
                {obj.question && (
                  <>
                    <span className="received-characters">
                      {obj.question.substring(0, obj.receivedCharacters)}
                    </span>
                    {obj.receivedCharacters !== undefined && (
                      <span>
                        {obj.question.substring(obj.receivedCharacters || 0)}
                      </span>
                    )}
                    {obj.read && <span>&nbsp;[R]</span>}
                  </>
                )}
              </>
            ),
          },
        ]}
        rowDisplay={(obj, index) => {
          const getRowClassName = (): string | undefined => {
            if (momentSelected !== undefined) return undefined;
            switch (colorType) {
              case "JUMP":
                if (
                  !utils.isQuestion(obj) ||
                  (obj.verse === undefined && obj.receivedCharacters !== -1)
                )
                  return undefined;
                const { words, read } = utils.getWordsReceived(obj);
                return words > 0 || read ? "pbp-green" : "pbp-red";
              case "RESULT":
                return obj.type === skTypes.tp
                  ? "pbp-green"
                  : obj.type === skTypes.error
                  ? "pbp-red"
                  : undefined;
              case "TEAM":
                if (obj.team === undefined) return undefined;
                return `pbp-${utils.teamSideColors[obj.team]}`;
            }
          };
          const isShown: boolean =
            showAll ||
            utils.isQuestion(obj) ||
            [skTypes.startOvertime, skTypes.halftime].includes(obj.type);

          return {
            className: getRowClassName(),
            selected: momentSelected === index,
            style: {
              display: !isShown ? "none" : undefined,
            },
          };
        }}
        onClickCell={(_, index, column) => {
          if (column === "questionNum" && props.editable) {
            if (momentSelected === index) {
              setMomentSelected(undefined);
              return;
            }
            if (
              momentSelected !== undefined &&
              switchEnabled &&
              props.editable
            ) {
              const newMoments: skTypes.Moment[] = [...props.moments];
              const temp = newMoments[index];
              newMoments[index] = newMoments[momentSelected];
              newMoments[momentSelected] = temp;
              props.onChangeMoments(newMoments);
              setMomentSelected(undefined);
              setSwitchEnabled(false);
            } else {
              setMomentSelected(index);
            }
          }
        }}
        style={{
          minHeight: "calc(100vh - 120px)",
          maxHeight: "calc(100vh - 120px)",
          marginBottom: 30,
        }}
      />
    </div>
  );
}
