import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { Row } from "../../components/Row";
import { fetchWrapper } from "../../state/actions/fetchWrapper";
import { getMediaStream } from "../../util/voiceUtils";
import { StringInput } from "../../components/Inputs";

export function MultiIndividualLobby() {
  const navigate = useNavigate();
  const settings = useSelector((state: RootStore) => state.settings);
  const countChapters = (): number => {
    let result: number = 0;
    settings.selectedChapters.forEach((book) => {
      book.forEach((chapter) => {
        if (chapter) result++;
      });
    });
    return result;
  };
  const chapterCount = countChapters();

  const [testingAudio, setTestingAudio] = useState<boolean>(false);
  const [roomName, setRoomName] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [creating, setCreating] = useState<boolean>(false);

  const [existingRooms, setExistingRooms] = useState<any[] | undefined>(
    undefined
  );

  const getRooms = async () => {
    const response = await fetchWrapper(
      undefined,
      "POST",
      "multi/getindividualrooms"
    );
    if (response.returnCode === 0)
      setExistingRooms(response.responseMessage.rooms);
  };
  useEffect(() => {
    getRooms();
  }, []);

  const stopTestTimeout = useRef<any>();
  const stopTestingAudio = () => {
    const originalVideo = document.getElementById(
      "test-audio"
    ) as HTMLVideoElement | null;
    const page = document.getElementById("multiplayer-home-page");
    if (originalVideo && page) {
      originalVideo.pause();
      originalVideo.removeAttribute("src");
      page.removeChild(originalVideo);
    }
    setTestingAudio(false);
  };

  return (
    <div className="page" id="multiplayer-home-page">
      <meta
        name="description"
        content="Quiz with people on other devices in multiplayer mode"
      />
      <Row>
        <button
          className="back-button"
          onClick={() => navigate("/multiplayer")}
        >
          Go Back
        </button>
        <h1 style={{ marginLeft: 10 }}>Multiplayer Practice Quizzing</h1>
      </Row>
      {creating ? (
        <div>
          <StringInput
            caption="Username:"
            value={userName}
            onChange={setUserName}
          />
          <StringInput
            caption="Room Name:"
            value={roomName}
            onChange={setRoomName}
          />
          {chapterCount === 0 ? (
            <p className="important-information">
              To create a room, you will need at least one chapter selected.
              Visit Chapters from the main menu to choose your material.
            </p>
          ) : (
            <>
              <p>
                {`Currently, you have ${chapterCount} chapters selected. Change this in Chapters from the main menu.`}
              </p>
              <Row style={{ marginTop: 10 }}>
                <button
                  className="clickable"
                  onClick={() => setCreating(false)}
                >
                  Cancel
                </button>
                <button
                  className="clickable"
                  style={{ marginLeft: 10 }}
                  onClick={async () => {
                    const result = await fetchWrapper(
                      undefined,
                      "POST",
                      "multi/createindividualroom",
                      { roomName }
                    );
                    if (result.returnCode === 0) {
                      navigate(
                        `/multiplayer/individual/${result.responseMessage.roomId}`,
                        {
                          state: {
                            userName,
                          },
                        }
                      );
                    }
                  }}
                >
                  Create Room
                </button>
              </Row>
            </>
          )}
        </div>
      ) : (
        <div>
          <Row>
            <button className="clickable" onClick={() => setCreating(true)}>
              Create Room
            </button>
            <button
              className="clickable"
              style={{ marginLeft: 10 }}
              onClick={getRooms}
            >
              Refresh Rooms
            </button>
          </Row>
          {existingRooms === undefined ? (
            <p>Rooms loading...</p>
          ) : existingRooms.length === 0 ? (
            <p>
              There are no multiplayer rooms available. Wait for one to be
              opened or create one yourself.
            </p>
          ) : (
            <>
              <StringInput
                caption="Username:"
                value={userName}
                onChange={setUserName}
              />
              <p>Choose an existing room below to join:</p>
              {existingRooms.map((room: any) => (
                <div
                  key={room.roomId}
                  className="season-event-list"
                  onClick={() => {
                    if (!userName) return;
                    navigate(`/multiplayer/individual/${room.roomId}`, {
                      state: { userName },
                    });
                  }}
                >
                  {room.roomName}
                </div>
              ))}
            </>
          )}
          <div style={{ marginTop: 40 }}>
            <p>BQA will need microphone access to use Multiplayer Quizzing.</p>
            {testingAudio ? (
              <>
                <p>
                  Grant your browser access to your microphone, if you are
                  asked. You may need to refresh the page after you do so. If
                  your microphone is working, you will be able to hear yourself
                  back through your speakers.
                </p>
                <button
                  className="link"
                  onClick={() => {
                    clearTimeout(stopTestTimeout.current);
                    stopTestingAudio();
                  }}
                >
                  Stop Testing Audio
                </button>
              </>
            ) : (
              <button
                className="link"
                onClick={() => {
                  const originalVideo = document.getElementById("test-audio");
                  if (originalVideo) document.removeChild(originalVideo);
                  const myVideo = document.createElement("video");
                  myVideo.id = "test-audio";
                  getMediaStream({
                    video: false,
                    audio: true,
                  }).then((stream) => {
                    if (!stream) return;
                    const page = document.getElementById(
                      "multiplayer-home-page"
                    );
                    myVideo.srcObject = stream;
                    myVideo.addEventListener("loadedmetadata", () => {
                      myVideo.play();
                    });
                    if (page) page.append(myVideo);
                    setTestingAudio(true);
                  });
                  const newId = setTimeout(stopTestingAudio, 3000);
                  stopTestTimeout.current = newId;
                }}
              >
                Test your microphone now.
              </button>
            )}
            <p style={{ marginTop: 40 }}>
              Multiplayer Quizzing is a casual way to practice with friends.
              Words will appear on the screen, and when one quizzer is
              answering, the other quizzers will vote on the result of the
              question. Majority wins (20 Points or Error), so make sure you're
              listening!
            </p>
            <p>
              To get started, set up a room or join an existing one, and start
              asking questions. Make sure your microphone and speakers are
              working. It might be best to use wired earbuds or headphones to
              reduce echo.
            </p>
            <p>
              You may notice there appears to be some lag between when you jump
              and when you are called on. This is because the system is waiting
              for other people to jump, just in case they have more lag than you
              do. In this way, lag is essentially solved: the quizzer who jumped
              fastest will win the jump.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
