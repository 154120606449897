import { useNavigate } from "react-router-dom";
import { Row } from "../../components/Row";
import { Link } from "react-router-dom";

export function MultiplayerHome() {
  const navigate = useNavigate();

  return (
    <div className="page" id="multiplayer-home-page">
      <meta
        name="description"
        content="Quiz with people on other devices in multiplayer mode"
      />
      <Row>
        <button className="back-button" onClick={() => navigate("/")}>
          Go Back
        </button>
        <h1 style={{ marginLeft: 10 }}>Multiplayer Quizzing</h1>
      </Row>
      <div>
        <h3>Choose the multiplayer quizzing format for you.</h3>
        <Row>
          <div style={{ width: 200 }}>
            <Link to="/multiplayer/individual" className="clickable">
              Invidivual
            </Link>
            <p>Practice by yourself with other quizzers.</p>
          </div>
          <div>
            <Link to="/multiplayer/teams" className="clickable">
              Teams
            </Link>
            <p>Quiz on teams with a quizmaster and real pads.</p>
          </div>
        </Row>
      </div>
    </div>
  );
}
