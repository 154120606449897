export function ESVText() {
  return (
    <>
      <p>
        The ESV® Bible (The Holy Bible, English Standard Version®) copyright ©
        2001 by Crossway Bibles, a publishing ministry of Good News Publishers.
        ESV® Text Edition: 2016. The ESV® text has been reproduced in
        cooperation with and by permission of Good News Publishers. Unauthorized
        reproduction of this publication is prohibited. All rights reserved.
      </p>
      <p>
        The ESV® Bible (The Holy Bible, English Standard Version®) is adapted
        from the Revised Standard Version of the Bible, copyright Division of
        Christian Education of the National Council of the Churches of Christ in
        the U.S.A. All rights reserved.
      </p>
      <p>
        English Standard Version, ESV, and the ESV logo are registered
        trademarks of Good News Publishers. Used by permission.
      </p>
    </>
  );
}
