import { useRef, useState } from "react";
import { useAcme } from "../hooks/useAcme";
import { useNavigate } from "react-router-dom";
import { Row } from "../components/Row";
import { Check } from "../components/Check/Check";
import { xor } from "../util/generalUtil";

export function Reaction() {
  const navigate = useNavigate();
  const [phase, setPhase] = useState<"UNSET" | "READY" | "RED" | "GREEN">(
    "UNSET"
  );
  const [reversePads, setReversePads] = useState(false);
  const [triggerType, setTriggerType] = useState<"AUDIO" | "VISUAL">("VISUAL");

  const phaseRef = useRef(phase);
  const startedTime = useRef(0);
  const timer = useRef<any>(null);
  const [resultDisplay, setResultDisplay] = useState<string>("");
  const getLightSettings = () => {
    const result: boolean[][] = [];
    for (let i = 0; i < 4; i++) {
      result.push([false, false, false, false, false]);
    }
    return result;
  };
  const [lightSettings, setLightSettings] = useState<boolean[][]>(
    getLightSettings()
  );
  const lightSettingsRef = useRef<boolean[][]>(lightSettings);

  const getJumped = (values: number[][]): number[][] => {
    let jumped: number[][] = [];
    values.forEach((team, tIndex) => {
      team.forEach((seat, sIndex) => {
        if (
          lightSettingsRef.current[tIndex][sIndex] &&
          xor([reversePads, seat === 1])
        )
          jumped.push([tIndex, sIndex]);
      });
    });
    return jumped;
  };
  const acme = useAcme((values) => {
    const now = Date.now();
    const jumped = getJumped(values);
    if (jumped.length > 0) {
      if (["RED", "GREEN"].includes(phaseRef.current)) {
        let newDisplay =
          phaseRef.current === "GREEN"
            ? `Reaction time was ${Math.max(now - startedTime.current, 0)} ms.`
            : "Jumped too early!";
        jumped.forEach((person) => {
          newDisplay = `${newDisplay} ${person[0] + 1}-${person[1] + 1}`;
        });
        setResultDisplay(newDisplay);
      }
      setPhase("READY");
      phaseRef.current = "READY";
      clearTimeout(timer.current);
    }
  });

  return (
    <div className="page">
      <button className="back-button" onClick={() => navigate("/")}>
        Go Back
      </button>
      {phase === "UNSET" && (
        <>
          <p>This game will test your reaction speed, using quiz pads.</p>
          <button
            className="clickable"
            onClick={() => {
              acme.init(() => setPhase("READY"));
            }}
          >
            Get Started
          </button>
        </>
      )}
      {phase === "READY" && (
        <>
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <Row>
              <Check
                checked={triggerType === "AUDIO"}
                onClick={() => setTriggerType("AUDIO")}
                style={{ width: 100 }}
              >
                Audio
              </Check>
              <Check
                checked={triggerType === "VISUAL"}
                onClick={() => setTriggerType("VISUAL")}
                style={{ width: 100 }}
              >
                Visual
              </Check>
            </Row>
            <Check
              style={{ height: 30, fontSize: 14, width: 120 }}
              checked={reversePads}
              onClick={setReversePads}
            >
              Inverse Pads
            </Check>
            {lightSettings.map((team, index) => (
              <Row key={index}>
                <span style={{ marginRight: 5 }}>{`Team ${index + 1}:`}</span>
                {team.map((seat, seatIndex) => (
                  <button
                    key={seatIndex}
                    style={{
                      fontSize: 14,
                      width: 40,
                      backgroundColor:
                        seat && acme.lights[index]?.[seatIndex] ? "green" : "",
                      transition: "unset",
                    }}
                    onClick={() => {
                      const newLights = [...lightSettings];
                      newLights[index] = [...team];
                      newLights[index][seatIndex] = !seat;
                      setLightSettings(newLights);
                      lightSettingsRef.current = newLights;
                    }}
                  >
                    {seat ? "On" : "Off"}
                  </button>
                ))}
              </Row>
            ))}
          </div>
          <p style={{ fontSize: 22 }}>{resultDisplay}</p>
          <button
            className="clickable"
            onClick={() => {
              if (getJumped(acme.lights).length > 0) return;
              startedTime.current = Date.now();
              setPhase("RED");
              phaseRef.current = "RED";
              const time = Math.random() * 5000 + 2000;
              if (triggerType === "AUDIO") {
                timer.current = setTimeout(() => {
                  setPhase("GREEN");
                  phaseRef.current = "GREEN";
                  const audio = new Audio(
                    require("../res/sounds/clap-sound.mp3")
                  );
                  audio.play();
                  startedTime.current = Date.now() - 10; // Subtract for length to play the sound
                }, time);
              } else if (triggerType === "VISUAL") {
                timer.current = setTimeout(() => {
                  setPhase("GREEN");
                  phaseRef.current = "GREEN";
                  startedTime.current = Date.now();
                }, time);
              }
            }}
          >
            Click to Start
          </button>
        </>
      )}
      {["RED", "GREEN"].includes(phase) && (
        <div
          style={{
            height: 400,
            marginTop: 20,
            backgroundColor:
              triggerType === "VISUAL"
                ? phase === "RED"
                  ? "red"
                  : "green"
                : undefined,
          }}
        >
          {triggerType === "VISUAL" && (
            <div style={{ margin: 20, fontSize: 36, fontWeight: "bold" }}>
              {phase === "RED" ? "Wait for Green..." : "Jump!"}
            </div>
          )}
          {triggerType === "AUDIO" && (
            <div style={{ fontSize: 30 }}>Jump when the clap sounds</div>
          )}
        </div>
      )}
    </div>
  );
}
