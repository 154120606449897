import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "../../../state/store";
import { DataTable } from "../../../components/DataTable/DataTable";
import { Row } from "../../../components/Row";
import { StatsSettings } from "../../../components/SKComponents/StatsSettings";
import { useKeyedParams } from "../../../hooks/useParams";
import { useDataProvider } from "../../../hooks/dataProvider";
import * as skTypes from "../../../types/sk";
import * as statsUtils from "../../../util/skStatsUtils";
import { addSeasonToLocalStore, getLocalSeason } from "../../../util/skUtils";
import {
  getSeasonInfo,
  setSelectedSeason,
} from "../../../state/actions/skActions";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";

interface ChapterStatsProps {
  seasonId: string;
}

export function ChapterStats() {
  const { seasonId } = useKeyedParams<ChapterStatsProps>();
  const provider = useDataProvider({
    getData: getSeasonInfo,
    selector: (state) => state.sk.selectedSeason,
    dispatchAction: setSelectedSeason,
    id: seasonId,
    getId: (season) => season.seasonId,
    render: (season) => {
      return <ChapterStatsComponent selectedSeason={season} />;
    },
    fallbackUrl: "/sk/seasons",
    cacheDetails: {
      prefix: "season",
      get: getLocalSeason,
      save: addSeasonToLocalStore,
    },
  });
  return provider.getPage();
}

export function ChapterStatsComponent({
  selectedSeason,
}: {
  selectedSeason: skTypes.Season;
}) {
  useDocumentTitle("Chapter Stats - Bible Quiz Academy");
  type SortProperty =
    | keyof skTypes.ChapterStats
    | "name"
    | "correct"
    | "errors"
    | "percentage"
    | "answerLuck";
  const location = useLocation();
  const locationState = location.state as {
    settings?: skTypes.StatsSettings;
    sortProperty?: SortProperty;
    sortReversed?: boolean;
    fromQuiz?: string;
  };
  const navigate = useNavigate();
  const { seasonId } = selectedSeason;
  const { selectedEvent } = useSelector((state: RootStore) => state.sk);
  const { skIncludeOvertime } = useSelector(
    (state: RootStore) => state.settings
  );
  const { material } = useSelector((state: RootStore) => state.material);

  const [stats, setStats] = useState<skTypes.ChapterStats[]>([]);
  const settings: skTypes.StatsSettings =
    locationState?.settings ||
    statsUtils.getEmptyStatsSettings(skIncludeOvertime);
  const setSettings = (newSettings: skTypes.StatsSettings): void => {
    navigate(location.pathname, {
      state: {
        ...locationState,
        settings: newSettings,
      },
      replace: true,
    });
  };
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);

  const goBack = (): void => {
    navigate(`/sk/${seasonId}/${selectedEvent}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const statsObjects = statsUtils.getChapterStats(
      seasonId,
      settings,
      material
    );
    if (!statsObjects.length) {
      goBack();
    } else {
      setStats(Object.values(statsObjects));
    }
  }, []);

  if (settingsVisible) {
    return (
      <div className="page" style={{ minWidth: 700 }}>
        <StatsSettings
          purpose="quizzer"
          seasonId={seasonId}
          onGoBack={(newSettings: skTypes.StatsSettings) => {
            const statsObjects = statsUtils.getChapterStats(
              seasonId,
              newSettings,
              material
            );
            setStats(Object.values(statsObjects));
            setSettings(newSettings);
            setSettingsVisible(false);
          }}
          defaultSettings={settings}
        />
      </div>
    );
  }
  return (
    <div className="page" style={{ minWidth: 700 }}>
      <Row style={{ marginBottom: 10, position: "relative" }}>
        <button className="back-button" onClick={goBack}>
          Go Back
        </button>
        <h1 style={{ marginLeft: 10 }}>Chapter Stats</h1>
        <button
          className="clickable"
          style={{ position: "absolute", right: 0 }}
          onClick={() => setSettingsVisible(true)}
        >
          Settings
        </button>
      </Row>
      <DataTable<skTypes.ChapterStats>
        data={stats}
        defaultSort={{
          property: "correct",
          direction: "desc",
        }}
        columns={[
          {
            title: "Chapter",
            property: "name",
            render: (obj) => (
              <span
                onClick={() => {
                  navigate(
                    `/sk/${seasonId}/chapterStats/${obj.book}-${obj.chapter}`,
                    { state: { chapterStats: obj } }
                  );
                }}
              >
                {`${material[obj.book].bookName} ${obj.chapter + 1}`}
              </span>
            ),
          },
          {
            title: "Correct",
            property: "correct",
            render: (obj) => obj.questionsByType[skTypes.tp] || 0,
            sortFunc: true,
          },
          {
            title: "Errors",
            property: "errors",
            render: (obj) => obj.questionsByType[skTypes.error] || 0,
            sortFunc: true,
          },
          {
            title: "Word %",
            property: "wordPercentage",
            render: (obj) => {
              const wordPercentage = statsUtils.getWordPercentage(
                obj.jumpsByType
              );
              return isNaN(wordPercentage) ? "-" : `${wordPercentage}%`;
            },
            sortFunc: (obj) => {
              const wordPercentage = statsUtils.getWordPercentage(
                obj.jumpsByType
              );
              return isNaN(wordPercentage) ? 0 : wordPercentage;
            },
          },
          {
            title: "Answer Luck",
            property: "answerLuck",
            render: (obj) =>
              obj.luck.expected === 0
                ? "-"
                : `${Math.round((obj.luck.actual * 100) / obj.luck.expected)}%`,
            sortFunc: (obj) =>
              obj.luck.expected === 0 ? 0 : obj.luck.actual / obj.luck.expected,
          },
        ]}
        getKey={(obj) => `${obj.book}-${obj.chapter}`}
      />
    </div>
  );
}
