import { TournamentPage } from "../../types/tournamentTypes";
import { TournamentText } from "./TournamentText";

export function TournamentTrivia(props: { page: TournamentPage }) {
  if (!props.page.trivia || props.page.trivia.length === 0) return null;
  return (
    <div>
      <h3 style={{ marginTop: 0 }}>Trivia</h3>
      <ul>
        {props.page.trivia.map((trivia, index) => (
          <li key={index}>
            <TournamentText text={trivia} />
          </li>
        ))}
      </ul>
    </div>
  );
}
