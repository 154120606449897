import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App.tsx";
import * as sw from "./serviceWorkerRegistration";

const root = createRoot(document.getElementById("root"));
root.render(<App />);

sw.register();
