import { Book } from "../../types/material";
export const SET_MATERIAL = "SET_MATERIAL";

const initialState = {
  material: [] as Book[],
};

export interface SetMaterial {
  type: "SET_MATERIAL";
  payload: {
    material: Book[];
  };
}
export type MaterialActions = SetMaterial;
export default function settings(
  state = initialState,
  action: MaterialActions
) {
  switch (action.type) {
    case SET_MATERIAL:
      return {
        material: action.payload.material as Book[],
      };
    default:
      return state;
  }
}
