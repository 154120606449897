import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { Row } from "../../components/Row";
import { QuizMetadataForm } from "../../components/SKComponents/QuizMetadataForm";
import * as colors from "../../util/colors";
import * as skTypes from "../../types/sk";
import { useDataProvider } from "../../hooks/dataProvider";
import { useRequestHandler } from "../../hooks/requestHandler";
import { useKeyedParams } from "../../hooks/useParams";
import {
  createQuiz,
  getSeasonInfo,
  setSelectedSeason,
} from "../../state/actions/skActions";
import { addSeasonToLocalStore, getLocalSeason } from "../../util/skUtils";
import { v4 as uuid } from "uuid";
import "./SK.scss";
import { Check } from "../../components/Check/Check";
import { SimpleStringInput } from "../../components/Inputs";
import { Link } from "react-router-dom";

interface SKMenuProps {
  seasonId: string;
  eventId: string;
}

export function NewPracticeQuiz() {
  const navigate = useNavigate();
  const { seasonId, eventId } = useKeyedParams<SKMenuProps>();
  const { selectedQuiz } = useSelector((state: RootStore) => state.sk);
  const { user } = useSelector((state: RootStore) => state.authentication);

  const [quizzerView, setQuizzerView] = useState<"TEAM" | "LIST">("TEAM");
  const [selectedTeam, setSelectedTeam] = useState<skTypes.Team | undefined>(); // Which roster is selected
  const [selectedQuizTeam, setSelectedQuizTeam] = useState<number>(0); // which team in this quiz is selected

  const [newQuizData, setNewQuizData] = useState<skTypes.QuizSummary>({
    seasonId,
    eventId,
    quizId: uuid(),
    quizName: "",
    site: 1,
    round: 1,
    teams: [],
    isPractice: true,
    createdTimeStamp: "",
    lastUpdated: "",
  });
  const updateQuizData = (key: string, value: any) => {
    const newData: skTypes.QuizSummary = { ...newQuizData };
    // @ts-ignore
    newData[key] = value;
    setNewQuizData(newData);
  };

  const handler = useRequestHandler({
    onSuccess: () => {
      if (selectedQuiz?.quizId === newQuizData.quizId)
        navigate(`/sk/${seasonId}/${eventId}/${selectedQuiz.quizId}`);
    },
    loading: (state) => state.sk.skRequestLoading,
    assumeCachedInfo: true,
  });
  const provider = useDataProvider({
    id: seasonId,
    getId: (season) => season.seasonId,
    dispatchAction: setSelectedSeason,
    getData: getSeasonInfo,
    selector: (state) => state.sk.selectedSeason,
    cacheDetails: {
      prefix: "season",
      get: getLocalSeason,
      save: addSeasonToLocalStore,
    },
    render: (season) => {
      const event = season.events.find((ev) => ev.eventId === eventId);
      if (!event || season.teams.length === 0) {
        return <Navigate to={`/sk/${seasonId}/events`} />;
      }
      const getAllQuizzers = () => {
        const result: skTypes.Quizzer[] = [];
        season.teams.forEach((team) => {
          team.quizzers.forEach((quizzer) => {
            if (quizzer.active) result.push(quizzer);
          });
        });
        return result.sort((q1, q2) =>
          q1.quizzerName.localeCompare(q2.quizzerName)
        );
      };

      return (
        <div className="page" style={{ minWidth: 700 }}>
          {handler.snackbar}
          <Row>
            <Link
              className="back-button"
              style={{ marginRight: 15 }}
              to={`/sk/${seasonId}/${eventId}`}
            >
              Go Back
            </Link>
            <h1>New Quiz</h1>
          </Row>

          <div
            style={{
              border: `4px solid ${colors.bqaDarkGrey}`,
              padding: 15,
              marginTop: 20,
              borderRadius: 10,
            }}
          >
            <QuizMetadataForm
              data={newQuizData}
              onUpdateDetails={updateQuizData}
              quizmasterList={season.quizmasters}
            />
            <Row>
              <Check
                checked={newQuizData.isPractice}
                onClick={(checked) => {
                  setNewQuizData({
                    ...newQuizData,
                    isPractice: checked,
                    teams: [],
                  });
                  setSelectedQuizTeam(0);
                }}
                style={{ width: 200 }}
              >
                Individual Quiz
              </Check>
              <Check
                checked={!newQuizData.isPractice}
                onClick={(checked) => {
                  setNewQuizData({
                    ...newQuizData,
                    isPractice: !checked,
                    teams: [],
                  });
                  setSelectedQuizTeam(0);
                }}
                style={{ width: 200 }}
              >
                Team Quiz
              </Check>
            </Row>
            <Row>
              <Check
                checked={quizzerView === "TEAM"}
                onClick={() => setQuizzerView("TEAM")}
                style={{ width: 200 }}
              >
                View Team Rosters
              </Check>
              <Check
                checked={quizzerView === "LIST"}
                onClick={() => setQuizzerView("LIST")}
                style={{ width: 200 }}
              >
                View Quizzer List
              </Check>
            </Row>
            <p>
              {newQuizData.isPractice
                ? "Add as many quizzers as are quizzing."
                : "Make teams and add quizzers to them."}
            </p>
            <Row>
              <div>
                {!newQuizData.isPractice && (
                  <div style={{ marginBottom: 10 }}>
                    <button
                      className="clickable"
                      style={{ width: 200 }}
                      onClick={() => {
                        const newTeams = [
                          ...newQuizData.teams,
                          {
                            teamId: uuid(),
                            teamName: "Team Name",
                            quizzers: [],
                            active: true,
                          },
                        ];
                        updateQuizData("teams", newTeams);
                        setSelectedQuizTeam(newTeams.length - 1);
                      }}
                    >
                      Add Team to this Quiz
                    </button>
                  </div>
                )}
                {quizzerView === "TEAM" ? (
                  <>
                    <p>Select a team to add quizzers from.</p>
                    <select
                      onChange={(e) => {
                        const thisTeam = season.teams.find(
                          (team) => team.teamId === e.target.value
                        );
                        if (thisTeam) setSelectedTeam(thisTeam);
                      }}
                      value={selectedTeam?.teamId}
                      style={{
                        marginBottom: 10,
                        marginRight: 10,
                        width: "100%",
                      }}
                    >
                      <option value="">Select a Team</option>
                      {season.teams.map((team: skTypes.Team) => (
                        <option key={team.teamId} value={team.teamId}>
                          {team.teamName}
                        </option>
                      ))}
                    </select>
                    {selectedTeam && (
                      <>
                        <p>Add any number of quizzers from this team.</p>
                        {selectedTeam.quizzers.map((quizzer) => (
                          <div
                            key={quizzer.quizzerId}
                            style={{ marginTop: 5, height: 25, minWidth: 100 }}
                          >
                            <button
                              className="link"
                              onClick={() => {
                                for (let team of newQuizData.teams) {
                                  for (let teamQuizzer of team.quizzers) {
                                    if (
                                      teamQuizzer.quizzerId ===
                                      quizzer.quizzerId
                                    )
                                      return;
                                  }
                                }
                                const newTeams = [...newQuizData.teams];
                                if (newTeams.length === 0)
                                  newTeams.push({
                                    teamId: uuid(),
                                    teamName: "Team Name",
                                    quizzers: [],
                                    active: true,
                                  });
                                newTeams[selectedQuizTeam].quizzers.push({
                                  ...quizzer,
                                  active: true,
                                });
                                updateQuizData("teams", newTeams);
                              }}
                            >
                              {quizzer.quizzerName}
                            </button>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <p style={{ marginTop: 0 }}>
                      Quizzers are sorted alphabetically
                    </p>
                    {getAllQuizzers().map((quizzer) => (
                      <div key={quizzer.quizzerId}>
                        <button
                          className="link"
                          onClick={() => {
                            for (let team of newQuizData.teams) {
                              for (let teamQuizzer of team.quizzers) {
                                if (teamQuizzer.quizzerId === quizzer.quizzerId)
                                  return;
                              }
                            }
                            const newTeams = [...newQuizData.teams];
                            if (newTeams.length === 0)
                              newTeams.push({
                                teamId: uuid(),
                                teamName: "Team Name",
                                quizzers: [],
                                active: true,
                              });
                            newTeams[selectedQuizTeam].quizzers.push({
                              ...quizzer,
                              active: true,
                            });
                            updateQuizData("teams", newTeams);
                          }}
                        >
                          {quizzer.quizzerName}
                        </button>
                      </div>
                    ))}
                  </>
                )}
              </div>
              {newQuizData.teams.map((team, index) => (
                <div key={team.teamId} style={{ marginLeft: 20 }}>
                  {!newQuizData.isPractice && (
                    <>
                      <div
                        style={{
                          width: "100%",
                          height: 8,
                          backgroundColor:
                            selectedQuizTeam === index
                              ? colors.bqaBlue
                              : "transparent",
                          marginBottom: 4,
                        }}
                      />
                      <div>
                        <button
                          className="link"
                          onClick={() => {
                            const newTeams = [...newQuizData.teams];
                            newTeams.splice(index, 1);
                            if (
                              selectedQuizTeam === newTeams.length &&
                              newTeams.length > 0
                            )
                              setSelectedQuizTeam(newTeams.length - 1);
                            updateQuizData("teams", newTeams);
                          }}
                        >
                          Remove Team
                        </button>
                      </div>
                      <SimpleStringInput
                        caption={`Team ${index + 1} Name`}
                        value={team.teamName}
                        onChange={(value) => {
                          const newTeams = [...newQuizData.teams];
                          newTeams[index].teamName = value;
                          updateQuizData("teams", newTeams);
                        }}
                        onFocus={() => setSelectedQuizTeam(index)}
                      />
                    </>
                  )}

                  {team.quizzers
                    .filter((q) => q.active)
                    .map((quizzer: skTypes.Quizzer, qIndex) => (
                      <div key={quizzer.quizzerId} style={{ marginBottom: 5 }}>
                        <span>{quizzer.quizzerName}</span>
                        <button
                          className="link"
                          style={{ height: 25, marginLeft: 4 }}
                          onClick={() => {
                            const newTeams = [...newQuizData.teams];
                            const newQuizzers = [
                              ...newQuizData.teams[0].quizzers,
                            ];
                            newQuizzers.splice(qIndex, 1);
                            newTeams[index] = {
                              ...newTeams[index],
                              quizzers: newQuizzers,
                            };
                            updateQuizData("teams", newTeams);
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                </div>
              ))}
            </Row>
            {!newQuizData.teams.some((team) => team.quizzers.length === 0) && (
              <div style={{ marginTop: 40 }}>
                <button
                  className="clickable"
                  onClick={() => {
                    // Check for duplicate quizzers
                    const quizzersUsed: Set<string> = new Set<string>();
                    let resultTeams: skTypes.Team[] = [];
                    if (newQuizData.isPractice) {
                      const resultQuizzers: skTypes.Quizzer[] = [];
                      newQuizData.teams[0].quizzers.forEach(
                        (quizzer: skTypes.Quizzer) => {
                          if (
                            quizzer.quizzerId &&
                            !quizzersUsed.has(quizzer.quizzerId)
                          ) {
                            quizzersUsed.add(quizzer.quizzerId);
                            resultQuizzers.push(quizzer);
                          }
                        }
                      );
                      resultTeams = [];
                      resultQuizzers.forEach((quizzer, index) => {
                        if (index % 5 === 0) {
                          // create new team
                          resultTeams.push({
                            teamId: String(index),
                            teamName: String(index),
                            quizzers: [],
                            active: true,
                          });
                        }
                        resultTeams[resultTeams.length - 1].quizzers.push(
                          quizzer
                        );
                      });
                    } else {
                      resultTeams = [...newQuizData.teams];
                      resultTeams.forEach((team) => {
                        team.quizzers.forEach((quizzer, index) => {
                          if (quizzersUsed.has(quizzer.quizzerId))
                            team.quizzers.splice(index, 1);
                          quizzersUsed.add(quizzer.quizzerId);
                        });
                      });
                    }

                    const newQuiz = {
                      ...newQuizData,
                      teams: resultTeams,
                      moments: [],
                    };
                    if (newQuiz.quizmaster) {
                      if (!season.quizmasters) {
                        season.quizmasters = [newQuiz.quizmaster];
                      } else {
                        if (!season.quizmasters?.includes(newQuiz.quizmaster))
                          season.quizmasters.push(newQuiz.quizmaster);
                      }
                    }

                    handler.runRequest(
                      createQuiz(newQuiz, user !== undefined, season),
                      "Creating the quiz...",
                      "Quiz created!"
                    );
                  }}
                >
                  Start Quiz!
                </button>
              </div>
            )}
          </div>
        </div>
      );
    },
  });

  return provider.getPage();
}
