import React from "react";

interface InfoBoxProps {
  onGoBack: () => void;
  children: React.ReactNode;
}
export function InfoBox(props: InfoBoxProps) {
  return (
    <div>
      <div>{props.children}</div>
      <button
        className="back-button"
        style={{ marginTop: 15 }}
        onClick={props.onGoBack}
      >
        Go Back
      </button>
    </div>
  );
}
