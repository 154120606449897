import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { SimpleStringInput } from "../Inputs";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";
import { tournamentSearch } from "../../state/actions/tournamentActions";
import { Grid } from "@mui/material";

interface HeaderProps {
  pageName: string;
  navigateBack: string;
  pageId?: string;
  secondaryId?: string;
  defaultSearch?: string;
}
export function TournamentHeader(props: HeaderProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootStore) => state.authentication);

  const [isSearching, setIsSearching] = useState(
    props.defaultSearch !== undefined
  );
  const [searchText, setSearchText] = useState(props.defaultSearch || "");
  useEffect(() => {
    document.getElementById("tournament-search-text")?.focus();
  }, [isSearching]);
  const search = () => {
    if (!searchText) return;
    setIsSearching(false);
    dispatch(tournamentSearch(searchText));
    navigate(`/tournaments/search/${encodeURIComponent(searchText)}`);
  };

  return (
    <Grid container style={{ marginBottom: 20 }} spacing={2}>
      <Grid item>
        <button
          className="back-button"
          onClick={() => {
            navigate(props.navigateBack);
            window.scrollTo(0, 0);
          }}
        >
          Go Back
        </button>
      </Grid>
      <Grid item>
        <h1 style={{ margin: 0 }}>{props.pageName}</h1>
      </Grid>
      <Grid item>
        {!isSearching && (
          <button className="link" onClick={() => setIsSearching(true)}>
            <SearchIcon width={26} height={26} />
          </button>
        )}
        {isSearching && (
          <div>
            <SimpleStringInput
              value={searchText}
              onChange={setSearchText}
              caption="Search"
              placeholder="Search"
              onKeyUp={(e) => {
                if (!e.code) return;
                if (e.code.includes("Enter")) {
                  search();
                  document.getElementById("tournament-search-text")?.blur();
                }
                if (e.code === "Escape") setIsSearching(false);
                window.scrollTo(0, 0);
              }}
              id="tournament-search-text"
            />
            <button className="link" onClick={search}>
              <SearchIcon width={26} height={26} />
            </button>
          </div>
        )}
      </Grid>
      <Grid item>
        {user?.securityClearance.logonType === "ADMIN" && (
          <>
            {props.pageId && (
              <Link
                style={{ marginRight: 20, marginTop: 8 }}
                to={`/tournaments/edit/${props.pageId}/${
                  props.secondaryId || ""
                }`.replace(/ /gi, "_")}
                onClick={() => window.scrollTo(0, 0)}
              >
                Edit
              </Link>
            )}
            <Link
              style={{ marginRight: 20, marginTop: 8 }}
              to="/tournaments/admin"
              onClick={() => window.scrollTo(0, 0)}
            >
              Admin
            </Link>
            {props.pageId !== "Help" && (
              <Link
                style={{ marginTop: 8, marginRight: 20 }}
                to="/tournaments/help"
                onClick={() => window.scrollTo(0, 0)}
              >
                Help
              </Link>
            )}
            <Link
              style={{ marginTop: 8 }}
              to="/tournaments/create"
              onClick={() => window.scrollTo(0, 0)}
            >
              Create
            </Link>
          </>
        )}
      </Grid>
    </Grid>
  );
}
