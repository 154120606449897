import { useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Row } from "../../components/Row";
import { useRequestHandler } from "../../hooks/requestHandler";
import { sendContact } from "../../state/actions/tournamentActions";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { Selection, StringInput } from "../../components/Inputs";
import { useSelector } from "react-redux";
import { RootStore } from "../../state/store";

export function TournamentContact() {
  useDocumentTitle("Contact - BQA Tournaments");
  const location = useLocation();
  const navigate = useNavigate();
  const contactInfo = location.state?.contactInfo || {};
  const fromPage = !!contactInfo?.pageId;
  const handler = useRequestHandler({
    onSuccess: () => {
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    },
  });
  const { user } = useSelector((state: RootStore) => state.authentication);

  const [formData, setFormData] = useState<any>({
    ...contactInfo,
    email: user?.email || "",
  });

  return (
    <div className="page">
      <Row>
        <Link
          className="back-button"
          to={
            fromPage
              ? `/tournaments/${contactInfo.pageId}/${
                  contactInfo.secondaryId || ""
                }`.replace(/ /g, "_")
              : "/tournaments"
          }
        >
          Go Back
        </Link>
        <h1 style={{ marginLeft: 20 }}>Contact</h1>
      </Row>
      <p>
        Feel free to contact us with improvements, suggestions, or fixes to any
        of the information displayed on BQA Tournaments.
      </p>
      {fromPage && (
        <p>{`Page: ${contactInfo.pageId} ${contactInfo.secondaryId || ""}`}</p>
      )}
      <Selection
        caption="Query:"
        value={formData.contactType}
        values={{
          ERROR: "Incorrect Information",
          FEATURE: "Feature Suggestion",
          INFO: "Information Suggestion",
        }}
        onChange={(value) =>
          setFormData({
            ...formData,
            contactType: value,
          })
        }
        placeholder="Select an Option"
        belowText="What are you contacting us about?"
      />
      <StringInput
        caption="Email:"
        belowText="Providing an email is optional, but it will allow you to get a reply to your comment."
        value={formData.email}
        onChange={(value) => setFormData({ ...formData, email: value })}
      />
      <div style={{ marginTop: 10 }}>
        <label htmlFor="t-contact-details">
          Any details you would like to add?
        </label>
      </div>
      <div>
        <textarea
          id="t-contact-details"
          style={{ marginTop: 4, width: 250, height: 60 }}
          value={formData.additionalText}
          onChange={(e) =>
            setFormData({
              ...formData,
              additionalText: e.target.value,
            })
          }
        />
      </div>
      <button
        className="clickable"
        style={{ marginTop: 20 }}
        onClick={() => {
          if (!formData.contactType || !formData.additionalText) {
            handler.showMessage(
              "Make sure all fields are filled out.",
              "error"
            );
            return;
          }
          handler.runRequest(
            sendContact(formData),
            "Submitting information...",
            "Your request has been submitted! Thank you for your assistance."
          );
        }}
      >
        Submit
      </button>
      {handler.snackbar}
    </div>
  );
}
