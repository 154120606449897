import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { DataTable } from "../../components/DataTable/DataTable";
import { TournamentHeader } from "../../components/TournamentComponents/TournamentHeader";
import { StringInput } from "../../components/Inputs";
import { getTournamentsUsage } from "../../state/actions/tournamentActions";

export function TournamentsUsage() {
  const dispatch = useDispatch();
  const { tournamentsUsage, selectedPage } = useSelector(
    (state: RootStore) => state.tournaments
  );

  const [pageId, setPageId] = useState(selectedPage?.pageId || "");
  const [secondaryId, setSecondaryId] = useState(
    selectedPage?.secondaryId || ""
  );

  return (
    <div className="page">
      <TournamentHeader
        pageName="Tournaments Usage"
        navigateBack="/tournaments"
      />
      <StringInput caption="Page ID" value={pageId} onChange={setPageId} />
      <StringInput
        caption="Secondary ID"
        value={secondaryId}
        onChange={setSecondaryId}
      />
      <button
        className="clickable"
        onClick={() => dispatch(getTournamentsUsage(pageId, secondaryId))}
        style={{ marginTop: 10 }}
      >
        Get Views
      </button>
      <DataTable<any>
        data={tournamentsUsage}
        columns={[
          {
            title: "Date",
            property: "date",
            render: (obj) => obj.date,
          },
          {
            title: "Page View Count",
            property: "count",
            render: (obj) => obj.count,
          },
        ]}
        style={{ width: 300, marginTop: 20 }}
        getKey={(obj) => obj.date}
      />
    </div>
  );
}
