import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Row } from "../../../components/Row";
import { QuestionsJumpsGraphs } from "./QuestionsJumpsGraphs";
import * as skTypes from "../../../types/sk";
import { useKeyedParams } from "../../../hooks/useParams";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";

interface StatsProps {
  seasonId: string;
}

export function SingleTeamStats() {
  useDocumentTitle("Team Stats - Bible Quiz Academy");
  const navigate = useNavigate();
  const location = useLocation();
  const { seasonId } = useKeyedParams<StatsProps>();

  const stats: skTypes.TeamStats | undefined = location.state?.teamStats;

  if (!stats) {
    return <Navigate to={`/sk/${seasonId}/teamstats`} state={location.state} />;
  }

  return (
    <div className="page" style={{ minWidth: 630 }}>
      <Row>
        <button className="back-button" onClick={() => navigate(-1)}>
          Go Back
        </button>
        <h1 style={{ marginLeft: 10 }}>{`Team Stats - ${stats.teamName}`}</h1>
      </Row>

      <QuestionsJumpsGraphs
        jumpsByType={stats.jumpsByType}
        questionsByType={stats.questionsByType}
        knowledgeErrors={stats.knowledgeErrorsByType}
      />
    </div>
  );
}
