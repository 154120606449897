import { Fragment, useEffect, useState } from "react";
import { QMRating, qmRatings, Quiz } from "../../types/sk";
import { enumMap } from "../../util/generalUtil";
import { Modal } from "../Modal/Modal";
import { Row } from "../Row";
import { StringInput } from "../Inputs";

interface Props {
  open: boolean;
  quiz: Quiz;
  onClose: (rating?: QMRating, quizmaster?: string) => void;
}
export function QMRatingSelection(props: Props) {
  const [ratings, setRatings] = useState<QMRating | undefined>(
    props.quiz.qmRating
  );
  const [quizmaster, setQuizmaster] = useState(props.quiz.quizmaster);

  useEffect(() => {
    if (props.open) setRatings(props.quiz.qmRating);
  }, [props.open]);

  const onClose = () => props.onClose(ratings, quizmaster);
  return (
    <Modal
      onClose={props.onClose}
      open={props.open}
      title="Please give Quizmaster ratings."
    >
      <StringInput
        value={quizmaster}
        onChange={setQuizmaster}
        caption="Quizmaster:"
        inputStyle={{ color: "black" }}
      />
      {enumMap(qmRatings, (rating, description) => {
        const id = `slider-${rating}`;
        const currentValue = ratings?.[rating] || 0;
        return (
          <Fragment key={rating}>
            <p style={{ marginBottom: 0 }}>{description}</p>
            <Row>
              <input
                type="range"
                className="slider"
                style={{ width: `calc(100% - 50px)`, marginRight: 10 }}
                step={1}
                value={currentValue}
                min={0}
                max={10}
                id={id}
                onChange={(e) => {
                  const resultRating: QMRating = { ...ratings } || {};
                  const value = e.target.valueAsNumber;
                  resultRating[rating] = value || undefined;
                  setRatings(resultRating);
                }}
              />
              <label htmlFor={id} style={{ verticalAlign: "center" }}>
                {currentValue || "N/A"}
              </label>
            </Row>
          </Fragment>
        );
      })}
      <div style={{ marginTop: 20 }}>
        <button
          className="back-button"
          onClick={onClose}
          style={{ float: "right" }}
        >
          Close
        </button>
      </div>
    </Modal>
  );
}
