import { combineReducers } from "redux";
import appDetails from "./appDetails";
import settings from "./settings";
import material from "./material";
import sk from "./sk";
import tournaments from "./tournaments";
import authentication from "./authentication";

export default combineReducers({
  appDetails,
  authentication,
  settings,
  material,
  sk,
  tournaments,
});
