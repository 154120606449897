import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row } from "../../components/Row";
import * as colors from "../../util/colors";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

interface SKHelpItem {
  tabTitle: string;
  content: React.ReactNode;
  key: string;
}

export function SKHelp() {
  useDocumentTitle("Scorekeeper Help - Bible Quiz Academy");
  const navigate = useNavigate();
  const location = useLocation();

  const helpItems: SKHelpItem[] = [
    {
      tabTitle: "Introduction",
      key: "intro",
      content: (
        <div>
          <p>
            The <b>Scorekeeper function</b> of BQA allows coaches and spectators
            to keep track of a quiz like never before, with tools for both
            beginner and advanced coaches.
          </p>
          <p>
            You can organize your quizzes and teams, keep track of all of your
            historical data, manage data over many tournaments and seasons, and
            see statistics for everything that has happened.
          </p>
          <p>
            Just like the rest of Bible Quiz Academy, we have support for both
            touchscreen devices and non-touchscreen, so whether you have an
            iPad, laptop, or even a phone, you will be able to keep score just
            as easily as everyone else.
          </p>
          <p>
            You can
            <button
              className="link"
              onClick={() => {
                navigate("/login");
              }}
            >
              make an account
            </button>
            for better access to BQA Scorekeeper. This is not required, but it
            will enhance your experience, allow you to share your quizzes with
            others, and download teams and quiz sets.
          </p>
        </div>
      ),
    },
    {
      key: "starting",
      tabTitle: "Starting a Quiz",
      content: (
        <div>
          <p>
            The first thing you need is a <b>season</b>. A season allows you to
            keep track of everything for a specific set of teams, be it your
            local season, a regional tournament, or Nationals.
          </p>
          <p>
            Before you start keeping score, you will need all the{" "}
            <b>team rosters</b> in. You can manually add the teams yourself, or
            you can
            <button
              className="link"
              style={{ fontSize: 16 }}
              onClick={() => navigate("/", { state: { menuView: "Contact" } })}
            >
              send me the teams
            </button>
            so they can be in the importable team list (if you are logged in).
            That will allow you to get every team in the tournament with only
            one click. You can change the teams at any time, though if you want
            to remove a quizzer from a team, you can only deactivate the
            quizzer, not fully delete. This is to allow the quizzer to stay in
            statistics, just in case the quizzer showed up in a previous quiz.
            The team set may change due to a quizzer being added to a roster; if
            this happens, you can re-import the teams. For your own team, you
            may want to create a few preset lineups for the lineup cards you
            have, to make the start of each quiz a little easier.
          </p>
          <p>
            Next, you'll need an <b>event</b>. Events keep track of quizzes for
            a specific day, or however you choose to organize your quizzes. For
            example, "Round Robin" or "October Quiz" might be event names.
          </p>
          <p>
            If you have imported a team set, there may be a schedule ready for
            you to import! Instead of clicking "Create New Event", click "Import
            Event Schedule" instead. This will allow you to import all quizzes
            for your team, saving you the hassle of setting up each individual
            quiz yourself. Like before, send me the schedule before the day of
            quizzing so it can be imported.
          </p>
          <p>
            Now, you're ready to start a quiz. Click the "New Quiz" button,
            select your three teams, and (optionally) add a quiz name and mark
            the round and site. Click "Start Quiz" and you'll be in the quiz
            site! Before you get overwhelmed at everything you see on this next
            screen, see how to keep score on the next tab.
          </p>
          <p>
            You may delete a quiz through the Edit Quiz Details view. You can
            delete an event if it has no quizzes inside of it, and you can
            delete a season if it has no events.
          </p>
          <p>
            If you're keeping score on material other than the current year's
            quiz material, go to Chapters in the main menu and check the SK
            boxes for each book you are scorekeeping on.
          </p>
        </div>
      ),
    },
    {
      key: "sk",
      tabTitle: "Scorekeeping",
      content: (
        <div>
          <p>
            <b>To keep score</b>, all you need to do is say who got points and
            errors. All the calculation is handled for you, and several things
            will be color-coded based on who is bonus potential, how many errors
            a team has, and whether a quizzer has a foul. How specifically to
            give points and errors will differ based on whether you are using a
            touchscreen device or not, so make sure you have the right
            touchscreen option selected in Settings, and see the next two tabs
            for details.
          </p>
          <p>
            You can use the Play-By-Play screen to see a recap of the quiz, and
            to edit past events in the quiz if you made a mistake. Click on the
            team's name, the quizzer's name, or what happened to get a list of
            items to change the question result to. You can also delete a
            question from the quiz or reorder the questions by clicking the
            question number on the left, then one of the buttons at the top.
            However, if you only made a mistake on the most recent question, a
            faster way to fix it will be the undo button, without ever entering
            the Play-By-Play screen.
          </p>
          <p>
            For things that happen in a quiz that aren't points or errors, such
            as a foul, coach's timeout, or start of overtime, click the
            specified areas of the screen. For quiz-level events (halftime,
            start of overtime), use the "Quiz" button at the top left part of
            the screen. For team-level events, use the team's name. For
            quizzer-level events (fouls, or subbing a quizzer out), use the
            quizzer's seat number. Many of all these are optional, but they help
            with statistics, and some will affect the score.
          </p>
          <p>
            The Show Team view provides a large-font view for your quizzers on
            the seats to get a snapshot of the state of the quiz. But they see
            more than just the score; they'll see all the details they need to
            know a strategy for the next question. The color of the number of
            points corresponds to the error penalty section the team is in
            (yellow for -10 points, red for -20 points), and if the team name is
            in red, it means the team has bonus potential. This way, quizzers
            can work out how to jump without any verbal communication (just not
            when a quizzer is answering). When you show your score to your
            quizzers at halftime and coach's timeouts, you can reverse the order
            of the teams (from the Tools menu) to make the screen make more
            sense to quizzers on the chairs.
          </p>
          <p>
            There are some other tools to help you out, available from the Tools
            menu. The running score graph shows the scores throughout the quiz,
            with lines and dots to show where other events in the quiz happened.
            You can reverse the order of the teams, so when you show your screen
            to the quizzers at halftime, the view makes more sense to them.
          </p>
          <p>
            For more advanced users, there are options for you to keep track of
            more than just who got points and errors. Selecting "Advanced Mode"
            in Settings will allow you to choose the "type" of error a quizzer
            received, be it a knowledge error, reference jump error, etc. For
            knowledge errors, you will also be able to select the type of
            knowledge error: the quizzer was in the wrong verse, said an
            incorrect word, gave an invalid question, ran out of time, or the
            quizmaster made a bad call. In addition, you will be able to keep
            selecting words in the question past the end of the reference and
            even mark a portion of a word that a quizzer got, so there can be
            better statistics on who knows their material well and which
            chapters have words on them more often.
          </p>
          <p>
            Advanced Mode will also enable additional features from the Tools
            menu, namely the Win Probability Graph. This simulates the remainder
            of the quiz from every question in the quiz, giving you a good
            estimate of how the teams sit, considering bonuses, errors, etc.
          </p>
        </div>
      ),
    },
    {
      key: "touch",
      tabTitle: "Touchscreen Actions",
      content: (
        <div>
          <p>
            If you are scorekeeping on a <b>touchscreen device</b>, most
            everything is quite the same as other scorekeeping apps you may be
            familiar with, and similar to Quiz Now and Verse Cards in BQA
            Online. First, make sure Touchscreen Mode is checked in Settings in
            Main Menu. Swipe Right on a quizzer to give 20 points, left to give
            an error, and tap two quizzers to switch them.
          </p>
          <p>
            For miscellaneous items, such as assigning a foul or coach's
            timeout, you'll have to tap the two items (one to open the menu, one
            to select the action). For example, tap the quizzer's number, then
            "Foul" to give the quizzer a foul. If you tap a quizzer's number by
            mistake and the menu appears, tap somewhere else to make it go away.
          </p>
          <p>
            For especially small devices such as phones, you can enable Compact
            View from Settings. This will disable many of the features BQA
            offers, but will give an interface better suited for your screen.
            All essential scorekeeping functionality will still be available,
            and you can also use Compact View when you watch a quiz.
          </p>
        </div>
      ),
    },
    {
      key: "hotkeys",
      tabTitle: "Hotkeys",
      content: (
        <div>
          <p>
            If you are scorekeeping on a device with a <b>keyboard</b>, there
            are many hotkeys you can use to make your life easier. First, once
            you are in a quiz, note the blue bar on the right side of the
            screen. It determines what a lot of the hotkeys do; whether they
            point toward the scoresheet at the top, or the verse and questions
            at the bottom. To switch the blue bar, move your mouse into the
            respectively region of the screen, or click the enter key.
          </p>
          <p>
            If the blue bar is on the top half of the screen, use the number
            keys to select a quizzer. The first number is the team, and the
            second is the quizzer. So if you want to select seat 5 of the team
            on the left, type 1 then 5. Once you have a quizzer selected, use
            the arrow keys to move your selection around, namely, to move the
            selection to either points or errors for that quizzer. Hit the
            spacebar to award points or errors, depending on what you have
            selected. Alternatively, you can also click on the quizzer's name,
            points, or errors, instead of using hotkeys. A second click on
            points or errors assigns it, and for when a substitution occurs,
            clicking on two quizzer's names (or typing their numbers) switches
            them.
          </p>
          <p>
            If the blue bar is on the bottom half of the screen, use the number
            keys to select a reference, and to see the verse and questions. If
            you need to select a book, use the number keys for that as well. Use
            the backspace to go back, and the colon to add a colon when needed
            (just like the buttons on the number pad do). If the reference might
            not be finished, but it is (for example, Revelation 3:1), use the
            shift key as a modifier when selecting the first word. If you are in
            Advanced mode, you will use the number keys to select the words the
            quizzer received, or Q for quote.
          </p>
          <p>
            The number keys are based off the location of the blue bar, but
            everything else isn't. The rest of the hotkeys allow you to click
            certain buttons without moving the mouse. These hotkeys can be
            customized; click the button below to see all of them and change
            them if you like. If you have ideas for other hotkeys to be added,
            please let me know!
          </p>
          <Link className="link" style={{ paddingLeft: 0 }} to="/sk/hotkeys">
            Edit Scorekeeper Hotkeys
          </Link>
        </div>
      ),
    },
    {
      key: "watch",
      tabTitle: "Watch Quizzes",
      content: (
        <div>
          <p>
            You can <b>watch quizzes</b> that are being kept score by other
            people! To watch a quiz, click "Watch Quiz" from the main menu. From
            there you will see all available quizzes for you to watch. Click one
            of those quizzes to join it. Once inside the quiz, you will have all
            the capabilities the original coach will. You can see the quiz
            history, graphs, and more. You will not have access to the reference
            that is currently selected, but you can select references yourself
            if you wish.
          </p>
          <p>
            Quizzes you are watching will be refreshed automatically every eight
            seconds, though you can click Refresh at the top to speed it up. If
            for some reason the quiz cannot be retrieved (maybe the scorekeeper
            removed the quiz or changed the password, or you lost internet
            connection), you will see a message at the top saying there's an
            error, though you will still be able to view the scoresheet.
          </p>
          <p>
            To allow other people to watch your quiz, select "Broadcast
            Publicly", when making a new quiz or editing quiz details. This will
            make your quiz visible to people on other devices. You will need to
            be logged in.
          </p>
        </div>
      ),
    },
    {
      key: "stats",
      tabTitle: "Statistics",
      content: (
        <div>
          <p>
            The <b>statistics</b> in Scorekeeper allow you to see details into
            all the quizzes you have kept score for. The main two are Quizzer
            Stats and Team stats, which show aggregate statistics for all the
            quizzers and teams respectively. Note that in team stats, the record
            only shows the record for quizzes that have been marked as complete.
            You can show the total points and errors of all quizzes you have, or
            you can divide them by the number of quizzes for the quizzer or
            team.
          </p>
          <p>
            You can enable many different filters for a more detailed look into
            the quizzes you have. These include:
          </p>
          <ul>
            <li>
              Filter by quiz: Select a set of quizzes and events to gather
              statistics from
            </li>
            <li>
              Filter by team: Only include quizzes that have certain teams in
              them
            </li>
            <li>
              Filter by quizzers: Only include questions when certain quizzers
              are on the seats.
            </li>
            <li>
              Filter by material: Only include questions from certain chapters
              and books
            </li>
            <li>
              Filter by jump type: Only include questions that had certain jump
              types
            </li>
            <li>
              Filter by quizmaster: Only include quizzes with certain
              quizmasters
            </li>
          </ul>
          <p>
            There are also some other settings that you can turn on and off,
            such as whether to include questions from overtime.
          </p>
          <p>
            If you have Advanced Mode enabled, you will be able to click on a
            quizzer or team to see more detailed information about them, most
            notably a breakdown of their questions and jumps. In addition, at
            the bottom of Quizzer Stats you will see Total Stats, which is
            totals the jumps of all quizzers and teams combined, based on the
            filters you have selected.
          </p>
          <p>
            Also with Advanced Mode, you'll see some in-depth statistics for
            quizzers and teams. These include:
          </p>
          <ul>
            <li>
              Seat Time: The percentage of questions this quizzer was on the
              chairs, of the questions the quizzer was elgible to jump.
            </li>
            <li>
              Jump Luck: How lucky the quizzer or is - that is, how many
              questions are they getting right compared to how many they should
              be getting right, based on how fast they are jumping.
            </li>
            <li>
              Answer Luck: How lucky the quizzer is - that is, considering only
              the choices the quizzer got, how many did they get right compared
              to the expected average
            </li>
          </ul>
          <p>
            For both luck metrics, knowledge errors are excluded, and 100%
            should be the average, meaning quizzers are getting as many
            questions right as they should be getting. However, quizzers can
            make their own luck by answering well.
          </p>
          <p>
            Many more advanced statistics may be under further development, so
            stay tuned for more updates and
            <Link
              className="link"
              style={{ fontSize: 16 }}
              to="/"
              state={{ menuView: "Contact" }}
            >
              let me know
            </Link>
            if you have any features you want to see!
          </p>
        </div>
      ),
    },
    {
      key: "practice",
      tabTitle: "Practice Seasons",
      content: (
        <div>
          <p>
            You can also make <b>practice seasons and quizzes</b> to help keep
            track of quizzers' progress through the year. A practice quiz has
            just quizzers, not teams, and as many questions as you like can be
            asked, with no quiz outs or error outs. All information you
            traditionally keep, such as the reference, will be recorded, and you
            will still be able to see stats later.
          </p>
          <p>
            To make a practice season, click Create New Practice Season from the
            Seasons menu. You will still have teams like normal, but in a quiz,
            you will add quizzers to the quiz, rather than teams. Some features
            will not be available in a practice quiz, since there is no actual
            score - just a bunch of questions to give your quizzers practice.
          </p>
          <p>
            However, you can still have team quizzing in a practice season. The
            teams will be temporary, that is, you will set them up individually
            for each quiz. This can be helpful if you have a 3v3 quiz where you
            split a full team in two, or something like that.
          </p>
          <p>
            Note that practice seasons are completely separate from regular
            seasons, and you won't be able to compare stats from practice
            quizzes against stats from regular quizzes.
          </p>
        </div>
      ),
    },
  ];

  const helpItemFromState = helpItems.findIndex(
    (item: SKHelpItem) => item.key === location.state?.helpItem
  );
  const selectedHelp =
    helpItemFromState >= 0 && helpItemFromState < helpItems.length
      ? helpItemFromState
      : 0;

  const setSelectedHelp = (newHelp: number): void => {
    navigate("/sk/help", {
      state: { helpItem: helpItems[newHelp].key },
      replace: true,
    });
  };

  return (
    <div className="page">
      <Row>
        <button className="back-button" onClick={() => navigate(-1)}>
          Go Back
        </button>
        <h1 style={{ marginLeft: 10 }}>Scorekeeper Help</h1>
      </Row>
      <Row style={{ marginTop: 20, marginBottom: 20 }}>
        {helpItems.map((item: SKHelpItem, index: number) => (
          <button
            key={item.key}
            className="link"
            style={{
              borderBottom:
                index === selectedHelp
                  ? `4px solid ${colors.bqaBlue}`
                  : `1px solid ${colors.bqaLightBlue}`,
              paddingBottom: index === selectedHelp ? 12 : 6,
              fontSize: 16,
              paddingRight: 8,
              paddingLeft: 8,
              margin: 0,
            }}
            onClick={() => setSelectedHelp(index)}
          >
            {item.tabTitle}
          </button>
        ))}
      </Row>
      {helpItems[selectedHelp].content}
      {selectedHelp + 1 < helpItems.length && (
        <button
          className="link"
          onClick={() => setSelectedHelp(selectedHelp + 1)}
          style={{ fontSize: 16, float: "right" }}
        >
          {"Next >"}
        </button>
      )}
    </div>
  );
}
