import { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { Line } from "react-chartjs-2";
import { Check } from "../../components/Check/Check";
import { DataTable } from "../../components/DataTable/DataTable";
import { Row } from "../../components/Row";
import { useRequestHandler } from "../../hooks/requestHandler";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { logOut, SET_LOGOUT } from "../../state/actions/authentication";

export function UserInfo() {
  useDocumentTitle("My Account - Bible Quiz Academy");
  const dispatch = useDispatch();
  const [view, setView] = useState<"USER" | "SIGNOUT">("USER");
  const [signOutData, setSignOutData] = useState<any>({});
  const navigate = useNavigate();
  const { user } = useSelector((state: RootStore) => state.authentication);
  const lightMode = useSelector((state: RootStore) => state.settings.lightMode);
  const { usage, allWeeksUsage } = useSelector(
    (state: RootStore) => state.appDetails
  );
  const finishSignout = () => {
    if (signOutData.removeData) {
      localStorage.clear();
      window.location.assign("/");
    } else {
      dispatch({ type: SET_LOGOUT });
      navigate("/");
    }
  };
  const handler = useRequestHandler({
    onSuccess: (key) => {
      if (key === "SIGNOUT") {
        finishSignout();
      }
    },
    onError: (key) => {
      if (key === "SIGNOUT") {
        finishSignout();
      }
    },
  });

  if (!user) return <Navigate to="/" />;

  const getTournamentAccessConent = () => {
    const { tournamentAccess } = user.securityClearance;
    if (!tournamentAccess || tournamentAccess.length === 0) return null;
    let result = "Tournament Access: ";
    tournamentAccess.forEach((ministry, index) => {
      result = `${result}${index > 0 ? ", " : ""}${ministry}`;
    });

    return <p>{result}</p>;
  };

  const getMainContent = () => {
    switch (view) {
      case "USER":
        return (
          <div>
            <p>{`Username: ${user.userName}`}</p>
            <p>{`Email: ${user.email}`}</p>
            {getTournamentAccessConent()}
            {usage.length > 0 && (
              <Row>
                <DataTable<any>
                  columns={[
                    {
                      title: "Date",
                      property: "date",
                      render: (obj) => obj.date,
                      sortFunc: true,
                    },
                    {
                      title: "Ping Count",
                      property: "p",
                      render: (obj) => obj.p,
                      sortFunc: true,
                    },
                  ]}
                  data={usage}
                  getKey={(obj) => obj.date}
                  style={{ width: 400 }}
                  defaultSort={{
                    property: "date",
                    direction: "desc",
                  }}
                />
                <Line
                  style={{ maxWidth: 600, maxHeight: 600, marginLeft: 20 }}
                  data={{
                    labels: allWeeksUsage.map((week) => week.d),
                    datasets: [
                      {
                        data: allWeeksUsage.map((week) =>
                          Math.round(week.p / 7)
                        ),
                        label: "Ping Count",
                        borderColor: lightMode ? "black" : "white",
                        backgroundColor: lightMode ? "black" : "white",
                      },
                    ],
                  }}
                />
              </Row>
            )}

            <div>
              <button
                className="link"
                style={{ paddingLeft: 0 }}
                onClick={() => setView("SIGNOUT")}
              >
                Sign Out
              </button>
            </div>
            {/* <div style={{ marginTop: 30 }}>
              <button
                className="link"
                style={{ paddingLeft: 0 }}
                onClick={() => {}}
              >
                Delete Account
              </button>
            </div> */}
          </div>
        );
      case "SIGNOUT":
        return (
          <div>
            <p>You can sign back in at any time</p>
            <Check
              checked={signOutData.removeData}
              onClick={(checked) => {
                setSignOutData({
                  ...signOutData,
                  removeData: checked,
                });
              }}
              style={{ width: 310 }}
            >
              Remove all BQA data from this device
            </Check>
            <p>
              If you have settings or quizzes from Scorekeeper, you can remove
              them from this device only when you signout.
            </p>
            <Row>
              <button
                className="link"
                onClick={() => {
                  handler.runRequest(
                    logOut(),
                    "Signing out...",
                    "You have logged out!",
                    "SIGNOUT"
                  );
                }}
              >
                Sign Out
              </button>
              <button
                className="link"
                style={{ marginLeft: 10 }}
                onClick={() => setView("USER")}
              >
                Cancel
              </button>
            </Row>
          </div>
        );
    }
  };
  return (
    <div className="page">
      {handler.snackbar}
      <Row>
        <Link className="back-button" to="/">
          Back to Menu
        </Link>
        <h1 style={{ marginLeft: 20 }}>My Account</h1>
      </Row>
      {getMainContent()}
    </div>
  );
}
