import { useEffect, useRef, useState } from "react";
import { useKeyedParams } from "../../hooks/useParams";
import io, { Socket } from "socket.io-client";
import Peer from "peerjs";
import { Navigate, useLocation } from "react-router-dom";
import { useAcme } from "../../hooks/useAcme";
import { Row } from "../../components/Row";
import { getMediaStream } from "../../util/voiceUtils";
import { Check } from "../../components/Check/Check";
import { xor } from "../../util/generalUtil";

const videoContainer = "multiplayer-teams-video-container";
type Roles = "QM" | "TEAM" | "SPEC";

// eslint-disable-next-line
type PhaseType =
  | "WAITING" // we will enter the quiz once the current question finishes
  | "READY" // A new question can be read
  | "ASKING" // a question is being read
  | "JUMPED" // I have jumped
  | "ANSWERING" // Someone is answering
  | "RULING"; // Time has expired and we are ruling on the result of the question

export function MultiplayerTeams() {
  const [phase, setPhase] = useState<PhaseType>("READY");
  const phaseRef = useRef<PhaseType>(phase);
  useEffect(() => {
    phaseRef.current = phase;
  }, [phase]);
  const [acmeTeam, setAcmeTeam] = useState<number>(0);
  const [padsOn, setPadsOn] = useState<boolean[]>(Array(5).fill(false));
  const [reversePads, setReversePads] = useState(false);
  const padsOnRef = useRef<boolean[]>(padsOn);
  const params = useKeyedParams<{ roomId: string }>();
  const location = useLocation();
  const state: { role?: Roles; includeVideo?: boolean; myName?: string } =
    location.state || {};
  const role = state?.role as Roles;
  const socketIO = useRef<Socket | undefined>(undefined);

  const myId = useRef<string>("");
  const teamDataRef = useRef<Record<string, any>>({});
  const [teamData, setTeamData] = useState<Record<string, any>>({});
  const teamIds = useRef<string[]>([]);
  const qmId = useRef<string>("");
  const allIds = useRef<string[]>([]);
  const [teamAnswering, setTeamAnswering] = useState<string>("");
  const [seatAnswering, setSeatAnswering] = useState<string>("");
  const startedReadingQuestion = useRef<number>(0);

  const acme = useAcme((teams) => {
    if (phaseRef.current === "ASKING") {
      let firstJump = -1;
      teams[acmeTeam].forEach((seat, index) => {
        if (padsOnRef.current[index] && xor([reversePads, seat === 1]))
          firstJump = index;
      });
      if (firstJump >= 0) {
        socketIO.current?.emit("JUMP", {
          teamId: myId.current,
          seat: firstJump + 1,
          timeDiff: Date.now() - startedReadingQuestion.current,
        });
        setTimeout(() => {
          // turn off audio, but still if the question is being asked
          if (phaseRef.current === "ASKING") {
            const video = document.getElementById(
              `video-${qmId.current}`
            ) as HTMLVideoElement;
            if (video) {
              video.muted = true;
            }
          }
        }, 200);
      }
    }
  });

  const addTitle = (id: string, title: string) => {
    const element = document.getElementById(`video-title-${id}`);
    if (element) {
      element.innerHTML = "";
      element.append(id === myId.current ? "You" : title);
    }
  };

  useEffect(() => {
    if (!role) return;
    const socket = io("/");
    socketIO.current = socket;
    const videoGrid = document.getElementById(videoContainer);

    const addVideoStream = (
      video: HTMLVideoElement,
      stream: MediaStream,
      id: string
    ) => {
      video.srcObject = stream;
      video.addEventListener("loadedmetadata", () => {
        video.play();
      });
      if (videoGrid && !document.getElementById(video.id)) {
        const element = document.createElement("div");
        video.style.width = "350px";
        const title = document.createElement("p");
        title.id = `video-title-${id}`;
        element.append(title);
        element.append(video);
        videoGrid.append(element);

        // Search for what the title should be
        let text = "";
        if (id === "") {
          text = "You";
        } else if (id === qmId.current) {
          text = "QM";
        } else if (id in teamDataRef.current) {
          text = teamDataRef.current[id].teamName;
        }
        if (text) addTitle(id, text);
      }
    };

    // @ts-ignore
    const myPeer = new Peer(undefined, {
      secure: true,
      debug: 2,
    });
    const myVideo = document.createElement("video");
    myVideo.muted = true;
    const peers: any = {};
    getMediaStream({
      video: state?.includeVideo === false || role === "SPEC" ? false : true,
      audio: role !== "SPEC",
    }).then((stream) => {
      addVideoStream(myVideo, stream as MediaStream, "");

      myPeer.on("call", (call) => {
        call.answer(stream);
        const video = document.createElement("video");
        video.id = `video-${call.peer}`; // The PeerJS id of the person calling
        call.on("stream", (userVideoStream) => {
          addVideoStream(video, userVideoStream, call.peer);
        });
        call.on("close", () => {
          video.pause();
          video.remove();
        });
      });

      socket.on("TEAM_UPDATE", (params) => {
        const currentIds: Set<string> = new Set(allIds.current);
        params.participantIds.forEach((userId: string) => {
          // Check for user connection
          if (!currentIds.has(userId)) {
            if (userId === myPeer.id) return;
            connectToNewUser(userId, stream as MediaStream);
          } else {
            currentIds.delete(userId);
          }

          // Team Titles
          let title = "";
          if (userId === params.qmId) title = "QM";
          if (userId in params.teams) title = params.teams[userId].teamName;
          addTitle(userId, title);
        });
        Array.from(currentIds).forEach((userId: string) => {
          if (peers[userId]) {
            peers[userId].close();
          }
        });
        setTeamData(params.teams);
        teamDataRef.current = params.teams;
        teamIds.current = params.teamIds;
        allIds.current = params.participantIds;
        qmId.current = params.qmId;
        addTitle(params.qmId, "QM");
      });

      socket.emit("GET_ROOM_DATA");

      socket.on("USER_MUTED", (params) => {
        const teamVideo: HTMLVideoElement | null = document.getElementById(
          `video-${params.quizzerId}`
        ) as HTMLVideoElement;
        if (!teamVideo) return;
        // mute/unmute this quizzer
        if (params.muted) {
          teamVideo.muted = true;
        } else {
          // Make sure we are in a state where this person could be unmuted.
          if (
            ["READY", "RULING"].includes(phase) ||
            (phase === "ANSWERING" && params.teamId === teamAnswering)
          )
            teamVideo.muted = false;
        }
      });
    });

    socket.on("READING_QUESTION", () => {
      setPhase("ASKING");
      startedReadingQuestion.current = Date.now();
      allIds.current.forEach((id) => {
        const video = document.getElementById(
          `video-${id}`
        ) as HTMLVideoElement;
        if (video) video.muted = id !== qmId.current;
      });
    });
    socket.on("QUIZZER_WON_JUMP", (params) => {
      setTeamAnswering(params.teamId);
      setSeatAnswering(params.seat);
      setPhase("ANSWERING");
      allIds.current.forEach((id) => {
        const video = document.getElementById(
          `video-${id}`
        ) as HTMLVideoElement;
        if (video) {
          if (id !== params.teamId) video.muted = true;
          if (id === params.teamId) video.muted = false;
        }
      });
    });
    socket.on("RULING_MADE", () => {
      setPhase("READY");

      allIds.current.forEach((id: string) => {
        if (
          (teamDataRef.current[id] || id === qmId.current) &&
          id !== myId.current
        ) {
          const teamVideo: HTMLVideoElement | null = document.getElementById(
            `video-${id}`
          ) as HTMLVideoElement;
          if (teamVideo) teamVideo.muted = false;
        }
      });
    });
    socket.on("USER_MUTED", (params) => {
      const userVideo: HTMLVideoElement | null = document.getElementById(
        `video-${params.userId}`
      ) as HTMLVideoElement;
      if (!userVideo) return;
      // mute/unmute this quizzer
      if (params.muted) {
        userVideo.muted = true;
      } else {
        // Make sure we are in a state where this person could be unmuted.
        if (
          ["READY", "RULING"].includes(phase) ||
          (phase === "ANSWERING" && params.userId === teamAnswering)
        )
          userVideo.muted = false;
      }
    });

    // Video streams and connections to users
    const connectToNewUser = (userId: string, stream: MediaStream) => {
      setTimeout(() => {
        const call = myPeer.call(userId, stream);

        const video = document.createElement("video");
        video.id = `video-${userId}`; // The PeerJs id of the person we're calling
        video.style.width = "max(25vw, 200px)";
        video.muted = ["ASKING", "ANSWERING", "JUMPED"].includes(phase);
        call.on("stream", (userVideoStream) => {
          addVideoStream(video, userVideoStream, userId);
        });
        call.on("close", () => {
          video.pause();
          video.remove();
        });

        peers[userId] = call;
      }, 500);
    };

    myPeer.on("open", (id: string) => {
      socket.emit("TEAM_CONNECT", {
        roomId: params.roomId,
        teamName: state.myName,
        userId: id,
        role,
      });
      myId.current = id;
    });
  }, []);

  if (!role) return <Navigate to={`/multiplayer/teams/${params.roomId}`} />;
  return (
    <div className="page">
      <p>{`Role: ${role}${
        role === "TEAM" ? `  ${state?.myName || ""}` : ""
      }`}</p>
      {role === "TEAM" && !acme.isReady && (
        <div>
          <button className="clickable" onClick={() => acme.init()}>
            Set up Pads
          </button>
        </div>
      )}
      {<Row style={{ marginTop: 10 }} id={videoContainer}></Row>}
      {role === "TEAM" && acme.isReady && phase === "READY" && (
        <div style={{ marginTop: 20 }}>
          <div>Waiting for the quizmaster to start reading a question</div>
          <Row>
            <span>Select team for pads:</span>
            {Array(4)
              .fill(undefined)
              .map((_, index) => (
                <button
                  key={index}
                  style={{
                    backgroundColor: acmeTeam === index ? "green" : "",
                    marginLeft: 2,
                  }}
                  onClick={() => setAcmeTeam(index)}
                >
                  {index + 1}
                </button>
              ))}
          </Row>
          <Check
            style={{ height: 30, fontSize: 14, width: 120 }}
            checked={reversePads}
            onClick={setReversePads}
          >
            Inverse Pads
          </Check>
          <Row style={{ marginTop: 4 }}>
            <span>Seats:</span>
            {padsOn.map((pad, index) => (
              <button
                key={index}
                style={{
                  backgroundColor:
                    pad && acme.lights[acmeTeam]?.[index] ? "green" : "",
                  fontSize: 14,
                }}
                onClick={() => {
                  const pads = [...padsOn];
                  pads[index] = !pads[index];
                  setPadsOn(pads);
                  padsOnRef.current = pads;
                }}
              >
                {pad ? "On" : "Off"}
              </button>
            ))}
          </Row>
        </div>
      )}
      {phase === "READY" && role === "QM" && (
        <div style={{ marginTop: 10 }}>
          <div>
            You can start reading the next question at any time. Make sure all
            the teams are ready.
          </div>
          <div style={{ marginTop: 10 }}>
            <button
              className="clickable"
              style={{ width: 250 }}
              onClick={() => {
                socketIO.current?.emit("START_READING_QUESTION", {});
              }}
            >
              Begin reading next question
            </button>
          </div>
        </div>
      )}
      {/* {phase === "READY" && (
        <div style={{ marginTop: 10 }}>
          <button
            className="link"
            onClick={() => {
              socketIO.current?.emit("TIMEOUT");
            }}
          >
            Call Timeout
          </button>
        </div>
      )} */}
      {phase === "READY" && (
        <div>
          <h5 style={{ marginBottom: 4 }}>Teams in the quiz:</h5>
          {teamIds.current.map((teamId: string) => {
            const team = teamData[teamId];
            return <div key={team.teamId}>{team.teamName}</div>;
          })}
        </div>
      )}
      {phase === "ANSWERING" && (
        <div>
          <p
            style={{ fontSize: 36 }}
          >{`${teamData[teamAnswering].teamName} ${seatAnswering} is answering.`}</p>
          {role === "QM" && (
            <div>
              <button
                className="clickable"
                onClick={() => {
                  socketIO.current?.emit("MAKE_RULING");
                }}
              >
                Make Ruling
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
