import { CacheLocations, EnumDescription } from "./generalTypes";

// Stored with all other seasons
export interface SeasonSummary {
  ownerId?: string;
  seasonId: string;
  seasonName: string;
  isPractice?: boolean; // default false
  location: CacheLocations;
  lastUpdated: string;
}

export interface Season extends SeasonSummary {
  teams: Team[];
  events: Event[];
  quizmasters?: string[];
  importedTeams?: {
    setId: string;
    setName?: string;
    lastUpdated: string;
    account?: string;
  };
}

export interface Team {
  teamId: string;
  teamName: string;
  coach?: string;
  color?: string; // hex or rgb; anything that can be put into a react element or css
  nickname?: string;
  quizzers: Quizzer[];
  active: boolean;
  presetLineups?: Lineup[];
  captian?: number; // captain
}
export interface Lineup {
  lineupName: string;
  quizzerIdList: string[];
  captain?: number;
}

export interface Quizzer {
  quizzerName: string;
  quizzerId: string;
  active: boolean;
}

export interface Event {
  eventId: string;
  eventName: string;
  quizzes: QuizSummary[]; // The ids and metadata that point to the quizzes
}

export interface QuizSummary {
  quizId: string;
  seasonId: string;
  eventId: string;
  quizName: string;
  round: number;
  site: number;
  teams: Team[];
  quizmaster?: string;
  isPractice?: boolean;
  createdTimeStamp: string;
  lastUpdated: string;
}

export interface WatchableQuiz {
  quizId: string;
  quizName: string;
  round?: number;
  site?: number;
}
export interface Quiz extends QuizSummary {
  moments: Moment[]; // Sorted
  qmRating?: QMRating;

  sharedWith?: string[];
  watchability?: "PUBLIC" | "PRIVATE";
  location?: CacheLocations;
}

export type QMRating = Partial<Record<QMRatingTypes, number>>;
export type QMRatingTypes =
  | "QUESTION"
  | "READING"
  | "ATTITUDE"
  | "MANAGEMENT"
  | "APPLICATION";
export const qmRatings: EnumDescription<QMRatingTypes> = {
  QUESTION: "Rules and Grammar (sentence structure with question formulation)",
  READING: "Reading and Stopping (consistency, rhythm)",
  ATTITUDE:
    "Attitude (was the QM open to being challenged, did they thoughtfully listen)",
  MANAGEMENT: "Management (how many questions per quiz, time management)",
  APPLICATION: "Application (decision making, consistency, & rule following).",
};

export const firstHalf: Readonly<QuizStatusType> = "FIRST_HALF";
export const secondHalf: Readonly<QuizStatusType> = "SECOND_HALF";
export const overtime: Readonly<QuizStatusType> = "OVERTIME";
export const ended: Readonly<QuizStatusType> = "ENDED";
export type QuizStatusType =
  | "FIRST_HALF"
  | "SECOND_HALF"
  | "OVERTIME"
  | "ENDED";
export const QuizStatusDescription: Readonly<Record<QuizStatusType, string>> = {
  FIRST_HALF: "First Half",
  SECOND_HALF: "Second Half",
  OVERTIME: "Overtime",
  ENDED: "Quiz Ended",
};

// Summary of a quiz.  Keep in mind this means nothing unless we know who the teams and quizzers are who are in the quiz
export interface QuizzerBoxScore {
  quizzerId: string;
  quizzerName: string;
  points: number;
  errors: number;
  foul: boolean;
  eligable: boolean;
}
export interface TeamBoxScore {
  teamId: string;
  teamName: string;
  points: number;
  errors: number;
  quizzersAnswering: number;
  quizzers: QuizzerBoxScore[];
  eligable: boolean;
  coachesTimeoutAvailable: boolean;
  captain?: number; // index in quizzers
}
export interface BoxScore {
  quizStatus: QuizStatusType;
  teams: TeamBoxScore[];
  finalPlacements: number[]; // [teamIndex] the place, from 1 to n, that they got in this quiz.  0 means not assigned yet
  totalQuestions: number;
  isPractice: boolean;
}

// Settings for replaying a quiz. Primarily used by the UI
export interface ReplayQuizSettings {
  teams: ReplayQuizTeam[];
  selectedQuestion: number;
}
export interface ReplayQuizTeam {
  correctAnswers: number;
  errors: number;
  setJumps: boolean;
}

export interface RunningScoreItem {
  scores: (number | undefined)[];
  startOfPeriod?: boolean;
  team?: number;
  coachesTimeout?: number; // the team index
  quizErrorOut?: boolean;
}
export interface WinProbability {
  firstProb: number;
  totalProb: number;
}

export const tp: Readonly<QuestionMomentType> = "TWENTY_POINTS";
export const error: Readonly<QuestionMomentType> = "ERROR";
export const foul: Readonly<MomentType> = "FOUL";
export const ct: Readonly<MomentType> = "COACHES_TIMEOUT";
export const tf: Readonly<MomentType> = "TECHNICAL_FOUL";
export const halftime: Readonly<MomentType> = "HALFTIME";
export const startOvertime: Readonly<MomentType> = "START_OVERTIME";
export const throwout: Readonly<MomentType> = "THROWOUT";
export const noQuestion: Readonly<MomentType> = "NO_QUESTION";
export const quizPause: Readonly<MomentType> = "PAUSE";
export const sub: Readonly<MomentType> = "SUB";
export const endQuiz: Readonly<MomentType> = "END_QUIZ";
export const absent: Readonly<MomentType> = "ABSENT";
export type QuestionMomentType = "TWENTY_POINTS" | "ERROR";
export type MomentType =
  | QuestionMomentType
  | "FOUL"
  | "COACHES_TIMEOUT"
  | "TECHNICAL_FOUL"
  | "HALFTIME"
  | "START_OVERTIME"
  | "THROWOUT"
  | "NO_QUESTION"
  | "PAUSE"
  | "SUB"
  | "END_QUIZ"
  | "ABSENT";
export const momentTypeDescriptions: Readonly<Record<MomentType, string>> = {
  TWENTY_POINTS: "20 Points",
  ERROR: "Error",
  FOUL: "Foul",
  COACHES_TIMEOUT: "Coach's Timeout",
  TECHNICAL_FOUL: "Technical Foul",
  HALFTIME: "Halftime",
  START_OVERTIME: "Overtime",
  THROWOUT: "Throwout",
  PAUSE: "Quiz Pause",
  SUB: "Substitution",
  END_QUIZ: "End Quiz",
  ABSENT: "Absent",
  NO_QUESTION: "No Question",
};

// Error Types
export const light: Readonly<ErrorType> = "L";
export const knowledge: Readonly<ErrorType> = "K";
export const ref: Readonly<ErrorType> = "REF";
export const split: Readonly<ErrorType> = "SR";
export const fifty: Readonly<ErrorType> = "FF";
export type ErrorType = "L" | "K" | "REF" | "SR" | "FF";
export const errorDescription: Readonly<Record<ErrorType, string>> = {
  L: "Light Error",
  SR: "Split Ref. Error",
  REF: "Reference Error",
  FF: "50/50 Error",
  K: "K-Error",
};
export const errorTypeButtonText: Readonly<Record<ErrorType, string>> = {
  L: "Light",
  SR: "Split",
  REF: "Ref.",
  FF: "50/50",
  K: "Knowledge",
};
export type QuestionType = MomentType | ErrorType;
export const momentTypeColor: Readonly<Partial<Record<QuestionType, string>>> =
  {
    L: "lightgrey",
    K: "red",
    REF: "yellow",
    SR: "pink",
    FF: "blue",
    ERROR: "red",
    TWENTY_POINTS: "green",
  };
export type KErrorType =
  | "WRONG_VERSE"
  | "WRONG_WORD"
  | "TIME"
  | "QUESTION"
  | "BAD_CALL";
export const wrongVerse: KErrorType = "WRONG_VERSE";
export const wrongWord: KErrorType = "WRONG_WORD";
export const outOfTime: KErrorType = "TIME";
export const invalidQuestion: KErrorType = "QUESTION";
export const badCall: KErrorType = "BAD_CALL";
export const kErrorDescription: Readonly<Record<KErrorType, string>> = {
  WRONG_VERSE: "Wrong Verse",
  WRONG_WORD: "Wrong Word",
  QUESTION: "Invalid Question",
  TIME: "Time",
  BAD_CALL: "Bad Call",
};
export const kErrorTypeColors: Readonly<Record<KErrorType, string>> = {
  WRONG_VERSE: "red",
  WRONG_WORD: "orange",
  QUESTION: "blue",
  TIME: "yellow",
  BAD_CALL: "green",
};
export const kErrorTypeButtonText: Readonly<Record<KErrorType, string>> = {
  WRONG_VERSE: "Verse",
  WRONG_WORD: "Word",
  QUESTION: "Question",
  TIME: "Time",
  BAD_CALL: "Bad Call",
};

export const splitRef: Readonly<JumpType> = "SPLIT_REF";
export const reference: Readonly<JumpType> = "REFERENCE";
export const read: Readonly<JumpType> = "READ";
export const oneWord: Readonly<JumpType> = "ONE_WORD";
export const multipleWords: Readonly<JumpType> = "MULTIPLE";
export type JumpType =
  | "SPLIT_REF"
  | "REFERENCE"
  | "READ"
  | "ONE_WORD"
  | "MULTIPLE";
export const jumpTypeDescription: Readonly<Record<JumpType, string>> = {
  SPLIT_REF: "Split Reference",
  REFERENCE: "Reference",
  READ: "Read",
  ONE_WORD: "One Word",
  MULTIPLE: "2+ Words",
};
export const jumpTypeColor: Readonly<Partial<Record<JumpType, string>>> = {
  SPLIT_REF: "white",
  REFERENCE: "red",
  READ: "orange",
  ONE_WORD: "green",
  MULTIPLE: "darkgreen",
};

export interface Moment {
  team?: number; // If this wasn't halftime or something, this points to the team array, so it is important that array doesn't change order
  quizzerId?: string; // The quizzer who won the jump
  type: MomentType;
  book?: number;
  chapter?: number;
  verse?: number;
  read?: boolean; // Legacy variable for if the quizzer got a read.
  question?: string;
  receivedCharacters?: number; // A substring of question - the words the quizzer got.  If not present, the quizzer got everything in question.
  quote?: boolean;
  errorType?: ErrorType; // Only applied if type === error
  kErrorType?: KErrorType; // Only applied if errorType === knowledge
  onSeats?: string[]; // The ids of the quizzers who were on the seats [quizzerId, though the order of this array has little meaning]
  onBench?: string[]; // ids of quizzers on bench. This is the new version; the above variable is for legacy purposes
}

// Used for importing teams
export interface TeamSet {
  setName: string;
  setId: string;
  lastUpdated: string;
  teams: Team[];
  quizmasters: string[];
  changeLog?: string[];
  account?: string; // link to quizzing.live
  isActive: boolean;
}
export interface QuizSet {
  setId: string; // The team set you must have downloaded
  eventName: string;
  quizzes: ImportableEventQuiz[];
  notes?: string;
}
export interface ImportableEventQuiz {
  site: number;
  round: number;
  teams: string[]; // Team ID
  quizmaster?: string;
}

// Stats
export interface StatsSettings {
  // Only include certain quizzes
  quizIds: Set<string>;

  // Filter by teams in quiz
  teamIds: Set<string>;
  minTeams: number;

  // Filter by quizzers on the chairs
  quizzerIds: Set<string>;
  minQuizzers: number;

  // Only include certain chapters
  chapters: Set<string>; // For John 3, we would use "3-2", that is, book index then chapter index

  // Only include certain quizmasters
  quizmasters: Set<string>;

  // Only include certain jump types
  jumpTypes: Set<JumpType>;

  // Misc. Settings
  includeOvertime: boolean;
  quoteOnly: boolean;
  afterPause: boolean;
}

export interface MomentInQuiz extends Moment {
  eventName: string;
  eventId: string;
  round?: number;
  site?: number;
  quizName: string;
  quizId: string;
  qIndex: number;
}
export interface ByQuizStats {
  quizId: string;
  eventId: string;
  quizName: string;
  eventName: string;
  round?: number;
  site?: number;
  correct: number;
  errors: number;
}

export interface StatsLuck {
  expectedFromJumps: number;
  expectedFromQuestions: number;
  actual: number;
  choicesCorrect: number;
}
export interface QuizzerStats {
  quizzerName: string;
  quizzerId: string;
  teamId: string;
  teamName: string;
  quizzes: Set<string>; // quizId
  points: number;
  correct: number;
  errors: number;
  luck: StatsLuck;
  questionsByType: Partial<Record<QuestionType, number>>;
  jumpsByType: Partial<Record<JumpType, number>>;
  knowledgeErrorsByType: Partial<Record<KErrorType, number>>;
  questionsOnSeat: number;
  seatTimeEligible: number;
  questions: MomentInQuiz[];
  otherMoments: MomentInQuiz[];
  byQuiz: Record<string, ByQuizStats>;
}

export interface TeamStats {
  teamName: string;
  teamId: string;
  quizzes: Set<string>;
  points: number;
  correct: number;
  errors: number;
  luck: StatsLuck;
  questionsByType: Partial<Record<QuestionType, number>>;
  jumpsByType: Partial<Record<JumpType, number>>;
  knowledgeErrorsByType: Partial<Record<KErrorType, number>>;
  record: number[];
  questions: MomentInQuiz[];
  otherMoments: MomentInQuiz[];
  pointsLostToErrors: number;
}

export interface ChapterStats {
  book: number;
  chapter: number;
  questionsByType: Partial<Record<QuestionType, number>>;
  jumpsByType: Partial<Record<JumpType, number>>;
  questions: MomentInQuiz[];
  luck: {
    expected: number;
    actual: number;
  };
}

export interface QuizzerMaterial {
  books: Record<number, Set<number>>; // book, chapters
}
export type MaterialKnown = Record<string, QuizzerMaterial>;

export type DetailedStatsOptions =
  | "BY_CHAPTER"
  | "ALL_JUMPS"
  | "K_ERRORS"
  | "BY_QUIZ"
  | "NONE";

export type SKHotkey =
  | "quote"
  | "pbp"
  | "showTeam"
  | "runningScore"
  | "winProbabilities"
  | "throwout"
  | "halftime"
  | "noQuestion"
  | "overtime"
  | "assign"
  | "goUp"
  | "goDown"
  | "goRight"
  | "goLeft"
  | "goBack"
  | "colon"
  | "clearAll"
  | "toggleSelection"
  | "undo"
  | "reverseTeams"
  | "num0"
  | "num1"
  | "num2"
  | "num3"
  | "num4"
  | "num5"
  | "num6"
  | "num7"
  | "num8"
  | "num9";
