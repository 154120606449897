export type VoteType =
  | "NONE"
  | "TWENTY_POINTS"
  | "ERROR"
  | "THROWOUT"
  | "NO_QUESTION";
export const voteTypeDescriptions: Record<VoteType, string> = {
  NONE: "Not Voted",
  TWENTY_POINTS: "Twenty Points",
  ERROR: "Error",
  THROWOUT: "Throwout",
  NO_QUESTION: "No Question",
};
