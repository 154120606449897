import { useSelector } from "react-redux";
import { RootStore } from "../../../state/store";
import { Line } from "react-chartjs-2";
import { Row } from "../../../components/Row";
import * as skTypes from "../../../types/sk";
import * as utils from "../../../util/skUtils";
import { getChartLabelOptions } from "../../../util/Chart";

interface Props {
  moments: skTypes.Moment[];
  teams: skTypes.Team[];
  onClose: () => void;
}

export function RunningScore(props: Props) {
  const lightMode = useSelector((state: RootStore) => state.settings.lightMode);
  const scoresByMoment: skTypes.RunningScoreItem[] = [
    { scores: props.teams.map(() => 0) },
    ...utils.getRunningScore(props.teams, props.moments),
  ];

  return (
    <div className="page" style={{ padding: 20, minWidth: 750 }}>
      <Row style={{ marginBottom: 10 }}>
        <button className="back-button" onClick={props.onClose}>
          Go Back
        </button>
        <div style={{ marginTop: 5, marginLeft: 10 }}>
          <span>
            {lightMode ? "Black" : "White"} Circle: Quiz Out or Error
            Out.&nbsp;&nbsp;&nbsp;Gray Circle: Coach's
            Timeout.&nbsp;&nbsp;&nbsp;Vertical Line: Halftime or Overtime.
          </span>
        </div>
      </Row>
      <div className="separator-line" />
      <div
        style={{
          width: "max(min(calc((100vh - 120px) * 2), 100vw - 100px), 700px",
        }}
      >
        <Line
          key="Running Score"
          options={{
            maintainAspectRatio: true,
            responsive: true,
            elements: {
              point: {
                radius: (element: any) => {
                  const x = element.parsed.x;
                  const team = utils.teamSideColors.indexOf(
                    element?.element?.options?.backgroundColor
                  );
                  let resultRadius: number = 3;
                  const moment = scoresByMoment[x];
                  if (moment.team === team) resultRadius += 2;
                  if (moment.coachesTimeout === team) resultRadius += 2;
                  return resultRadius;
                },
              },
            },
            animation: { duration: 0 }, // general animation time
            // @ts-ignore
            hover: { display: false, animationDuration: 0 }, // No animation
            responsiveAnimationDuration: 0,
            plugins: {
              legend: {
                display: true,
                position: "top",
                labels: getChartLabelOptions(lightMode),
              },
            },
            tooltips: {
              enabled: false,
            },
            scales: {
              x: {
                ticks: {
                  // @ts-ignore
                  callback: (x: number) => {
                    return scoresByMoment[x].startOfPeriod ? " " : null;
                  },
                },
                grid: {
                  display: true,
                  drawOnChartArea: true,
                  color: "#666666",
                  // @ts-ignore
                  innerWidth: 0.5,
                },
              },
              xAxes: {
                display: false,
              },
              y: {
                grid: {
                  color: "#666666",
                  // @ts-ignore
                  innerWidth: 0.5,
                },
              },
            },
          }}
          data={{
            labels: scoresByMoment.map(
              // Moment index
              (scoreMoment: skTypes.RunningScoreItem, index: number) =>
                `${index}`
            ),
            datasets: props.teams.map((team: skTypes.Team, index: number) => {
              return {
                data: scoresByMoment.map(
                  (scoreMoment: skTypes.RunningScoreItem) =>
                    scoreMoment.scores[index]
                ),
                label: team.teamName,
                // colors for coaches timeouts, and also quiz/error out
                borderColor: scoresByMoment.map(
                  (scoreMoment: skTypes.RunningScoreItem) => {
                    if (scoreMoment.coachesTimeout === index) return "grey";
                    return scoreMoment.quizErrorOut &&
                      index === scoreMoment.team
                      ? lightMode
                        ? "black"
                        : "white"
                      : utils.teamSideColors[index];
                  }
                ),
                borderWidth: 3,
                // quiz outs and error souts
                backgroundColor: scoresByMoment.map(
                  (scoreMoment: skTypes.RunningScoreItem) => {
                    return scoreMoment.quizErrorOut &&
                      index === scoreMoment.team
                      ? lightMode
                        ? "black"
                        : "white"
                      : utils.teamSideColors[index];
                  }
                ),
              };
            }),
          }}
        />
      </div>
    </div>
  );
}
