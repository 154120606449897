import React, { CSSProperties } from "react";
import "./Check.scss";

interface CheckProps {
  checked: boolean | undefined;
  outline?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick: (
    checked: boolean,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  children: React.ReactNode;
  id?: string;
}

export function Check(props: CheckProps) {
  return (
    <button
      className={`check ${props.checked ? "checked" : "unchecked"} ${
        props.outline ? "outline" : ""
      } no-wrap-text ${props.className}`}
      style={props.style}
      onClick={(e) => {
        props.onClick(!props.checked, e);
      }}
      id={props.id}
    >
      {props.children}
    </button>
  );
}
