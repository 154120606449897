import { EffectCallback, useEffect, useRef } from "react";

/**
 * a useEffect, but doesn't run when the hook initially mounts
 * @param func
 * @param deps
 */
export const useEffectAfterMount = (func: EffectCallback, deps: any[]) => {
  const mounted = useRef<boolean>(false);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    func();
  }, deps);
};
