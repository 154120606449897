import React, { CSSProperties, useState } from "react";
import { MenuItem } from "@mui/material";
import Menu from "@mui/material/Menu";
import "./DropdownMenu.scss";

interface DropdownMenuProps {
  items: {
    title: React.ReactNode;
    key?: string; // default to title
    onClick: () => void;
    visible?: boolean; // default true
  }[];
  children: React.ReactNode; // can be just text if needed
  style?: CSSProperties;
  className?: string;
  id: string;
  menuEnabled?: boolean;
}

export function DropdownMenu(props: DropdownMenuProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<any>();

  return (
    <>
      <button
        className={props.className}
        style={{
          backgroundColor: "transparent",
          fontSize: "unset",
          padding: 0,
          outline: "none",
          border: "none",
          margin: 0,
          ...props.style,
        }}
        onClick={(e) => {
          if (props.menuEnabled === false) return;
          setOpen(true);
          setAnchorEl(e.currentTarget);
          e.currentTarget.blur();
        }}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-controls={open ? props.id : undefined}
      >
        {props.children}
      </button>
      <Menu
        elevation={0}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setOpen(false)}
        onClick={() => {
          setOpen(false);
          if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur();
        }}
        MenuListProps={{
          "aria-labelledby": props.id,
        }}
        PaperProps={{
          className: "dropdown-paper",
        }}
        id={props.id}
      >
        {props.items.map((item) => {
          if (item.visible === false) return null;
          return (
            <MenuItem
              key={item.key || String(item.title)}
              onClick={item.onClick}
              disableRipple
              className="dropdown-menu-item"
            >
              {item.title}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
