import React, { useState } from "react";
import { Row } from "../Row";
import { combineBooleansObject, xorObject } from "../../util/generalUtil";
import { PlusMinusButton } from "../PlusMinusButton";
import { Check } from "../Check/Check";
import "./settings.scss";
import "../SKComponents/AdditionalSKStyles.scss";

export type Tree = Record<
  string,
  {
    title: string;
    children: Record<
      string,
      {
        title: string;
        checked: boolean;
      }
    >;
  }
>;

interface Props {
  data: Tree;
  height: number | string;
  onChange: (data: Tree) => void;
  checkWholeTeam?: boolean;
  autoOpen?: Set<string>;
}

export function CheckTree(props: Props) {
  const getDefaultExpanded = (): Record<string, boolean> => {
    const treeKeys = Object.keys(props.data);
    const result: Record<string, boolean> = {};
    if (treeKeys.length === 1)
      return {
        [treeKeys[0]]: true,
      };
    treeKeys.forEach((key: string) => {
      result[key] = props.autoOpen !== undefined && props.autoOpen.has(key);
    });
    return result;
  };
  const [expanded, setExpanded] = useState<Record<string, boolean>>(
    getDefaultExpanded()
  );

  return (
    <div className="settings-scrolling stats-settings-scrolling">
      {Object.keys(props.data).map((key: string) => {
        const item = props.data[key];
        return (
          <React.Fragment key={key}>
            <Row>
              <PlusMinusButton
                style={{ marginTop: 8 }}
                onClick={() => {
                  setExpanded({
                    ...expanded,
                    [key]: expanded[key] ? false : true,
                  });
                }}
              >
                {expanded[key] ? "-" : "+"}
              </PlusMinusButton>
              <Check
                checked={combineBooleansObject(
                  item.children,
                  (item) => item.checked
                )}
                outline={xorObject(item.children, (item) => item.checked)}
                onClick={(checked: boolean) => {
                  if (props.checkWholeTeam === false) {
                    // We can't check the whole team, so just expand/collapse instead
                    setExpanded({
                      ...expanded,
                      [key]: expanded[key] ? false : true,
                    });
                    return;
                  }
                  const newData = {
                    ...props.data,
                    [key]: item,
                  };
                  Object.keys(newData[key].children).forEach(
                    (childKey: string) => {
                      newData[key].children[childKey].checked = checked;
                    }
                  );
                  props.onChange(newData);
                }}
                style={{
                  fontSize: 20,
                  paddingTop: 5,
                  width: "calc(100% - 60px)",
                  marginLeft: 5,
                }}
              >
                {item.title}
              </Check>
            </Row>
            {expanded[key] && (
              <div style={{ marginLeft: 20 }}>
                {Object.keys(item.children).map((childKey: string) => {
                  const childItem = item.children[childKey];
                  return (
                    <div key={childKey}>
                      <Check
                        checked={childItem.checked}
                        onClick={(checked) => {
                          const newData = {
                            ...props.data,
                            [key]: item,
                          };
                          newData[key].children[childKey].checked = checked;
                          props.onChange(newData);
                        }}
                      >
                        {childItem.title}
                      </Check>
                    </div>
                  );
                })}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
