import { useEffect } from "react";

/**
 * Sets the document title to the value you provide as long as this hook as active
 * @param title the document title for the page
 * @param revertOnUnmount whether the title should revert to the original when the component unmounts. Default true.
 */
export function useDocumentTitle(
  title: string,
  revertOnUnmount: boolean = true
) {
  useEffect(() => {
    document.title = title;
    if (revertOnUnmount)
      return () => {
        document.title = "Bible Quiz Academy";
      };
  }, [title]);
}
