import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../../state/store";
import { Line } from "react-chartjs-2";
import { Check } from "../../../components/Check/Check";
import { Row } from "../../../components/Row";
import * as skTypes from "../../../types/sk";
import * as utils from "../../../util/skUtils";
import * as statsUtils from "../../../util/skStatsUtils";
import { getChartLabelOptions } from "../../../util/Chart";

interface Props {
  moments: skTypes.Moment[];
  teams: skTypes.Team[];
  onClose: () => void;
}

export function WinProbabilities(props: Props) {
  const lightMode = useSelector((state: RootStore) => state.settings.lightMode);
  const [probabilities, setProbabilities] = useState<
    skTypes.WinProbability[][]
  >([]);

  useEffect(() => {
    setProbabilities(
      statsUtils.getAllWinProbabilities(props.moments, props.teams)
    );
  }, []);

  const scoresByMoment: skTypes.RunningScoreItem[] = [
    { scores: props.teams.map(() => 0) },
    ...utils.getRunningScore(props.teams, props.moments),
  ];

  const [onlyFirst, setOnlyFirst] = useState<boolean>(false);

  return (
    <div className="page" style={{ padding: 20, minWidth: 750 }}>
      <Row style={{ marginBottom: 10 }}>
        <button className="back-button" onClick={props.onClose}>
          Go Back
        </button>
        <Check
          checked={onlyFirst}
          onClick={setOnlyFirst}
          style={{ width: 200, marginTop: 0, marginLeft: 10 }}
        >
          Show Only First Place
        </Check>
      </Row>
      <hr className="separator-line" />
      <div
        style={{
          width: "max(min(calc((100vh - 120px) * 2), 100vw - 100px), 700px",
        }}
      >
        <Line
          key="Win Probability Graph"
          options={{
            maintainAspectRatio: true,
            responsive: true,
            elements: { point: { radius: 3 } },
            animation: { duration: 0 }, // general animation time
            // @ts-ignore
            hover: { display: false, animationDuration: 0 }, // No animation
            responsiveAnimationDuration: 0,
            plugins: {
              legend: {
                display: true,
                position: "top",
                labels: getChartLabelOptions(lightMode),
              },
            },
            tooltips: {
              enabled: false,
            },
            scales: {
              x: {
                ticks: {
                  // @ts-ignore
                  callback: (x: number) => {
                    if (x === 0) return null;
                    return scoresByMoment[x].startOfPeriod ? " " : null;
                  },
                },
                grid: {
                  display: true,
                  drawOnChartArea: true,
                  color: "#666666",
                  // @ts-ignore
                  innerWidth: 0.5,
                },
              },
              xAxes: {
                display: false,
              },
              y: {
                grid: {
                  color: "#666666",
                  // @ts-ignore
                  innerWidth: 0.5,
                },
                beginAtZero: true,
                max: onlyFirst ? 1 : 2,
              },
            },
          }}
          data={{
            labels: probabilities.map(
              (scoreMoment: skTypes.WinProbability[], index: number) =>
                `${index}`
            ),
            datasets: props.teams.map((team: skTypes.Team, index: number) => {
              return {
                data: probabilities.map(
                  (scoreMoment: skTypes.WinProbability[]) =>
                    onlyFirst
                      ? scoreMoment[index].firstProb
                      : scoreMoment[index].totalProb
                ),
                label: team.teamName,
                borderColor: utils.teamSideColors[index],
                backgroundColor: utils.teamSideColors[index],
              };
            }),
          }}
        />
      </div>
    </div>
  );
}
