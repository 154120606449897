import React, { CSSProperties, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { TournamentLink } from "./TournamentLink";
import { tournamentSpecialChars } from "../../util/tournamentUtils";

interface Props {
  text: string;
  noMarkdown?: boolean;
}

export function TournamentText(props: Props) {
  const paragraphs = useMemo<React.ReactElement[]>(() => {
    // Case for the no markdown version
    if (props.noMarkdown)
      return props.text.split("\n\n").map((item) => <p key={uuid()}>{item}</p>);

    const result: React.ReactElement[] = [];
    let currentParagraph: React.ReactElement[] = [];
    let currentThing: "TEXT" | "LINK_ID" | "LINK_SECID" | "LINK_TEXT" = "TEXT";
    let activeItem: string[] = []; // pieces of the current element being added
    let isBold = false;
    let isItalics = false;
    let isCode = false;

    /**
     * adds a piece of text to a paragraph, in a single span
     */
    const addTextToParagraph = () => {
      const style: CSSProperties = {};
      if (isBold) {
        style.fontWeight = "bold";
      }
      if (isItalics) {
        style.fontStyle = "italic";
      }
      if (activeItem[0] && activeItem[0].length > 0) {
        const params = { key: uuid(), style, children: activeItem[0] };
        if (isCode) {
          currentParagraph.push(<code {...params} />);
        } else {
          currentParagraph.push(<span {...params} />);
        }
      }
      activeItem = [];
    };

    /**
     * Finishes the paragraph and begins a new one
     */
    const completeParagraph = () => {
      if (currentParagraph.length > 0) {
        const isOnlyTitle =
          currentParagraph.length === 1 &&
          currentParagraph[0].props.style?.fontWeight === "bold";
        if (isOnlyTitle) currentParagraph[0].props.style.fontSize = 18;
        result.push(
          <p key={uuid()} style={isOnlyTitle ? { marginTop: 16 } : undefined}>
            {[...currentParagraph]}
          </p>
        );
        currentParagraph = [];
      }
    };

    for (let i = 0; i < props.text.length; i++) {
      const thisChar = props.text.charAt(i);
      const nextChar = props.text.charAt(i + 1) || "";

      if (
        tournamentSpecialChars.has(thisChar) &&
        (!isCode || thisChar === "`") &&
        (!["LINK_ID", "LINK_SECID"].includes(currentThing) ||
          ["[", "]"].includes(thisChar))
      ) {
        let isSkipping = false;
        if (thisChar === nextChar && thisChar !== "\\") {
          // Something special happens
          switch (thisChar) {
            case "\n":
              addTextToParagraph();
              completeParagraph();
              currentThing = "TEXT";
              break;
            case "[":
              if (activeItem[0]) {
                // start link
                if (activeItem[0].length > 0) {
                  currentParagraph.push(
                    <span key={uuid()}>{activeItem[0]}</span>
                  );
                  activeItem = [];
                }
              }
              currentThing = "LINK_ID";
              break;
            case "]":
              if (activeItem[0]) {
                // End link
                currentParagraph.push(
                  <TournamentLink
                    key={uuid()}
                    pageId={activeItem[0]}
                    secondaryId={
                      currentThing === "LINK_SECID" || activeItem.length > 1
                        ? activeItem[1]
                        : undefined
                    }
                    text={
                      currentThing === "LINK_TEXT"
                        ? activeItem[activeItem.length - 1]
                        : undefined
                    }
                  />
                );
                currentThing = "TEXT";
                activeItem = [];
              }
              break;
            case "'":
              addTextToParagraph();
              isBold = !isBold;
              break;
            case "*":
              addTextToParagraph();
              isItalics = !isItalics;
              break;
            case "`":
              addTextToParagraph();
              isCode = !isCode;
              break;
          }
          isSkipping = true;
        } else {
          switch (thisChar) {
            case "\\":
              // Escape; apply the character, then skip to what comes after
              if (nextChar) {
                activeItem[activeItem.length - 1] += nextChar;
              }
              isSkipping = true;
              break;
          }
        }
        if (isSkipping) {
          i += 1;
          continue;
        }
      }

      if (
        thisChar === "|" &&
        (currentThing === "LINK_ID" || currentThing === "LINK_SECID")
      ) {
        if (currentThing === "LINK_ID") activeItem.push("");
        activeItem.push("");
        currentThing = "LINK_TEXT";
        continue;
      }
      if (thisChar === "/" && currentThing === "LINK_ID") {
        currentThing = "LINK_SECID";
        activeItem.push("");
        continue;
      }

      if (activeItem.length === 0) activeItem.push("");
      activeItem[activeItem.length - 1] += thisChar;
    }

    if (currentThing === "TEXT") {
      // Finish final item of paragraph
      addTextToParagraph();
    }
    if (currentParagraph.length > 0) {
      // Finish final paragraph
      completeParagraph();
    }
    return result;
  }, [props.text, props.noMarkdown]);

  return <>{paragraphs}</>;
}
