import React from "react";

export function Row(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      style={{
        display: "flex",
        flexWrap: "wrap",
        rowGap: 10,
        ...(props.style || {}),
      }}
    >
      {props.children}
    </div>
  );
}
