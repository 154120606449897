import React, { useEffect } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootStore } from "../state/store";
import { CircularProgress } from "@mui/material";
import { useEffectAfterMount } from "./useEffectAfterMount";
import { equalsIgnoreCase } from "../util/generalUtil";

interface Props<E> {
  id: string;
  getId: (record: E) => string;
  getData: (id: string) => Promise<E | undefined>;
  dispatchAction: (record: E | undefined) => AnyAction;
  selector: (state: RootStore) => E | undefined;
  render?: (record: E) => React.ReactElement;
  fallbackUrl?: string;
  cacheDetails?: {
    prefix: string;
    save: (data: E) => void;
    get: (id: string) => E | undefined;
  };
  onUseFallback?: () => void;
  disableFallback?: boolean; // The url won't be used, and we won't go back to the previous page
}
interface DataProviderReturn<E> {
  record: E | undefined;
  getPage: () => React.ReactElement;
}
export function useDataProvider<E>(props: Props<E>): DataProviderReturn<E> {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const record = useSelector(props.selector);
  const { user } = useSelector((state: RootStore) => state.authentication);
  const usingRecord = record
    ? equalsIgnoreCase(props.id, props.getId(record))
      ? record
      : undefined
    : undefined;

  const takeFallback = (forceFallback: boolean) => {
    if (!props.fallbackUrl && !forceFallback) return;
    if (props.onUseFallback && forceFallback) props.onUseFallback();
    if (props.disableFallback) return;
    if (props.fallbackUrl) {
      navigate(props.fallbackUrl, { replace: true });
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    const getRecord = async () => {
      let data: E | undefined = undefined;
      if (!user && props.cacheDetails) {
        data = props.cacheDetails.get(props.id);
      } else {
        data = await props.getData(props.id);
      }
      if (props.cacheDetails) {
        if (data) {
          props.cacheDetails.save(data);
        } else {
          data = props.cacheDetails.get(props.id) as Awaited<E>;
        }
      }
      dispatch(props.dispatchAction(data));
      if (!data) takeFallback(true);
    };
    if (props.id && (!record || props.getId(record) !== props.id)) getRecord();
  }, [props.id]);

  useEffectAfterMount(() => {
    if (!record) {
      takeFallback(false);
    }
  }, [record]);
  useEffect(() => {
    if (props.cacheDetails && record) {
      props.cacheDetails.save(record);
    }
  }, [record]);

  return {
    record: usingRecord,
    getPage: () => {
      if (!props.render) return <></>;
      if (usingRecord) return props.render(usingRecord);
      else
        return (
          <div style={{ minHeight: "100vh", textAlign: "center" }}>
            <CircularProgress style={{ width: 60, margin: 20 }} />
          </div>
        );
    },
  };
}
