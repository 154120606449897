import React from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../../state/store";
import { Doughnut } from "react-chartjs-2";
import { Row } from "../../../components/Row";
import * as skTypes from "../../../types/sk";
import { getDoughnutOptions } from "../../../util/Chart";

interface QuestionsJumpsGraphsProps {
  questionsByType: Partial<Record<skTypes.QuestionType, number>>;
  jumpsByType: Partial<Record<skTypes.JumpType, number>>;
  knowledgeErrors: Partial<Record<skTypes.KErrorType, number>>;
  additionalRowContent?: React.ReactElement | React.ReactElement[] | null;
}
export function QuestionsJumpsGraphs(props: QuestionsJumpsGraphsProps) {
  const lightMode = useSelector((state: RootStore) => state.settings.lightMode);
  const questionsByTypeKeys = Object.keys(
    props.questionsByType
  ) as skTypes.QuestionType[];
  const jumpsByTypeKeys = Object.keys(props.jumpsByType) as skTypes.JumpType[];
  const knowledgeErrorKeys = Object.keys(
    props.knowledgeErrors
  ) as skTypes.KErrorType[];
  const { skDisableGraphs } = useSelector((state: RootStore) => state.settings);

  const getTotalQuestions = (): number => {
    let result: number = 0;
    (Object.keys(props.questionsByType) as skTypes.QuestionType[]).forEach(
      (key) => {
        const count = props.questionsByType[key];
        if (count) result += count;
      }
    );
    return result;
  };
  const getTotalJumps = (): number => {
    let result: number = 0;
    (Object.keys(props.jumpsByType) as skTypes.JumpType[]).forEach((key) => {
      const count = props.jumpsByType[key];
      if (count) result += count;
    });
    return result;
  };
  const getTotalKErrors = (): number => {
    let result: number = 0;
    (Object.keys(props.knowledgeErrors) as skTypes.KErrorType[]).forEach(
      (key) => {
        const count = props.knowledgeErrors[key];
        if (count) result += count;
      }
    );
    return result;
  };

  return (
    <Row style={{ marginTop: 10, paddingBottom: 10 }}>
      <div>
        <h2>Questions</h2>
        <p>{`Total Questions: ${getTotalQuestions()}`}</p>
        {skDisableGraphs ? (
          <div>
            {(Object.keys(props.questionsByType) as skTypes.QuestionType[]).map(
              (key: skTypes.QuestionType) => {
                return (
                  <Row>
                    <div style={{ width: 200 }}>
                      {
                        // @ts-ignore
                        skTypes.momentTypeDescriptions[key] ||
                          // @ts-ignore
                          skTypes.errorDescription[key]
                      }
                    </div>
                    <div>{props.questionsByType[key]}</div>
                  </Row>
                );
              }
            )}
          </div>
        ) : (
          <Doughnut
            style={{ height: 150 }}
            data={{
              labels: questionsByTypeKeys.map((key: skTypes.QuestionType) => {
                return (
                  // @ts-ignore
                  skTypes.momentTypeDescriptions[key] ||
                  // @ts-ignore
                  skTypes.errorDescription[key]
                );
              }),
              datasets: [
                {
                  data: Object.values(props.questionsByType),
                  backgroundColor: questionsByTypeKeys.map(
                    (key: skTypes.QuestionType) => skTypes.momentTypeColor[key]
                  ),
                  hoverOffset: 5,
                },
              ],
            }}
            options={getDoughnutOptions(lightMode)}
          />
        )}
      </div>
      <div style={{ marginLeft: 10 }}>
        <h2>Jumps</h2>
        <p>{`Total Jumps: ${getTotalJumps()}`}</p>
        {skDisableGraphs ? (
          <div>
            {(Object.keys(props.jumpsByType) as skTypes.JumpType[]).map(
              (key: skTypes.JumpType) => {
                return (
                  <Row>
                    <div style={{ width: 200 }}>
                      {skTypes.jumpTypeDescription[key]}
                    </div>
                    <div>{props.jumpsByType[key]}</div>
                  </Row>
                );
              }
            )}
          </div>
        ) : (
          <Doughnut
            style={{ height: 150 }}
            data={{
              labels: jumpsByTypeKeys.map((key: skTypes.JumpType) => {
                return skTypes.jumpTypeDescription[key];
              }),
              datasets: [
                {
                  data: Object.values(props.jumpsByType),
                  backgroundColor: jumpsByTypeKeys.map(
                    (key: skTypes.JumpType) => skTypes.jumpTypeColor[key]
                  ),
                  hoverOffset: 5,
                },
              ],
            }}
            options={getDoughnutOptions(lightMode)}
          />
        )}
      </div>
      <div style={{ marginLeft: 10 }}>
        <h2>Knowledge Errors</h2>
        <p>{`Total Knowledge Errors: ${getTotalKErrors()}`}</p>
        {skDisableGraphs ? (
          <div>
            {(Object.keys(props.knowledgeErrors) as skTypes.KErrorType[]).map(
              (key) => {
                return (
                  <Row>
                    <div style={{ width: 200 }}>
                      {skTypes.kErrorDescription[key]}
                    </div>
                    <div>{props.knowledgeErrors[key]}</div>
                  </Row>
                );
              }
            )}
          </div>
        ) : (
          <Doughnut
            style={{ height: 150 }}
            data={{
              labels: knowledgeErrorKeys.map((key) => {
                return skTypes.kErrorDescription[key];
              }),
              datasets: [
                {
                  data: Object.values(props.knowledgeErrors),
                  backgroundColor: knowledgeErrorKeys.map(
                    (key) => skTypes.kErrorTypeColors[key]
                  ),
                  hoverOffset: 5,
                },
              ],
            }}
            options={getDoughnutOptions(lightMode)}
          />
        )}
      </div>
      {props.additionalRowContent}
    </Row>
  );
}
