import React, { useRef } from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  doubleClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function DoubleClickable(props: Props) {
  const firstClick = useRef<number>(-1);

  // HTML props, not including custom callback, to be sent to the div
  const divProps: Partial<Props> = {
    ...props,
  };
  delete divProps.doubleClick;

  return (
    <div
      {...divProps}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        const time: number = Date.now();
        // The 300 millisecond cutoff is arbitrarily defined.
        if (
          firstClick.current >= 0 &&
          Math.abs(time - firstClick.current) < 300
        ) {
          props.doubleClick(e);
          firstClick.current = -1;
        } else {
          firstClick.current = time;
        }
      }}
    >
      {props.children}
    </div>
  );
}
