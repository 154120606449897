import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { Row } from "../../components/Row";
import { HelpButton } from "../../components/HelpButton";
import { CircularProgress } from "@mui/material";
import { fetchWrapper } from "../../state/actions/fetchWrapper";
import * as skTypes from "../../types/sk";
import * as colors from "../../util/colors";
import "./SK.scss";

export function WatchQuiz() {
  const navigate = useNavigate();
  const location = useLocation();
  const [quizzes, setQuizzes] = useState<skTypes.WatchableQuiz[] | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    location.state?.errorMessage
  );
  const [importing, setImporting] = useState<boolean>(false); // imports a quiz into your current season
  const { selectedSeason, selectedEvent } = useSelector(
    (state: RootStore) => state.sk
  );
  const currentEventIndex = selectedSeason
    ? selectedSeason.events.findIndex(
        (event: skTypes.Event) => event.eventId === selectedEvent
      )
    : -1;

  const refresh = () => {
    const setQuizListError = () => {
      setQuizzes([]);
      setErrorMessage(
        "An error occurred getting the list of quizzes. Try again later."
      );
    };
    fetchWrapper(undefined, "GET", "scorekeeper/getwatchablequizlist")
      .then((data) => {
        if (data.returnCode === 0) {
          setQuizzes(data.responseMessage.quizzes);
        } else {
          setQuizListError();
        }
      })
      .catch(() => {
        setQuizListError();
      });
  };
  useEffect(refresh, []);

  return (
    <div className="page">
      <Row style={{ position: "relative" }}>
        <button className="back-button" onClick={() => navigate("/")}>
          Go Back
        </button>
        <button
          className="clickable"
          style={{ marginLeft: 10 }}
          onClick={() => {
            setQuizzes(undefined);
            refresh();
            setErrorMessage(undefined);
          }}
        >
          Refresh
        </button>
        <h1 style={{ marginLeft: 10 }}>Watch Quiz</h1>
        <HelpButton style={{ position: "absolute", right: 10 }} tab="watch" />
      </Row>
      <div className="important-information" style={{ marginTop: 15 }}>
        {errorMessage !== undefined
          ? errorMessage
          : "Choose a quiz below to watch."}
      </div>
      <div
        style={{
          marginTop: 20,
          border: `4px solid ${colors.bqaDarkGrey}`,
          padding: 15,
        }}
      >
        {!quizzes ? (
          <CircularProgress />
        ) : quizzes.length > 0 ? (
          <>
            {false && selectedSeason && selectedSeason && !importing && (
              <div style={{ marginBottom: 20 }}>
                <button
                  className="link"
                  onClick={() => setImporting(true)}
                  style={{ marginLeft: 0 }}
                >
                  Import Quiz Instead
                </button>
              </div>
            )}
            {importing && selectedSeason && currentEventIndex >= 0 && (
              <Row style={{ marginBottom: 20 }}>
                <span>
                  {`You are importing a quiz into your current season, ${selectedSeason.seasonName}, and your current event, ${selectedSeason.events[currentEventIndex].eventName}.`}
                </span>
                <button className="link" onClick={() => setImporting(false)}>
                  Cancel
                </button>
              </Row>
            )}
            <h3
              style={{ fontWeight: "bold", marginTop: 5 }}
            >{`Quizzes Available for ${
              importing ? "Importing" : "Watching"
            }`}</h3>
            {quizzes.map((quiz: skTypes.WatchableQuiz, index: number) => {
              const siteFontSize = quiz.quizName ? 12 : 16;
              return (
                <React.Fragment key={quiz.quizId}>
                  <button
                    className="season-event-list"
                    style={{
                      height: 25,
                      marginTop: index > 0 ? 5 : 0,
                    }}
                    onClick={() => {
                      if (!importing) {
                        // Send us to the quiz site and hope we have the correct password
                        navigate(`/watchquiz/${quiz.quizId}`);
                      }
                    }}
                  >
                    {quiz.quizName && <span>{quiz.quizName}</span>}
                    {quiz.site !== undefined && (
                      <>
                        <span
                          style={{
                            fontSize: siteFontSize,
                            marginLeft: quiz.quizName ? 5 : 0,
                          }}
                        >
                          {`Site ${quiz.site} `}
                        </span>
                      </>
                    )}
                    {quiz.round !== undefined && (
                      <>
                        &nbsp;&nbsp;
                        <span style={{ fontSize: siteFontSize }}>
                          {`Round ${quiz.round}`}
                        </span>
                      </>
                    )}
                  </button>
                  <br />
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <span>No quizzes are available.</span>
        )}
      </div>
    </div>
  );
}
