import { useNavigate } from "react-router-dom";
import { Row } from "../components/Row";
import { Link } from "react-router-dom";

export function NoPage() {
  const navigate = useNavigate();
  return (
    <div className="page">
      <h1>This page does not exist.</h1>
      <Row style={{ marginTop: 10 }}>
        <button className="clickable" onClick={() => navigate(-1)}>
          Go Back
        </button>
        <Link className="clickable" to="/" style={{ marginLeft: 10 }}>
          Return Home
        </Link>
      </Row>
    </div>
  );
}
