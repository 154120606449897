import { CSSProperties } from "react";
import { Link } from "react-router-dom";
import { TournamentSummary } from "../../types/tournamentTypes";

interface Props {
  pageId?: string;
  secondaryId?: string;
  text?: string;
  fallbackContent?: string;
  style?: CSSProperties;
  navigateState?: object;
}

export function TournamentLink(props: Props) {
  if (!props.pageId) return <span>{props.fallbackContent || null}</span>;

  const text = props.text
    ? props.text
    : `${props.pageId}${props.secondaryId ? ` ${props.secondaryId}` : ""}`;

  if (props.pageId.includes("www.")) {
    return (
      <a
        href={`//${props.pageId}`}
        style={props.style}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
    );
  }

  const getLinkTo = () => {
    if (!props.pageId) return "/tournaments";
    return `/tournaments/${props.pageId}${
      props.secondaryId ? `/${props.secondaryId}` : ""
    }`.replace(/ /g, "_");
  };
  return (
    <Link
      to={getLinkTo()}
      onClick={() => window.scrollTo(0, 0)}
      state={props.navigateState}
      style={props.style}
    >
      {text}
    </Link>
  );
}

export const TournamentPlaceContent = (props: {
  tournament: TournamentSummary;
  index: number;
}) => (
  <TournamentLink
    pageId={props.tournament.topPlacements[props.index]}
    navigateState={{
      alternatePage: {
        pageId: props.tournament.pageId,
        secondaryId: props.tournament.secondaryId,
      },
      tournamentSelectedTeam: props.tournament.topPlacements[props.index],
    }}
  />
);
