import { User } from "../../types/userTypes";
import {
  AuthenticationActions,
  SET_LOGIN,
  SET_LOGOUT,
} from "../actions/authentication";

interface AuthenticationState {
  user?: User;
}
const getInitialState = (): AuthenticationState => {
  let authStatus: { user: User } | undefined;
  try {
    authStatus = JSON.parse(localStorage.getItem("auth-status") || "{}");
  } catch {
    // empty
  }

  return {
    user: authStatus?.user,
  };
};
const initialState = getInitialState();

export default function authentication(
  state = initialState,
  action: AuthenticationActions
): AuthenticationState {
  switch (action.type) {
    case SET_LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    case SET_LOGOUT:
      localStorage.removeItem("auth-status");
      const newState = {
        ...state,
      };
      delete newState.user;
      return newState;
    default:
      return state;
  }
}
