import {
  ChapterStats,
  Quiz,
  QuizzerStats,
  Season,
  SeasonSummary,
  SKHotkey,
  TeamStats,
} from "../../types/sk";

export const SET_SK_SAVE_LOADING = "SET_SK_SAVE_LOADING";
export const SET_SK_SEASON_SUMMARIES = "SET_SK_SEASON_SUMMARIES";
export const SET_SK_SEASON = "SET_SK_SEASON";
export const SET_SK_EVENT = "SET_SK_EVENT";
export const SET_SK_QUIZ = "SET_SK_QUIZ";
export const SET_SK_STATS = "SET_SK_STATS";
export const SET_SK_HOTKEYS = "SET_SK_HOTKEYS";

export interface AllSKStats {
  QUIZZER?: QuizzerStats[];
  TEAM?: TeamStats[];
  CHAPTER?: ChapterStats[];
}
interface SKReducerData {
  skRequestLoading: boolean;
  seasons?: SeasonSummary[];
  selectedSeason?: Season;
  selectedEvent: string; // Event ID
  selectedQuiz?: Quiz;

  stats: AllSKStats;

  hotkeys: Record<SKHotkey, string>;
}
const getInitialState = (): SKReducerData => {
  const defaultHotkeys: Partial<Record<SKHotkey, string>> = {
    quote: "KeyQ",
    pbp: "KeyP",
    showTeam: "KeyT",
    runningScore: "KeyS",
    winProbabilities: "KeyY",
    throwout: "KeyT",
    halftime: "KeyH",
    noQuestion: "KeyN",
    overtime: "KeyO",
    assign: "Space",
    goUp: "ArrowUp",
    goDown: "ArrowDown",
    goRight: "ArrowRight",
    goLeft: "ArrowLeft",
    goBack: "Backspace",
    colon: "Semicolon",
    clearAll: "Escape",
    toggleSelection: "Enter",
    undo: "KeyZ",
    reverseTeams: "KeyV",
  };
  for (let i = 0; i < 10; i++) {
    defaultHotkeys[("num" + i) as SKHotkey] = "Digit" + i;
  }
  return {
    // Used for saving so we can go straight to them when we reload
    selectedEvent: "",
    hotkeys: {
      ...defaultHotkeys,
      ...JSON.parse((localStorage.getItem("sk-hotkeys") as string) || "{}"),
    },
    stats: {},
    skRequestLoading: false,
  };
};

const initialState = getInitialState();

export interface SetSKSaveLoading {
  type: typeof SET_SK_SAVE_LOADING;
  payload: {
    isLoading: boolean;
  };
}
export interface SetSeasonSummaries {
  type: typeof SET_SK_SEASON_SUMMARIES;
  payload: {
    summaries: SeasonSummary[];
  };
}
export interface SetSKSeason {
  type: typeof SET_SK_SEASON;
  payload: {
    season?: Season;
  };
}
export interface SetSKQuiz {
  type: typeof SET_SK_QUIZ;
  payload: {
    quiz?: Quiz;
  };
}
export interface SetSKEvent {
  type: typeof SET_SK_EVENT;
  payload: {
    selectedEvent: string;
  };
}
export interface SetSKStats {
  type: typeof SET_SK_STATS;
  payload: {
    stats: AllSKStats;
  };
}
export interface SetSKHotkeys {
  type: typeof SET_SK_HOTKEYS;
  payload: {
    hotkeys: Record<SKHotkey, string>;
  };
}
export type SKReducerTypes =
  | SetSeasonSummaries
  | SetSKSeason
  | SetSKEvent
  | SetSKQuiz
  | SetSKStats
  | SetSKSaveLoading
  | SetSKHotkeys;

export default function sk(
  state = initialState,
  action: SKReducerTypes
): SKReducerData {
  switch (action.type) {
    case SET_SK_SAVE_LOADING:
      return {
        ...state,
        skRequestLoading: action.payload.isLoading,
      };
    case SET_SK_SEASON_SUMMARIES:
      return {
        ...state,
        seasons: action.payload.summaries || [],
        skRequestLoading: false,
      };
    case SET_SK_SEASON:
      return {
        ...state,
        selectedSeason: action.payload.season,
        skRequestLoading: false,
      };
    case SET_SK_EVENT:
      return {
        ...state,
        selectedEvent: action.payload.selectedEvent,
        skRequestLoading: false,
      };
    case SET_SK_QUIZ:
      return {
        ...state,
        selectedQuiz: action.payload.quiz,
        skRequestLoading: false,
      };
    case SET_SK_STATS:
      return {
        ...state,
        stats: {
          ...state.stats,
          ...action.payload.stats,
        },
        skRequestLoading: false,
      };
    case SET_SK_HOTKEYS:
      localStorage.setItem(
        "sk-hotkeys",
        JSON.stringify(action.payload.hotkeys)
      );
      return {
        ...state,
        hotkeys: action.payload.hotkeys,
      };
    default:
      return state;
  }
}
