import { useEffect, useRef, useState } from "react";

export const useAcme = (onInputUpdate?: (teams: number[][]) => void) => {
  type Modes = "LIGHTS" | "FIRST" | "OFF";
  const [lights, setLights] = useState<number[][]>([]);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [currentMode, setCurrentMode] = useState<Modes>("OFF");
  const acmeDevice = useRef<any>(null);

  const setMode = async (mode: Modes, newLights?: boolean[][]) => {
    if (!acmeDevice.current) return;
    setCurrentMode(mode);
    // let args: number[] = [0];
    // switch (mode) {
    //   case "FIRST":
    //     if (!newLights) return;
    //     args.push(4);

    //     const seatArgs: number[] = [0, 3];
    //     await acmeDevice.current.sendReport(0, new Uint8Array(seatArgs));
    //     return
    //   case "LIGHTS":
    //     args.push(9);
    // }
    // args.push(1);
    await acmeDevice.current.sendReport(0, new Uint8Array([9, 16]));
  };

  // Disconnect from the device
  useEffect(() => {
    return () => {
      if (acmeDevice.current) {
        acmeDevice.current.forget();
      }
    };
  }, []);

  const listener = (ev: any) => {
    const data: ArrayBuffer = ev.data.buffer;
    const arr = new Uint8Array(data);
    const teams = [arr[1], arr[2], arr[3], arr[4]];

    const result: number[][] = [];

    teams.forEach((team) => {
      let teamBin = team.toString(2);
      while (teamBin.length < 5) {
        teamBin = `0${teamBin}`;
      }
      const teamResult: number[] = [];
      for (let i = 0; i < 5; i++) {
        const lightOn = teamBin.charAt(4 - i) === "1";
        teamResult.push(lightOn ? 1 : 0);
      }
      result.push(teamResult);
    });

    // Finish up
    if (onInputUpdate) onInputUpdate(result);
    setLights(result);
    setIsReady(true);
  };

  const init = async (next?: (device: any) => void) => {
    try {
      const nav = navigator as any;
      const device = (
        await nav.hid.requestDevice({ filters: [{ vendorId: 1240 }] })
      )[0];

      await device.open();
      acmeDevice.current = device;

      // Set up listening to jumps
      device.addEventListener("inputreport", listener);

      // Finish up
      await setMode("LIGHTS");
      if (next) next(device);
    } catch {
      // failed to connect
    }
  };
  return {
    isReady,
    init,
    lights,
    mode: currentMode,
    setMode,
  };
};
