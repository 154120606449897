import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "../../state/store";
import { Row } from "../Row";
import { Check } from "../Check/Check";
import { PlusMinusButton } from "../PlusMinusButton";
import { SettingsProps } from "./";
import { combineBooleans, xor } from "../../util/generalUtil";
import { Book } from "../../types/material";
import { SET_SELECTED_CHAPTERS } from "../../state/reducers/settings";
import "./settings.scss";

// Current Material
const booksExpanded: number[] = [2];
const giveLinks: number[] = [2];

/**
 * All books are not expanded, except current year's material (defined above)
 * @returns an array of all expanded books
 */
const getDefaultExpanded = (material: Book[]) => {
  const currentExpanded = new Set(booksExpanded);
  const result: { book: boolean; chapters: boolean[] }[] = [];
  material.forEach((book, bookIndex) => {
    result.push({
      book: currentExpanded.has(bookIndex),
      chapters: Array(book.chapters.length).fill(false),
    });
  });
  return result;
};

export function ChapterSelector(props: SettingsProps) {
  const dispatch = useDispatch();
  const { material } = useSelector((state: RootStore) => state.material);
  const settings = useSelector((state: RootStore) => state.settings);
  const [selectedChapters, setSelectedChapters] = useState([
    ...settings.selectedChapters,
  ]);
  const [selectedSKBooks, setSelectedSKBooks] = useState([
    ...settings.selectedSKBooks,
  ]);
  const [expanded, setExpanded] = useState(getDefaultExpanded(material));

  const scrollToBook = (bookIndex: number) => {
    // Get the items to work with
    const outerItem = document.getElementById("root");
    const innerItem = document.getElementById(`book-id-${bookIndex}`);
    if (outerItem && innerItem) {
      window.scrollTo(
        0,
        innerItem.getBoundingClientRect().top -
          outerItem.getBoundingClientRect().top -
          130
      );
    }
  };
  const getVerseChecked = (book: number, chapter: number, verse: number) => {
    if (typeof selectedChapters[book][chapter] === "boolean") {
      return selectedChapters[book][chapter] as boolean;
    } else {
      return (selectedChapters[book][chapter] as boolean[])[verse];
    }
  };
  useEffect(() => {
    scrollToBook(booksExpanded[0]);
  }, []);

  return (
    <div className="settings-container">
      <div className="settings-top-sticky">
        <button
          className="clickable"
          style={{ marginLeft: 10, marginBottom: 20 }}
          onClick={() => {
            dispatch({
              type: SET_SELECTED_CHAPTERS,
              payload: {
                chapters: selectedChapters,
                skBooks: selectedSKBooks,
              },
            });
            props.onClickBack(settings);
          }}
        >
          Back
        </button>
        <button
          className="link"
          style={{ marginLeft: 10 }}
          onClick={() => {
            setSelectedChapters(
              material.map((book) => book.chapters.map(() => false))
            );
            setSelectedSKBooks(material.map(() => false));
          }}
        >
          Clear All
        </button>
        {giveLinks.length > 0 && (
          <div style={{ marginLeft: 10, marginBottom: 5 }}>
            {giveLinks.map((item) => (
              <button
                key={`book-expanded-${item}-link`}
                className="link"
                style={{ marginRight: 10 }}
                onClick={() => scrollToBook(item)}
              >
                {material[item]?.bookName}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="settings-scrolling" id="scrolling-div">
        {material.map((book: Book, index: number) => (
          <div
            key={`book-check-${book.bookName}`}
            style={{ marginTop: 5 }}
            id={`book-id-${index}`}
          >
            {index === 27 && (
              <p style={{ fontSize: 12 }}>
                Psalms verses and questions provided to support{" "}
                <a
                  href="https://www.majorleaguequizzing.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MLQ
                </a>
                .
              </p>
            )}
            <Row>
              <PlusMinusButton
                style={{ marginTop: 7 }}
                onClick={() => {
                  // Expand or collapse the book
                  const newExpanded = [...expanded];
                  newExpanded[index] = {
                    ...newExpanded[index],
                    book: !newExpanded[index].book,
                  };
                  setExpanded(newExpanded);
                }}
              >
                {expanded[index].book ? "-" : "+"}
              </PlusMinusButton>
              <Check
                checked={combineBooleans(selectedChapters[index])}
                outline={xor(selectedChapters[index])}
                onClick={(checked) => {
                  // Select the entire book
                  const result = [...selectedChapters];
                  result[index] = Array(book.chapters.length).fill(checked);
                  setSelectedChapters(result);
                }}
                style={{ fontSize: 20, width: 230, marginLeft: 5 }}
              >
                {book?.bookName}
              </Check>
              <Check
                checked={selectedSKBooks[index]}
                onClick={(checked) => {
                  const result = [...selectedSKBooks];
                  result[index] = checked;
                  setSelectedSKBooks(result);
                }}
                style={{ marginLeft: 10, width: 45 }}
              >
                SK
              </Check>
            </Row>
            {expanded[index].book && (
              <div style={{ marginLeft: 20 }}>
                {book.chapters.map((chapter, chapterIndex: number) => (
                  <React.Fragment key={`${book.bookName}-${chapterIndex}`}>
                    <Row
                      key={`chapter-check-${book.bookName}-${chapterIndex + 1}`}
                    >
                      <PlusMinusButton
                        style={{ marginTop: 7 }}
                        onClick={() => {
                          // Expand or collapse the book
                          const newExpanded = [...expanded];
                          newExpanded[index] = {
                            ...newExpanded[index],
                            chapters: [...newExpanded[index].chapters],
                          };
                          newExpanded[index].chapters[chapterIndex] =
                            !newExpanded[index].chapters[chapterIndex];
                          setExpanded(newExpanded);
                        }}
                      >
                        {expanded[index].chapters[chapterIndex] ? "-" : "+"}
                      </PlusMinusButton>
                      <Check
                        style={{ marginLeft: 4, width: 240 }}
                        checked={selectedChapters[index][chapterIndex] === true}
                        outline={Array.isArray(
                          selectedChapters[index][chapterIndex]
                        )}
                        onClick={(checked) => {
                          // Select the chapter
                          const result = [...selectedChapters];
                          result[index][chapterIndex] = checked;
                          setSelectedChapters(result);
                        }}
                      >
                        {`${book?.bookName} ${chapterIndex + 1}`}
                      </Check>
                    </Row>
                    {expanded[index].chapters[chapterIndex] && (
                      <Row>
                        {chapter.map((verse, verseIndex) => (
                          <Check
                            key={`${book.bookName}-${chapterIndex}-${verseIndex}`}
                            style={{
                              width: 35,
                              textAlign: "center",
                              padding: 0,
                            }}
                            checked={getVerseChecked(
                              index,
                              chapterIndex,
                              verseIndex
                            )}
                            onClick={(checked) => {
                              const result = [...selectedChapters];
                              let newChapter = result[index][chapterIndex];
                              if (typeof newChapter === "boolean") {
                                newChapter = Array(chapter.length).fill(
                                  newChapter
                                );
                              }
                              newChapter[verseIndex] = checked;
                              if (!xor(newChapter)) {
                                // All verses are true or false, so no array is needed
                                newChapter = checked;
                              }
                              result[index][chapterIndex] = newChapter;
                              setSelectedChapters(result);
                            }}
                          >
                            {verseIndex + 1}
                          </Check>
                        ))}
                      </Row>
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
