import { Modal as MUIModal, Fade, Box, Backdrop } from "@mui/material";
import { ReactNode } from "react";
import "./Modal.scss";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title: ReactNode; // Probably a string
}

export function Modal(props: ModalProps) {
  return (
    <MUIModal
      open={props.open}
      onClose={props.onClose}
      slots={{
        backdrop: () => (
          <Backdrop
            className="modal-backdrop"
            style={{
              opacity: 0.2,
              width: "100%",
              height: "100%",
              transition: "5s fade in out",
            }}
            open={props.open}
            onClick={props.onClose}
            transitionDuration={5000}
          />
        ),
      }}
      className="modal-component"
    >
      <Fade in={props.open} timeout={400}>
        <Box
          className="light-mode modal-box"
          style={{
            transform: "translate(-50%, -50%)",
            top: "40%",
            left: "50%",
            position: "absolute",
            width: 400,
            borderRadius: "2rem",
            padding: "1rem 1.5rem",
          }}
        >
          <h2 style={{ marginBottom: "1rem" }}>{props.title}</h2>
          {props.children}
        </Box>
      </Fade>
    </MUIModal>
  );
}
