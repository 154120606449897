import plg from "compromise-speech";
import nlp from "compromise";
import { TextDetails } from "../types/generalTypes";
import { QMSpeeds } from "../types/settings";
nlp.extend(plg);

export const breakupSyllables = (question: string): TextDetails[] => {
  const doc = nlp(question);
  doc.compute("syllables");
  const result = doc.json()[0].terms;

  result.forEach((term: TextDetails) => {
    // Fix syllables, if needed
    if (term.normal in manualSyllables) {
      term.syllables = manualSyllables[term.normal];
    }

    // Numeric - get approximate syllables
    const num = Number.parseInt(term.text);
    if (!isNaN(num)) {
      if (num > 10) term.syllables = term.text.split("");
    }
  });
  return result;
};

const speedFactor = {
  SLOW: 1.3,
  MEDIUM: 1,
  FAST: 0.7,
};

export const baseTimeForWord = (qmSpeed: QMSpeeds) => {
  return 230 * speedFactor[qmSpeed];
};
export const timeForNextSyllable = (
  word: TextDetails,
  syllableIndex: number,
  qmSpeed: QMSpeeds
): number => {
  let baseTime = 120;
  if (syllableIndex === word.syllables.length) {
    baseTime += 90;
    if (word.post.length > 1) baseTime += 30;
  }
  const accurateTime = baseTime * speedFactor[qmSpeed];
  const withRandomization = accurateTime * (Math.random() * 0.1 + 0.95);
  return Math.floor(withRandomization);
};

const manualSyllables: Record<string, string[]> = {
  judea: ["ju", "de", "a"],
  beyond: ["bey", "ond"],
  guardian: ["guar", "di", "an"],
  philemon: ["phi", "le", "mon"],
};
