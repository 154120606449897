import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Check } from "../../components/Check/Check";
import { Row } from "../../components/Row";
import { useKeyedParams } from "../../hooks/useParams";
import { v4 as uuid } from "uuid";
import { StringInput } from "../../components/Inputs";

export function MultiTeamsLobby() {
  const params = useKeyedParams<{ roomId?: string }>();
  const isCreating = !params.roomId;
  const navigate = useNavigate();
  const [role, setRole] = useState(isCreating ? "QM" : "");
  const [myName, setMyName] = useState("");
  const [includeVideo, setIncludeVideo] = useState<boolean>(true);
  const [roomId] = useState(params.roomId || uuid());
  const getURL = () => {
    let result = window.location.href;
    if (!result.endsWith("/")) result += "/";
    result += roomId;
    return result;
  };

  return (
    <div className="page">
      <Row>
        <button
          className="back-button"
          onClick={() => navigate("/multiplayer")}
        >
          Go Back
        </button>
        <h1 style={{ marginLeft: 10 }}>{`${
          isCreating ? "Create" : "Join"
        } a Multiplayer Team Quiz`}</h1>
      </Row>
      {isCreating && (
        <div>
          <p>
            <span>{`The URL to join the quiz will be ${getURL()} Send it via email or any other medium to anyone who is going to join the quiz.`}</span>{" "}
            <button
              className="link"
              onClick={() => {
                navigator.clipboard.writeText(getURL());
              }}
            >
              Copy URL
            </button>
          </p>

          <p>You will be the Quizmaster in this quiz.</p>
        </div>
      )}
      {!isCreating && (
        <div style={{ maxWidth: 250, marginBottom: 10 }}>
          <h3>Select Your Role</h3>
          <Check checked={role === "TEAM"} onClick={() => setRole("TEAM")}>
            Team
          </Check>
          <p>Spectator options coming soon.</p>
          {/* <Check checked={role === "SPEC"} onClick={() => setRole("SPEC")}>
            Spectator
          </Check> */}
        </div>
      )}
      {role && (
        <StringInput
          caption={`Your${role === "TEAM" ? "Team Name" : "name"}`}
          value={myName}
          onChange={setMyName}
        />
      )}

      {role === "TEAM" && (
        <div style={{ maxWidth: 250, marginTop: 10 }}>
          {" "}
          <Check checked={includeVideo} onClick={setIncludeVideo}>
            Include Video (Recommended)
          </Check>{" "}
        </div>
      )}
      <h3>Before you join</h3>
      <p>
        <span>You will be on video with the other teams in the quiz.</span>
        {role === "TEAM" && (
          <span>
            Make sure your whole team is together, with a set of Acme (or
            similar) pads.
          </span>
        )}
      </p>
      <p>
        There will be a delay between when the first quizzer jumps and when they
        are called on. This is to ensure teams with slower internet connection
        still have a chance to win jumps. The quizmaster will not call on the
        quizzer; instead, the team and seat who won the jump will be displayed
        on the screen.
      </p>
      {role && (
        <Link
          className="clickable"
          style={{ marginTop: 10 }}
          to={`/multiplayer/teams/${roomId}/quiz`}
          state={{
            role,
            myName,
            includeVideo: role === "SPEC" ? false : includeVideo,
          }}
        >
          Join Quiz!
        </Link>
      )}
    </div>
  );
}
