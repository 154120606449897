export const getMediaStream = async (params: {
  video: boolean;
  audio: boolean;
}): Promise<MediaStream | undefined> => {
  const func =
    navigator.mediaDevices?.getUserMedia ||
    // @ts-ignore
    navigator.getUserMedia ||
    // @ts-ignore
    navigator.webKitGetUserMedia ||
    // @ts-ignore
    navigator.moxGetUserMedia ||
    // @ts-ignore
    navigator.mozGetUserMedia ||
    // @ts-ignore
    navigator.msGetUserMedia;
  if (!func) return undefined;
  return func(params);
};
