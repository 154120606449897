import dayjs from "dayjs";

/**
 * gets a readable date range
 * @param startDate the first date of the range
 * @param endDate the last date of the range
 * @returns a string in a readable format representing the date range
 */
export const getDateRangeDisplay = (
  startDate: string,
  endDate: string = startDate
): string => {
  const fullFormatStr = "MMM D, YYYY";
  const monthFormatStr = "MMM D";
  if (startDate === endDate) return dayjs(startDate).format(fullFormatStr);
  const s1 = dayjs(startDate);
  const s2 = dayjs(endDate);
  if (s1.year() !== s2.year())
    return s1.format(fullFormatStr) + " - " + s2.format(fullFormatStr);
  const yearPart = ", " + s1.year();
  if (s1.month() !== s2.month())
    return (
      s1.format(monthFormatStr) + " - " + s2.format(monthFormatStr) + yearPart
    );
  // only days are different
  return s1.format(monthFormatStr) + " - " + s2.date() + yearPart;
};

export const getTimeStampDisplay = (timestamp: string) => {
  const day = dayjs(timestamp);
  return `${getDateRangeDisplay(timestamp)} at ${day.format("HH:mm")}`;
};
