import {
  PageSearch,
  Tournament,
  TournamentPage,
  TournamentPageID,
  TournamentSummary,
} from "../../types/tournamentTypes";
import { TournamentActionTypes } from "../actions/tournamentActions";

interface TState {
  tournamentList: TournamentSummary[];
  trending: TournamentPageID[];
  searchPages: PageSearch[];
  selectedPage?: TournamentPage;
  tournamentsUsage: any[];
  isLoading: boolean;
  success: boolean;
  errorMessage: string;
}

const initialState: TState = {
  tournamentList: [],
  trending: [],
  searchPages: [],
  tournamentsUsage: [],
  isLoading: false,
  success: true,
  errorMessage: "",
};

export default function tournaments(
  state = initialState,
  action: TournamentActionTypes
): TState {
  switch (action.type) {
    case "SET_TOURNAMENTS_LOADING":
      return {
        ...state,
        isLoading: action.payload.isLoading,
        success: action.payload.success,
        errorMessage: action.payload.errorMessage,
      };
    case "SET_TOURNAMENT_LIST":
      return {
        ...state,
        tournamentList: action.payload.tournaments,
        isLoading: false,
        trending: action.payload.trending || [],
      };
    case "SET_TOURNAMENT_PAGE":
      return {
        ...state,
        selectedPage: action.payload.page,
        isLoading: false,
        success: true,
        errorMessage: "",
      };
    case "SET_TOURNAMENT_EVENT": {
      if (!state.selectedPage || state.selectedPage.pageType !== "TOURNAMENT")
        return state;
      const page: Tournament = { ...state.selectedPage };
      const eventIndex = page.events.findIndex(
        (ev) => ev.eventName === action.payload.event.eventName
      );
      if (eventIndex >= 0) {
        page.events = [...page.events];
        page.events[eventIndex] = action.payload.event;
      }
      return {
        ...state,
        selectedPage: page,
      };
    }
    case "SET_TOURNAMENT_SEARCH":
      return {
        ...state,
        searchPages: action.payload.pages,
        isLoading: false,
      };
    case "SET_TOURNAMENTS_USAGE":
      return {
        ...state,
        tournamentsUsage: action.payload.usage,
      };
    default:
      return state;
  }
}
