import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRequestHandler } from "../../hooks/requestHandler";
import { useKeyedParams } from "../../hooks/useParams";
import { createNewPassword } from "../../state/actions/authentication";
import styles from "./Authentication.module.css";
import { StringInput } from "../../components/Inputs";

interface ResetPasswordProps {
  resetId: string;
}

export function ResetPassword() {
  const navigate = useNavigate();
  const { resetId } = useKeyedParams<ResetPasswordProps>();
  const [pwd1, setPwd1] = useState<string>("");
  const [pwd2, setPwd2] = useState<string>("");
  const handler = useRequestHandler({
    onSuccess: () => {
      setTimeout(() => {
        navigate("/login");
      }, 300);
    },
  });

  return (
    <div className="page">
      {handler.snackbar}
      <div className={styles.screen_container}>
        <h1>Create a new password</h1>
        <StringInput caption="New Password" value={pwd1} onChange={setPwd1} />
        <StringInput
          caption="Confirm Password"
          value={pwd2}
          onChange={setPwd2}
        />
        <div style={{ marginTop: 20 }}>
          <button
            className="clickable"
            onClick={() => {
              if (pwd1 !== pwd2) return;
              const parts = resetId.split("_");
              if (parts.length !== 2) return;
              handler.runRequest(
                createNewPassword(parts[0], parts[1], pwd1),
                "Resetting your password...",
                "Your password has been reset!"
              );
            }}
          >
            Create Password
          </button>
          <Link className="link" style={{ marginLeft: 10 }} to="/login">
            Back to Login
          </Link>
        </div>
      </div>
    </div>
  );
}
