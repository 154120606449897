import dayjs from "dayjs";
import { TournamentPage } from "../types/tournamentTypes";
import { User } from "../types/userTypes";

export const tournamentSpecialChars = new Set([
  "\n",
  "[",
  "]",
  "\\",
  "'",
  "*",
  "`",
]);

/**
 * checks if the user can edit the tournament page. Does not validate against current data.
 * @param page the page being edited
 * @param user the user attempting the edit
 * @returns if the user has write access to the page
 */
export const canEditPage = (page: TournamentPage, user?: User): boolean => {
  if (!user) return false;
  if (user.securityClearance.logonType === "ADMIN") return true;
  if (
    "ministry" in page &&
    user.securityClearance.tournamentAccess?.includes(page.ministry)
  )
    return true;
  return false;
};

/**
 * uses data in a page to get a description
 * @param page the page
 * @returns description text for top of the page
 */
export const getPageDescription = (page: TournamentPage): string => {
  // Description overrides everything
  if (page.description) return page.description;
  let result = "";

  const addShortBio = () => {
    // Add the short bio to any page
    if (page.additionalData.shortBio) {
      result = `${result} ${page.additionalData.shortBio}`;
    }
  };
  switch (page.pageType) {
    case "TOURNAMENT":
      const isOnline = page.location === "Online";

      result = `${getPageName(page)} is a`;

      // Time variation
      const now = dayjs();
      const isNotFuture = page.startDate && now > dayjs(page.startDate);
      const isNotPast = page.endDate && now < dayjs(page.endDate);
      if (isNotPast) {
        result = `${result}${isNotFuture ? "n ongoing" : "n upcoming"}`;
      }
      if (isOnline) {
        result = `${result}${!isNotPast ? "n" : ""} online`;
      }

      // Final details
      result = `${result} tournament`;
      if (page.host) result = `${result} hosted by [[${page.host}]]`;
      if (page.location && !isOnline)
        result = `${result} held in ${page.location}`;
      result += ".";
      addShortBio();

      if (isNotFuture && isNotPast)
        result +=
          "\n\nThe most up-to-date statistics and quizzes may be available at [[www.quizzing.live|quizzing.live]].";
      break;
    case "QUIZZER":
      result = `${page.pageId} is a ${
        page.lastActive ? "former " : ""
      }quizzer for [[${page.ministry}]]`;
      result += ".";
      break;
    case "TEAM":
      result = `${page.pageId} ${
        page.lastActive ? "was" : "is"
      } a team quizzing for [[${page.ministry}]]`;
      result += ".";
      break;
    case "MINISTRY":
      result = `${page.ministryName} (${page.pageId}) ${
        page.lastActive ? "was" : "is"
      } a ministry based in ${page.location}`;
      result += ".";
      break;
    default:
      break;
  }

  if (page.pageType !== "TOURNAMENT") addShortBio();
  return result;
};

export const removeMarkdown = (text: string) => {
  let result = text;
  let isSkipped = false;
  let phase = "TEXT";
  let linkText = "";
  let linkStart = 0;
  for (let i = 0; i < result.length; i++) {
    const char = result.charAt(i);
    if (char === "\n" && phase === "TEXT") {
      // New line; replace with space
      result = result.substring(0, i) + " " + result.substring(i + 2);
      continue;
    }
    if (char === "\\") {
      // Escaped character; ignore the escape and keep the character by skipping the removal
      i++;
      isSkipped = true;
      continue;
    }

    if (tournamentSpecialChars.has(char)) {
      if (isSkipped) {
        isSkipped = false;
      } else if (char === "[" && phase === "TEXT") {
        // Start of link
        linkStart = i - 1;
        i++;
        phase = "LINK";
        linkText = "";
      } else if (char === "]" && phase === "LINK") {
        // End of link
        const newIndex = linkStart + linkText.length;
        result =
          result.substring(0, linkStart) +
          " " +
          linkText +
          result.substring(i + 2);
        i = newIndex;
        phase = "TEXT";
      } else if (result.charAt(i + 1) === char) {
        // double character; skip the characters but keep the text
        result = result.substring(0, i) + result.substring(i + 2);
        i -= 1;
      }
    } else if (phase === "LINK") {
      // No special character, but in a link
      if (char === "|") {
        // Alias text; use that instead of the actual link
        linkText = "";
      } else if (char === "/") {
        // remove break to secondary ID
        linkText += " ";
      } else {
        linkText += char;
      }
    }
  }
  return result;
};
export const getPageName = (page: TournamentPage): string => {
  if (page.pageType === "MINISTRY") {
    return page.ministryName;
  }
  return `${page.pageId}${page.secondaryId ? ` ${page.secondaryId}` : ""}`;
};

export const getTeamNameDisplay = (
  teamName: string,
  ministry?: string
): string => {
  let nameResult = teamName;
  if (ministry) {
    nameResult = `${nameResult} - ${ministry}`;
  }
  return nameResult;
};
