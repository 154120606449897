import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Chart,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend
);

export const getDoughnutOptions = (lightMode: boolean) => ({
  maintainAspectRatio: true,
  responsive: true,
  elements: { point: { radius: 2 } },
  animation: { duration: 0 }, // general animation time
  responsiveAnimationDuration: 0,
  plugins: {
    legend: {
      display: true,
      position: "top" as "top",
      labels: getChartLabelOptions(lightMode),
    },
  },
});
export const getChartLabelOptions = (lightMode: boolean) => ({
  color: lightMode ? "black" : "lightgrey",
  font: {
    size: 14,
  },
});
