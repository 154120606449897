import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { Row } from "../../components/Row";
import { useKeyedParams } from "../../hooks/useParams";
import io, { Socket } from "socket.io-client";
import Peer from "peerjs";
import * as Types from "../../types/multiplayer";
import { getMediaStream } from "../../util/voiceUtils";

interface MultiplayerIndividualProps {
  roomId: string;
}

type PhaseType =
  | "WAITING" // we will enter the quiz once the current question finishes
  | "READY" // A new question can be read
  | "ASKING" // a question is being read
  | "JUMPED" // I have jumped
  | "ANSWERING" // Someone is answering
  | "RULING"; // Time has expired and we are ruling on the result of the question
const statsColumnWidths = [150, 100, 100];
const myVideoId: string = "my-video-element";

export function MultiplayerIndividual() {
  const navigate = useNavigate();
  const material = useSelector((state: RootStore) => state.material.material);
  const settings = useSelector((state: RootStore) => state.settings);
  const { roomId } = useKeyedParams<MultiplayerIndividualProps>();
  const location: any = useLocation();
  const userName: string = location?.state?.userName;
  const [myId, setMyId] = useState<string>("");
  const [muted, setMuted] = useState<boolean>(false); // If we are muted completely, not automatically

  const socketIO = useRef<Socket | undefined>(undefined);
  const [phase, setPhase] = useState<PhaseType>("READY");
  const [quizzerAnswering, setQuizzerAnswering] = useState<string>("");
  const [timeTaken, setTimeTaken] = useState<number>(0);
  const [quizzerData, setQuizzerData] = useState<any[]>([]);
  const quizzerDataRef = useRef<any[]>([]);
  const [quizzerDetails, setQuizzerDetails] = useState<Record<string, any>>({});
  const [myVote, setMyVote] = useState<Types.VoteType>("NONE");
  const [noQuestion, setNoQuestion] = useState<boolean>(false);
  //const [currentQuestion, setCurrentQuestion] = useState<any>({});
  const currentQuestionRef = useRef<any>({});
  const getQuestionJSX = (): React.ReactNode => {
    if (!currentQuestionRef.current) return "";
    // Gets a jsx expression with the current question and verse
    return (
      <>
        <br />
        {currentQuestionRef.current.question}
        <br />
        <br />
        {material[currentQuestionRef.current.book].bookName}{" "}
        {currentQuestionRef.current.chapter + 1}:
        {currentQuestionRef.current.verse + 1}&nbsp;
        {
          material[currentQuestionRef.current.book].chapters[
            currentQuestionRef.current.chapter
          ][currentQuestionRef.current.verse].verse
        }
      </>
    );
  };

  const [screenText, setScreenText] = useState<React.ReactNode>("");
  const realScreenText = useRef<React.ReactNode>("");
  const words = useRef<string[]>([]);
  const currentWord = useRef(0);

  const [startReadingQuestion, setStartReadingQuestion] = useState<number>(0);
  const quizmasterTimer = useRef<any>(null);
  useEffect(() => {
    if (startReadingQuestion) {
      const newId = setInterval(() => {
        if (
          (words.current[currentWord.current + 1].includes("(") ||
            words.current[currentWord.current + 1].includes("[")) &&
          !words.current[currentWord.current]?.includes("?")
        ) {
          words.current[currentWord.current] += "?";
        }
        const newText =
          realScreenText.current + " " + words.current[currentWord.current];
        setScreenText(newText);
        realScreenText.current = newText;
        if (words.current[currentWord.current]?.includes("?")) {
          clearInterval(quizmasterTimer.current);
          setStartWaitTimer(Date.now());
        }

        currentWord.current = currentWord.current + 1;
      }, 300);
      quizmasterTimer.current = newId;
    }

    return () => clearTimeout(quizmasterTimer.current);
  }, [startReadingQuestion]);

  const [startReacting, setStartReacting] = useState(0);
  const reactionTimer = useRef<any>(undefined);
  useEffect(() => {
    if (startReacting) {
      const newId = setInterval(() => {
        clearTimeout(quizmasterTimer.current);
        clearTimeout(reactionTimer.current);

        if (socketIO.current) {
          socketIO.current.emit("USER_JUMP", {
            timeDiff: Date.now() - startReadingQuestion,
            received: realScreenText.current,
          });
        }
      }, 200);
      reactionTimer.current = newId;
    }

    return () => clearTimeout(reactionTimer.current);
  }, [startReacting]);

  // The three seconds you have to jump
  const [startWaitTimer, setStartWaitTimer] = useState<number>(0);
  const waitTimer = useRef<any>(null);
  useEffect(() => {
    if (startWaitTimer) {
      const newId = setTimeout(() => {
        setNoQuestion(true);
        if (socketIO.current) socketIO.current.emit("NO_QUESTION");
      }, 3000);
      waitTimer.current = newId;
    }
  }, [startWaitTimer]);

  const [startTimekeeper, setStartTimekeeper] = useState<number>(0);
  const timekeeperTimer = useRef<any>(null);
  useEffect(() => {
    if (startTimekeeper) {
      const newId = setInterval(() => {
        setTimeTaken((time) => {
          const isQuote =
            currentQuestionRef.current.question.startsWith("Quote?");
          if (time === (isQuote ? 29 : 19)) {
            if (quizzerAnswering === myId && socketIO.current !== undefined) {
              socketIO.current.emit("TIME_RUN_OUT");
            }
          }
          return time + 1;
        });
      }, 1000);
      timekeeperTimer.current = newId;
    }
  }, [startTimekeeper]);

  useEffect(() => {
    const socket = io("/");
    socketIO.current = socket;
    const videoGrid = document.getElementById("multiplayer-individual-page");

    // @ts-ignore
    const myPeer = new Peer(undefined, {
      secure: true,
      debug: 2,
    });
    const myVideo = document.createElement("video");
    myVideo.id = myVideoId;
    myVideo.muted = true;
    const peers: any = {};
    getMediaStream({
      video: false,
      audio: true,
    }).then((stream) => {
      addVideoStream(myVideo, stream);

      myPeer.on("call", (call: any) => {
        call.answer(stream);
        const video = document.createElement("video");
        video.id = `video-${call.peer}`; // The PeerJS id of the person calling
        call.on("stream", (userVideoStream: any) => {
          addVideoStream(video, userVideoStream);
        });
        call.on("close", () => {
          video.pause();
          video.remove();
        });
      });

      socket.on("QUIZZER_UPDATE", (params) => {
        const set: Set<string> = new Set(
          quizzerDataRef.current.map((quizzer) => quizzer.quizzerId)
        );
        params.quizzers.forEach((quizzer: any) => {
          if (!set.has(quizzer.quizzerId)) {
            if (quizzer.quizzerId === myPeer.id) return;
            connectToNewUser(quizzer.quizzerId, stream);
          } else {
            set.delete(quizzer.quizzerId);
          }
        });
        Array.from(set).forEach((quizzerId: string) => {
          if (peers[quizzerId]) {
            peers[quizzerId].close();
          }
        });
        setQuizzerData(params.quizzers);
        quizzerDataRef.current = params.quizzers;
      });
    });

    myPeer.on("open", (id: string) => {
      socket.emit("USER_CONNECTING", {
        roomId,
        quizzerName: userName,
        quizzerId: id,
        chapters: settings.selectedChapters,
        displayBookName: settings.qnBookName,
      });
      setMyId(id);
    });

    socket.on("SEND_QUIZZERS", (params: any) => {
      setPhase(params.betweenQuestions ? "READY" : "WAITING");
      if (quizzerData.length === 0) {
        setQuizzerData(params.quizzers);
        quizzerDataRef.current = params.quizzers;
      }
    });

    // Video streams and connections to users
    const connectToNewUser = (userId: string, stream: any) => {
      setTimeout(() => {
        const call = myPeer.call(userId, stream);

        const video = document.createElement("video");
        video.id = `video-${userId}`; // The PeerJs id of the person we're calling
        video.muted = ["ASKING", "ANSWERING", "JUMPED"].includes(phase);
        call.on("stream", (userVideoStream: any) => {
          addVideoStream(video, userVideoStream);
        });
        call.on("close", () => {
          video.pause();
          video.remove();
        });

        peers[userId] = call;
      }, 1000);
    };
    const addVideoStream = (video: any, stream: any) => {
      video.srcObject = stream;
      video.addEventListener("loadedmetadata", () => {
        video.play();
      });
      if (videoGrid && !document.getElementById(video.id))
        videoGrid.append(video);
    };

    socket.on("ASK_QUESTION", (params) => {
      setPhase("ASKING");
      currentQuestionRef.current = params;
      currentWord.current = 0;
      setStartReadingQuestion(Date.now());
      setNoQuestion(false);
      const newWords: string[] = [
        "3...",
        "2...",
        "1...",
        "According",
        "to",
        ...(params.displayBookName ? [material[params.book].bookName] : []),
        String(params.chapter + 1) + ":",
        String(params.verse + 1),
        ...params.question.split(" "),
      ];
      words.current = newWords;
      setScreenText("");
      realScreenText.current = "";

      // Mute everyone
      quizzerDataRef.current.forEach((quizzer: any) => {
        const quizzerVideo: HTMLVideoElement | null = document.getElementById(
          `video-${quizzer.quizzerId}`
        ) as HTMLVideoElement;
        if (quizzerVideo) quizzerVideo.muted = true;
      });
    });
    socket.on("QUIZZER_WON_JUMP", (params) => {
      clearInterval(quizmasterTimer.current);
      clearTimeout(reactionTimer.current);
      const quizzer = quizzerDataRef.current.find(
        (quizzerItem) => quizzerItem.quizzerId === params.quizzerId
      );
      const newText =
        (params.received || realScreenText.current) +
        ` ...finish the question, ${quizzer?.quizzerName || "Quizzer"}`;
      setScreenText(newText);
      realScreenText.current = newText;
      setPhase("ANSWERING");
      setQuizzerAnswering(params.quizzerId);
      setTimeTaken(0);
      setStartTimekeeper(Date.now());
      setMyVote("NONE");

      // unmute the quizzer who won the jump
      const quizzerVideo: HTMLVideoElement | null = document.getElementById(
        `video-${params.quizzerId}`
      ) as HTMLVideoElement;
      if (quizzerVideo) quizzerVideo.muted = false;
    });
    socket.on("TIME_RAN_OUT", () => {
      if (phase === "RULING") return;
      setPhase("RULING");
      clearInterval(timekeeperTimer.current);
      const newText = (
        <>
          {realScreenText.current}&nbsp;&nbsp;&nbsp;Time!
          <br />
          <br /> {getQuestionJSX()}
        </>
      );
      setScreenText(newText);
      realScreenText.current = newText;

      // Unmute everyone
      quizzerDataRef.current.forEach((quizzer: any) => {
        const quizzerVideo: HTMLVideoElement | null = document.getElementById(
          `video-${quizzer.quizzerId}`
        ) as HTMLVideoElement;
        if (quizzerVideo) quizzerVideo.muted = false;
      });
    });
    socket.on("VOTE_UPDATE", (params) => {
      //setVoteStatus(params.votes);
    });
    socket.on("MAKE_RULING", (params) => {
      setQuizzerDetails(params.quizzerDetails || quizzerDetails);
      setPhase("READY");
      clearInterval(timekeeperTimer.current);
      let newScreenText = getQuestionJSX();
      const resultText = (
        <>
          {Types.voteTypeDescriptions[params.ruling as Types.VoteType] ||
            params.ruling}
          <br />
          {newScreenText}
        </>
      );
      setScreenText(resultText);
      realScreenText.current = resultText;

      // Unmute everyone
      quizzerDataRef.current.forEach((quizzer: any) => {
        const quizzerVideo: HTMLVideoElement | null = document.getElementById(
          `video-${quizzer.quizzerId}`
        ) as HTMLVideoElement;
        if (quizzerVideo) quizzerVideo.muted = false;
      });
    });
    socket.on("USER_MUTED", (params) => {
      const quizzerVideo: HTMLVideoElement | null = document.getElementById(
        `video-${params.quizzerId}`
      ) as HTMLVideoElement;
      if (!quizzerVideo) return;
      // mute/unmute this quizzer
      if (params.muted) {
        quizzerVideo.muted = true;
      } else {
        // Make sure we are in a state where this person could be unmuted.
        if (
          ["READY", "RULING"].includes(phase) ||
          (phase === "ANSWERING" && params.quizzerId === quizzerAnswering)
        )
          quizzerVideo.muted = false;
      }
    });
    return () => {
      myPeer.disconnect();
      myPeer.destroy();
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  const getMainContent = () => {
    if (!socketIO.current) return "Multiplayer Quiz Loading...";
    if (phase === "WAITING") {
      return (
        <p>
          A question is in progress. Once it has finished, you will enter the
          quiz.
        </p>
      );
    }
    return (
      <>
        <div style={{ height: 300 }}>{screenText}</div>
        <div style={{ marginTop: 20, marginBottom: 40 }}>
          <div style={{ display: "flex", fontSize: 16 }}>
            <div style={{ width: statsColumnWidths[0] }}>Name</div>
            <div style={{ width: statsColumnWidths[1] }}>Correct</div>
            <div style={{ width: statsColumnWidths[2] }}>Errors</div>
          </div>
          {quizzerData.map((quizzer: any) => {
            const details = quizzerDetails[quizzer.quizzerId] || {};

            return (
              <div
                style={{ display: "flex", fontSize: 14 }}
                key={quizzer.quizzerId}
              >
                <div style={{ width: statsColumnWidths[0] }}>
                  {quizzer.quizzerName}
                </div>
                <div style={{ width: statsColumnWidths[1] }}>
                  {details.TWENTY_POINTS || 0}
                </div>
                <div style={{ width: statsColumnWidths[2] }}>
                  {details.ERROR || 0}
                </div>
              </div>
            );
          })}
        </div>
        {phase === "ASKING" && !noQuestion && (
          <div>
            <button
              className="clickable"
              style={{ marginTop: 100 }}
              onClick={() => {
                setStartReacting(Date.now());
                clearTimeout(waitTimer.current);
              }}
            >
              Jump
            </button>
          </div>
        )}
        {phase === "READY" && (
          <div>
            <button
              className="clickable"
              onClick={() => {
                if (socketIO.current) {
                  socketIO.current.emit("NEXT_QUESTION");
                }
              }}
            >
              Ask Question
            </button>
          </div>
        )}
        {(phase === "ANSWERING" || phase === "RULING") &&
          (quizzerAnswering !== myId || phase === "RULING") && (
            <>
              <div style={{ marginTop: 70 }}>{`Vote on whether ${
                quizzerData.find(
                  (quizzer) => quizzer.quizzerId === quizzerAnswering
                )?.quizzerName || "the quizzer"
              } gets 20 Points or an Error! Your currect vote: ${
                Types.voteTypeDescriptions[myVote]
              }`}</div>
              <Row style={{ marginTop: 10 }}>
                <button
                  className="clickable"
                  onClick={() => {
                    setMyVote("TWENTY_POINTS");
                    if (socketIO.current)
                      socketIO.current.emit("VOTE", { vote: "TWENTY_POINTS" });
                  }}
                >
                  Twenty Points
                </button>
                <button
                  className="clickable"
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    setMyVote("ERROR");
                    if (socketIO.current)
                      socketIO.current.emit("VOTE", { vote: "ERROR" });
                  }}
                >
                  Error
                </button>
                {phase !== "RULING" && (
                  <div style={{ marginLeft: 50, marginTop: 5 }}>
                    {`Time taken: ${timeTaken}`}
                  </div>
                )}
              </Row>
            </>
          )}
        {phase === "ANSWERING" && quizzerAnswering !== myId && (
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            {getQuestionJSX()}
          </div>
        )}
        {["WAITING", "READY"].includes(phase) && (
          <div style={{ marginTop: 20 }}>
            <button
              className="back-button"
              onClick={() => {
                navigate("/multiplayer/individual");
              }}
            >
              Go Back
            </button>
          </div>
        )}
      </>
    );
  };
  return (
    <div id="multiplayer-individual-page" className="page">
      {getMainContent()}
      <div
        style={{
          position: "fixed",
          bottom: 10,
          left: 10,
        }}
      >
        <span>{`Microphone ${
          muted
            ? "MUTED"
            : ["READY", "RULING"].includes(phase) ||
              (phase === "ANSWERING" && quizzerAnswering === myId)
            ? "ON"
            : "OFF"
        }`}</span>
        <button
          className="link"
          onClick={() => {
            if (socketIO.current) {
              socketIO.current.emit("MUTE", { muted: !muted });
              setMuted(!muted);
            }
          }}
        >
          {`${muted ? "Un-" : ""}Mute`}
        </button>
      </div>
    </div>
  );
}
