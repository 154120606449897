import { Dispatch } from "redux";
import {
  SET_REQUEST_LOADING,
  SET_REQUEST_RESULT,
} from "../reducers/appDetails";

export async function fetchWrapper<E extends object = any>(
  dispatch: Dispatch | undefined,
  method: string,
  url: string,
  body: object = {},
  headers: any = {}
): Promise<ResponseStructure<E>> {
  try {
    if (dispatch)
      dispatch({
        type: SET_REQUEST_LOADING,
      });

    const checkOnline = async () => {
      if (window.navigator.onLine === false) {
        const result: ResponseStructure = {
          returnCode: 1,
          errorMessage: "You do not have internet connection.",
          responseMessage: {},
          returnType: "FATAL",
        };
        if (dispatch)
          dispatch({
            type: SET_REQUEST_RESULT,
            payload: {
              returnCode: result.returnCode,
              errorMessage: result.errorMessage,
              success: false,
            },
          });
        return result;
      }
    };
    const onlineResult = await checkOnline();
    if (onlineResult) return onlineResult;

    const res = await fetch(`/api/${url}`, {
      method,
      headers: {
        "Content-Type": "application/json",
        credentials: "include",
        ...headers,
      },
      body: method !== "GET" ? JSON.stringify(body) : undefined,
    });
    const data: ResponseStructure<E> = await res.json();
    if (dispatch)
      dispatch({
        type: SET_REQUEST_RESULT,
        payload: {
          returnCode: data.returnCode,
          errorMessage: data.errorMessage,
          success: data.returnCode === 0,
        },
      });
    return data;
  } catch {
    return {
      returnCode: 1,
      returnType: "FATAL",
      errorMessage: "",
      responseMessage: {} as E,
    };
  }
}

export interface ResponseStructure<E = any> {
  returnCode: number;
  returnType: "GOOD" | "FATAL";
  errorMessage: string;
  responseMessage: E;
}
