import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction, Action } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import thunk from "redux-thunk";

const middleware = [thunk];

export type RootStore = ReturnType<typeof rootReducer>;

export type ThunkActionType<ReturnType = void> = ThunkAction<
  Promise<ReturnType>,
  RootStore,
  null,
  Action<String>
>;
export type ThunkDispatchType<Actions extends Action = AnyAction> =
  ThunkDispatch<RootStore, null, Actions>;

export const configureRedux = () =>
  configureStore({
    reducer: rootReducer,
    middleware,
  });

const store = configureRedux();
export default store;
