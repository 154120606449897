import {
  CSSProperties,
  KeyboardEvent,
  ReactElement,
  useMemo,
  useState,
} from "react";
import { Input, Select, MenuItem } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { EnumDescription } from "../types/generalTypes";
import { enumForEach } from "../util/generalUtil";

const getInputId = (caption: string, id: string | undefined) =>
  id || `input-${caption}`;

interface InputCommonProps {
  caption: string;
  style?: CSSProperties;
  belowText?: string;
  afterInput?: React.ReactNode;
  spacing?: boolean;
  id?: string;
  "data-testid"?: string;
}
interface InputContainerProps extends InputCommonProps {
  children: React.ReactNode;
}
function InputContainer(props: InputContainerProps) {
  return (
    <div
      style={{
        marginTop: props.spacing !== false ? 10 : undefined,
        ...props.style,
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            marginRight: 5,
            marginTop: 4,
            width: props.spacing !== false ? 170 : undefined,
          }}
        >
          <label htmlFor={getInputId(props.caption, props.id)}>
            {props.caption}
          </label>
        </div>
        {props.children}
        {props.afterInput}
      </div>
      {props.belowText && (
        <p style={{ margin: 0, fontSize: 12 }}>{props.belowText}</p>
      )}
    </div>
  );
}

interface StringInputProps extends InputCommonProps {
  value: string | undefined;
  type?: string;
  onChange: (value: string) => void;
  onKeyUp?: (e: KeyboardEvent<any>) => void;
  onFocus?: (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
  ) => void;
  placeholder?: string;
  inputStyle?: CSSProperties;
  id?: string;
}
export function SimpleStringInput(props: StringInputProps) {
  return (
    <Input
      id={props.id || getInputId(props.caption, props.id)}
      value={props.value || ""}
      onChange={(e) => props.onChange(e.target.value)}
      onFocus={props.onFocus}
      placeholder={props.placeholder}
      style={{ width: 250, ...props.inputStyle }}
      type={props.type}
      onKeyUp={props.onKeyUp}
      data-testid={props["data-testid"]}
    />
  );
}
export function StringInput(props: StringInputProps) {
  return (
    <InputContainer {...props}>
      <SimpleStringInput {...props} />
    </InputContainer>
  );
}

interface NumberInputProps extends InputCommonProps {
  value: number | undefined;
  onChange: (value: number) => void;
  placeholder?: string;
  inputStyle?: CSSProperties;
}
export function SimpleNumberInput(props: NumberInputProps) {
  const getDefaultValue = () =>
    props.value !== undefined ? String(props.value) : "";
  const [value, setValue] = useState<string>(getDefaultValue());

  return (
    <Input
      id={getInputId(props.caption, props.id)}
      value={value}
      onChange={(e) => {
        const newValue = e.target.value;
        setValue(newValue);
        const newNumber = Number.parseInt(newValue || "0");
        if (!isNaN(newNumber)) props.onChange(newNumber);
      }}
      onBlur={() => setValue(getDefaultValue())}
      placeholder={props.placeholder}
      type="number"
      style={{ width: 250, ...props.inputStyle }}
      data-testid={props["data-testid"]}
    />
  );
}
export function NumberInput(props: NumberInputProps) {
  return (
    <InputContainer {...props}>
      <SimpleNumberInput {...props} />
    </InputContainer>
  );
}

interface SelectProps<E extends string = string> extends InputCommonProps {
  value: E | undefined;
  values: E[] | EnumDescription<E>;
  onChange: (value: E) => void;
  placeholder?: string;
  noneText?: string;
  dropdownStyle?: CSSProperties;
}
export function SimpleSelection<E extends string>(props: SelectProps<E>) {
  const trueValues = useMemo(() => {
    const result: ReactElement[] = [];
    if (Array.isArray(props.values)) {
      props.values.forEach((value) =>
        result.push(
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        )
      );
    } else {
      enumForEach(props.values, (key, value) => {
        result.push(
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        );
      });
    }
    if (props.noneText) {
      result.splice(
        0,
        0,
        <MenuItem key="none" value="">
          {props.noneText}
        </MenuItem>
      );
    }
    return result;
  }, [props.values]);

  return (
    <Select
      value={props.value || ""}
      onChange={(e) => {
        props.onChange(e.target.value as E);
      }}
      displayEmpty
      renderValue={(value) => {
        if (value === "" && props.noneText) return props.noneText;
        if (!value && !("" in props.values)) return props.placeholder;
        if (Array.isArray(props.values)) return value;
        return props.values[value];
      }}
      style={{ width: 250, height: 32, ...props.dropdownStyle }}
      id={getInputId(props.caption, props.id)}
      inputProps={{
        id: getInputId(props.caption, props.id),
      }}
      data-testid={props["data-testid"]}
    >
      {trueValues}
    </Select>
  );
}
export function Selection<E extends string>(props: SelectProps<E>) {
  return (
    <InputContainer {...props}>
      <SimpleSelection {...props} />
    </InputContainer>
  );
}

interface DateInputProps extends InputCommonProps {
  value: string | undefined;
  onChange: (value: string) => void;
}
export function DateInput(props: DateInputProps) {
  return (
    <InputContainer {...props}>
      <DatePicker
        slotProps={{ textField: { size: "small", style: { width: 250 } } }}
        value={props.value ? dayjs(props.value) : null}
        onChange={(value) => {
          if (value) props.onChange(value.format("YYYY-MM-DD"));
        }}
        data-testid={props["data-testid"]}
      />
    </InputContainer>
  );
}
