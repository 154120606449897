import { useNavigate } from "react-router-dom";
import { Row } from "../../../components/Row";
import { useDataProvider } from "../../../hooks/dataProvider";
import { useKeyedParams } from "../../../hooks/useParams";
import {
  getPage,
  setSelectedPage,
} from "../../../state/actions/tournamentActions";
import { TournamentPage } from "../../../types/tournamentTypes";
import { EditPageForm } from "./EditPageForm";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";

interface EditProps {
  pageId: string;
  secondaryId?: string;
}

export function EditTournamentWrapper() {
  const { pageId, secondaryId } = useKeyedParams<EditProps>();
  const provider = useDataProvider({
    id: `${pageId}${secondaryId ? `/${secondaryId}` : ""}`.replace(/_/g, " "),
    getId: (tournament) =>
      `${tournament.pageId}${
        tournament.secondaryId ? `/${tournament.secondaryId}` : ""
      }`,
    dispatchAction: setSelectedPage,
    selector: (state) => state.tournaments.selectedPage,
    getData: getPage,
    fallbackUrl: "/tournaments",
    render: (page) => {
      return <EditTournamentPage page={page} />;
    },
  });
  return provider.getPage();
}

interface Props {
  page: TournamentPage;
}
function EditTournamentPage(props: Props) {
  useDocumentTitle(
    `Editing ${props.page.pageId}${
      props.page.secondaryId ? ` ${props.page.secondaryId}` : ""
    } - BQA Tournaments`
  );
  const navigate = useNavigate();
  return (
    <div className="page">
      <Row>
        <button
          className="back-button"
          style={{ marginRight: 20 }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Go Back
        </button>
        <h1>Edit Page</h1>
      </Row>
      <EditPageForm mode="UPDATE" page={props.page} />
    </div>
  );
}
