import { useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { Check } from "../Check/Check";
import * as skTypes from "../../types/sk";
import * as colors from "../../util/colors";
import { NumberInput, SimpleSelection, StringInput } from "../Inputs";

interface Props {
  quizmasterList?: string[];
  data: Partial<skTypes.Quiz>;
  onUpdateDetails: (key: keyof skTypes.Quiz, value: any) => void;
}

export function QuizMetadataForm(props: Props) {
  const { user } = useSelector((state: RootStore) => state.authentication);

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <StringInput
          caption="Quiz Name:"
          value={props.data.quizName || ""}
          onChange={(value) => props.onUpdateDetails("quizName", value)}
          belowText="Quiz name is optional - if it is not supplied, the round and site will be used instead."
          data-testid="sk-quiz-name"
        />
        <NumberInput
          caption="Round:"
          value={props.data.round}
          onChange={(value) => props.onUpdateDetails("round", value)}
          data-testid="sk-round"
        />
        <NumberInput
          caption="Site:"
          value={props.data.site}
          onChange={(value) => props.onUpdateDetails("site", value)}
          data-testid="sk-site"
        />
        <StringInput
          caption="Quizmaster:"
          value={props.data.quizmaster}
          placeholder="Quizmaster"
          onChange={(value) => props.onUpdateDetails("quizmaster", value)}
          afterInput={
            <SimpleSelection
              caption="qm-dropdown"
              dropdownStyle={{ marginLeft: 5 }}
              value=""
              placeholder="Select a Quizmaster"
              onChange={(value) => props.onUpdateDetails("quizmaster", value)}
              values={["", ...(props.quizmasterList || [])]}
            />
          }
          data-testid="sk-qm"
        />
      </div>
      {(user !== undefined || props.data.watchability === "PUBLIC") && (
        <div
          style={{
            border: `4px solid ${colors.bqaDarkGrey}`,
            padding: 10,
            marginBottom: 15,
          }}
        >
          <Check
            checked={props.data.watchability === "PUBLIC"}
            onClick={(checked: boolean) => {
              props.onUpdateDetails(
                "watchability",
                checked ? "PUBLIC" : "PRIVATE"
              );
            }}
            style={{ width: 200, marginTop: 10, marginBottom: 15 }}
          >
            Broadcast Publicly
          </Check>
          {props.data.watchability === "PUBLIC" && (
            <div style={{ marginBottom: 15, fontSize: 12 }}>
              By broadcasting this quiz, you authorize anyone to see it live.
            </div>
          )}
        </div>
      )}
    </>
  );
}
