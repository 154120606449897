import { useRef, useState } from "react";
import io from "socket.io-client";
import {
  getEventsForTime,
  getQuizIdForTime,
} from "../../state/actions/skActions";
import { RequestHandlerReturn } from "../../hooks/requestHandler";

interface QuizTimerProps {
  account: string;
  round: number;
  site: number;
  handler: RequestHandlerReturn;
}

export function QuizTimer(props: QuizTimerProps) {
  const socketIO = useRef<any>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [time, setTime] = useState<string>("");
  const [connectionInfo, setConnectionInfo] = useState<{
    event?: number;
    events: { eventName: string; eventId: number }[];
  }>();

  if (isConnected) {
    return (
      <span
        onDoubleClick={() => {
          setIsConnected(false);
        }}
      >
        {time}
      </span>
    );
  }
  if (connectionInfo) {
    return (
      <>
        <select
          value={connectionInfo.event}
          onChange={async (e) => {
            const eventId = e.target.value;
            const quizId = await getQuizIdForTime(
              props.account,
              eventId,
              props.round,
              props.site
            );
            if (!quizId) {
              props.handler.showMessage("This quiz is not available.", "error");
              return;
            }
            const socket = io("https://quizzing.live");
            socket.on("connect", () => {
              socket.emit(
                "register_for_time_updates",
                props.account,
                String(quizId)
              );
              socket.on("time_update", setTime);
            });
            socketIO.current = socket;

            setIsConnected(true);
          }}
        >
          <option value="">Select an Event</option>
          {connectionInfo.events.map((e) => (
            <option key={e.eventId} value={e.eventId}>
              {e.eventName}
            </option>
          ))}
        </select>
        <br />
        <span>Make sure your round and site are correct.</span>
      </>
    );
  }
  return (
    <button
      className="link"
      onClick={async () => {
        const events = await getEventsForTime(props.account);
        if (events.length === 0) {
          props.handler.showMessage("Live time is not available.", "error");
        } else {
          setConnectionInfo({
            events,
          });
        }
      }}
    >
      Live Time
    </button>
  );
}
