import { Dispatch } from "redux";
import { TokenData } from "../../types/generalTypes";
import { User } from "../../types/userTypes";
import { ThunkActionType } from "../store";
import { fetchWrapper } from "./fetchWrapper";

export const SET_LOGIN = "SET_LOGIN";
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";

export interface SetLogin {
  type: typeof SET_LOGIN;
  payload: {
    user: User;
    tokenData: TokenData;
  };
}
export interface SetLogout {
  type: typeof SET_LOGOUT;
}

export type AuthenticationActions = SetLogin | SetLogout;

export const authenticate =
  (username: string, password: string): ThunkActionType =>
  async (dispatch: Dispatch) => {
    const data = await fetchWrapper(dispatch, "POST", "security/login", {
      username,
      password,
    });
    if (data.returnCode === 0) {
      dispatch(setLogin(data.responseMessage.user, data.responseMessage.token));
      localStorage.setItem(
        "auth-status",
        JSON.stringify({
          user: data.responseMessage.user,
          tokenData: data.responseMessage.token,
        })
      );
    }
  };

export const logOut = (): ThunkActionType => async (dispatch: Dispatch) => {
  await fetchWrapper(dispatch, "POST", "security/signout", {});
};

export const confirmUsername =
  (email: string): ThunkActionType =>
  async (dispatch: Dispatch) => {
    await fetchWrapper(dispatch, "POST", "security/confirmusername", {
      email,
    });
  };

export const requestPasswordReset =
  (usernameOrEmail: string): ThunkActionType =>
  async (dispatch: Dispatch) => {
    const data = await fetchWrapper(
      dispatch,
      "POST",
      "security/requestpasswordreset",
      {
        usernameOrEmail,
      }
    );
    if (data.returnCode === 0) {
      dispatch(setLogin(data.responseMessage.user, data.responseMessage.token));
    }
  };

export const createNewPassword =
  (userId: string, challengeKey: string, password: string): ThunkActionType =>
  async (dispatch: Dispatch) => {
    const data = await fetchWrapper(
      dispatch,
      "POST",
      "security/resetpassword",
      {
        userId,
        challengeKey,
        password,
      }
    );
    if (data.returnCode === 0) {
      dispatch(setLogin(data.responseMessage.user, data.responseMessage.token));
    }
  };

export const createUser =
  (username: string, email: string): ThunkActionType =>
  async (dispatch: Dispatch) => {
    await fetchWrapper(dispatch, "POST", "security/createuser", {
      user: {
        username,
        email,
      },
    });
  };

export const updateUser =
  (user: User): ThunkActionType =>
  async (dispatch: Dispatch) => {
    await fetchWrapper(dispatch, "PATCH", "security/updateuser", {
      user,
    });
  };
export const setLogin = (user: User, tokenData: TokenData): SetLogin => ({
  type: SET_LOGIN,
  payload: {
    user,
    tokenData,
  },
});
