import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "./state/store";
import { SET_SELECTED_CHAPTERS } from "./state/reducers/settings";
import { SET_MATERIAL } from "./state/reducers/material";
import { Book } from "./types/material";

// Ensures that we have material loaded. If we don't, display an app-level loading screen
interface Props {
  children: React.ReactElement;
}
export default function MaterialProvider(props: Props) {
  const dispatch = useDispatch();

  const settings = useSelector((state: RootStore) => state.settings);
  const { material } = useSelector((state: RootStore) => state.material);

  useEffect(() => {
    const loadMaterial = async () => {
      const data = require("./res/material.json");
      dispatch({
        type: SET_MATERIAL,
        payload: {
          material: data,
        },
      });
      const payloadResult = {
        type: SET_SELECTED_CHAPTERS,
        payload: {
          chapters: settings.selectedChapters,
          skBooks: settings.selectedSKBooks,
        },
      };

      if (!settings.selectedChapters?.length) {
        const emptyChapterArray: boolean[][] = [];
        data.forEach((book: Book) => {
          emptyChapterArray.push(Array(book.chapters.length).fill(false));
        });
        payloadResult.payload.chapters = emptyChapterArray;
      }
      if (!settings.selectedSKBooks?.length) {
        const emptySKChapterArray: boolean[] = [];
        data.forEach(() => {
          emptySKChapterArray.push(false);
        });
        payloadResult.payload.skBooks = emptySKChapterArray;
      }
      dispatch(payloadResult);
    };
    if (material.length === 0) loadMaterial();
  }, [material]);

  if (material.length === 0) {
    return <div>BQA Loading...</div>;
  }

  return props.children;
}
