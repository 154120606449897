import { ReactElement } from "react";
import { useEffect, useState } from "react";
import * as skTypes from "../../../types/sk";
import * as utils from "../../../util/skUtils";
import { useSelector } from "react-redux";
import { RootStore } from "../../../state/store";
import "../QuizSite.scss";

interface Props {
  boxScore: skTypes.BoxScore;
  teams: skTypes.Team[];
  onClose: () => void;
  reverse: boolean;
}
export function ShowTeam(props: Props) {
  const { skAdvanced } = useSelector((state: RootStore) => state.settings);
  const [rendered, setRendered] = useState<boolean>(false);

  useEffect(() => {
    // close on click, but wait to fire the event until the thing has actually loaded
    if (rendered) window.addEventListener("click", props.onClose);
    if (!rendered) setRendered(true);
    return () => window.removeEventListener("click", props.onClose);
  });

  const getTeamScoreDisplay = (): ReactElement[] => {
    const result: ReactElement[] = props.boxScore.teams.map(
      (team: skTypes.TeamBoxScore, index: number) => {
        let bonusPotential: boolean = false;
        if (team.quizzersAnswering >= 4) {
          team.quizzers.forEach(
            (quizzer: skTypes.QuizzerBoxScore, quizzerIndex: number) => {
              if (
                utils.isQuizzerEligable(props.boxScore, index, quizzerIndex) &&
                quizzer.points === 0
              )
                bonusPotential = true;
            }
          );
        }

        const quizTeam = props.teams[index];
        return (
          <div
            key={team.teamId}
            style={{
              width: `calc((100% / ${props.teams.length}) - 2px)`,
              padding: 20,
              textAlign: "center",
            }}
          >
            <div
              className="no-wrap-text"
              style={{
                fontSize: 48,
                color:
                  bonusPotential &&
                  team.eligable &&
                  skAdvanced &&
                  props.boxScore.quizStatus !== "ENDED"
                    ? "green"
                    : undefined,
              }}
            >
              {quizTeam.nickname || quizTeam.teamName}
            </div>
            <div
              className={
                team.eligable &&
                skAdvanced &&
                props.boxScore.quizStatus !== "ENDED"
                  ? `${
                      team.errors >= 3
                        ? team.errors >= 6
                          ? "high-errors-show"
                          : "middle-errors-show"
                        : ""
                    }`
                  : ""
              }
              style={{
                fontSize: 160,
                textAlign: "center",
              }}
            >
              {team.points}
            </div>
          </div>
        );
      }
    );
    if (props.reverse) {
      return result;
    } else {
      return result.reverse();
    }
  };
  return (
    <div
      className="page"
      style={{ paddingTop: "20vh", minWidth: 850, display: "flex" }}
    >
      {getTeamScoreDisplay()}
    </div>
  );
}
