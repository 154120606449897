import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar, CircularProgress, Alert, AlertColor } from "@mui/material";
import { RootStore, ThunkActionType } from "../state/store";

export interface RequestHandlerReturn {
  snackbar: React.ReactElement | null; // The current message
  runRequest: (
    action: ThunkActionType,
    loading?: string, // Message to be displayed immediately
    success?: string, // Message displayed if the request is successful
    key?: string // key denoting the type of request
  ) => void;
  showMessage: (text: string, type: AlertColor) => void;
  requestInProgress: boolean; // If there is a request currently in progress
  currentKey: string; // The key of the current request
}

export const useRequestHandler = (options: {
  onSuccess?: (
    // Callback when request is finished
    key: string // the key of the request that was run
  ) => void;
  onError?: (
    key: string, // the key of the request that was run
    returnCode: number
  ) => void;
  loading?: (state: RootStore) => boolean;
  assumeCachedInfo?: boolean; // Assumes data will be cached, so success messages will always be displayed
  alwaysSend?: boolean; // If true, requests won't wait for each other to finish.
}): RequestHandlerReturn => {
  const dispatch = useDispatch();
  const requestLoading = useSelector(
    options?.loading || ((state: RootStore) => state.appDetails.requestLoading)
  );
  const { errorMessage, requestSuccess, returnCode } = useSelector(
    (state: RootStore) => state.appDetails
  );
  const [messageVisible, setMessageVisible] = useState<boolean>(false);
  const [messageText, setMessageText] = useState<string>("");
  const [messageColor, setMessageColor] = useState<AlertColor>("success");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const resultMessages = useRef<{
    success?: string;
    key: string;
  }>({
    key: "",
  });

  // displays a message and denotes current request
  const runRequest = async (
    action?: ThunkActionType,
    loading?: string,
    success?: string,
    key: string = ""
  ) => {
    if (isLoading && !options.alwaysSend) return;
    setIsLoading(true);

    if (loading) {
      setMessageText(loading);
      setMessageColor("info");
      setMessageVisible(true);
    }
    setIsLoading(true);
    resultMessages.current = {
      success,
      key,
    };
    dispatch(action);
  };

  useEffect(() => {
    if (!requestLoading && isLoading) {
      if (requestSuccess || options.assumeCachedInfo) {
        // Success message
        setMessageText(resultMessages.current.success || "Success!");
        setMessageColor("success");
        setMessageVisible(true);
        if (options.onSuccess) options.onSuccess(resultMessages.current.key);
      } else {
        // Fail message
        setMessageText(errorMessage);
        setMessageColor("error");
        setMessageVisible(true);
        if (options.onError)
          options.onError(resultMessages.current.key, returnCode);
      }
      setIsLoading(false);
      resultMessages.current = {
        success: "",
        key: "",
      };
    }
  }, [requestLoading]);

  return {
    requestInProgress: isLoading,
    runRequest,
    currentKey: resultMessages.current.key,
    snackbar:
      messageText && messageVisible ? (
        <Snackbar
          open={messageVisible}
          autoHideDuration={isLoading ? 10000 : 3000}
          onClose={() => {
            setMessageVisible(false);
            setMessageText("");
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          style={{ backgroundColor: "white" }}
        >
          {isLoading ? (
            <div style={{ margin: "10px 20px" }}>
              <CircularProgress size={20} />
              <span style={{ marginTop: -10, marginLeft: 8, color: "black" }}>
                {messageText}
              </span>
            </div>
          ) : (
            <Alert
              severity={messageColor}
              style={{ backgroundColor: "inherit" }}
            >
              {messageText}
            </Alert>
          )}
        </Snackbar>
      ) : null,
    showMessage: (text, type) => {
      setMessageText(text);
      setMessageColor(type);
      setMessageVisible(true);
    },
  };
};
