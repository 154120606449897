import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootStore } from "../../../state/store";
import { Doughnut } from "react-chartjs-2";
import { Row } from "../../../components/Row";
import * as skTypes from "../../../types/sk";
import { getDoughnutOptions } from "../../../util/Chart";
import { useKeyedParams } from "../../../hooks/useParams";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";

interface StatsProps {
  seasonId: string;
}

export function SingleChapterStats() {
  useDocumentTitle("Chapter Stats - Bible Quiz Academy");
  const navigate = useNavigate();
  const location = useLocation();
  const { seasonId } = useKeyedParams<StatsProps>();
  const { material } = useSelector((state: RootStore) => state.material);
  const lightMode = useSelector((state: RootStore) => state.settings.lightMode);

  const stats: skTypes.ChapterStats | undefined = location.state?.chapterStats;

  if (!stats) {
    return (
      <Navigate to={`/sk/${seasonId}/chapterstats`} state={location.state} />
    );
  }

  const questionsByType: skTypes.QuestionType[] = Object.keys(
    stats.questionsByType
  ) as skTypes.QuestionType[];
  const jumpsByType: skTypes.JumpType[] = Object.keys(
    stats.jumpsByType
  ) as skTypes.JumpType[];

  return (
    <div className="page">
      <Row>
        <button className="back-button" onClick={() => navigate(-1)}>
          Go Back
        </button>
        <h1 style={{ marginLeft: 10 }}>{`Chapter Stats - ${
          material[stats.book].bookName
        } ${stats.chapter + 1}`}</h1>
      </Row>

      <Row style={{ marginTop: 10 }}>
        <div>
          <h2>Questions</h2>
          <Doughnut
            style={{ height: 150 }}
            data={{
              labels: questionsByType.map((key: skTypes.QuestionType) => {
                return (
                  // @ts-ignore
                  skTypes.momentTypeDescriptions[key] ||
                  // @ts-ignore
                  skTypes.errorDescription[key]
                );
              }),
              datasets: [
                {
                  data: Object.values(stats.questionsByType),
                  backgroundColor: questionsByType.map(
                    (key: skTypes.QuestionType) => skTypes.momentTypeColor[key]
                  ),
                  hoverOffset: 5,
                },
              ],
            }}
            options={getDoughnutOptions(lightMode)}
          />
        </div>
        <div>
          <h2>Jumps</h2>
          <Doughnut
            style={{ height: 150 }}
            data={{
              labels: jumpsByType.map((key: skTypes.JumpType) => {
                return skTypes.jumpTypeDescription[key];
              }),
              datasets: [
                {
                  data: Object.values(stats.jumpsByType),
                  backgroundColor: jumpsByType.map(
                    (key: skTypes.JumpType) => skTypes.jumpTypeColor[key]
                  ),
                  hoverOffset: 5,
                },
              ],
            }}
            options={getDoughnutOptions(lightMode)}
          />
        </div>
      </Row>
    </div>
  );
}
