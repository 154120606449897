import { CSSProperties } from "react";
import { Link } from "react-router-dom";

interface Props {
  style: CSSProperties;
  tab?: string;
}
export function HelpButton(props: Props) {
  return (
    <Link
      className="link"
      to={"/sk/help"}
      state={{
        helpItem: props.tab,
      }}
      style={{ ...props.style, fontSize: 24 } as CSSProperties}
      onClick={() => window.scrollTo(0, 0)}
    >
      ?
    </Link>
  );
}
