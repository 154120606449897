import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { TournamentLink } from "./TournamentLink";
import { getTournamentPageViews } from "../../state/actions/tournamentActions";
import { TournamentPage } from "../../types/tournamentTypes";

export function TournamentFooter(props: {
  page?: TournamentPage;
  pageId?: string;
  secondaryId?: string;
  linkText?: string;
}) {
  const dispatch = useDispatch();
  const { selectedPage } = useSelector((state: RootStore) => state.tournaments);
  const { user } = useSelector((state: RootStore) => state.authentication);

  const hasViews = props.page && selectedPage && "timesPulled" in selectedPage;
  return (
    <>
      <hr />
      <div style={{ fontSize: 12 }}>
        <p>
          This page is maintained by BQA. &nbsp;
          <TournamentLink pageId="About" text="About Tournaments" />
          &nbsp;
          <TournamentLink
            pageId="contact"
            navigateState={{
              contactInfo: {
                pageId: props.page?.pageId || props.pageId,
                secondaryId: props.page?.secondaryId || props.secondaryId,
                contactType: props.linkText ? "" : "ERROR",
              },
            }}
            text={props.linkText || "Report Error"}
          />
        </p>
        {props.page &&
          !hasViews &&
          user?.securityClearance.logonType === "ADMIN" && (
            <button
              className="link"
              onClick={() => {
                dispatch(getTournamentPageViews(props.page!));
              }}
              style={{ paddingLeft: 0 }}
            >
              Get Views
            </button>
          )}
        {hasViews && (
          <p>{`This page has been viewed ${selectedPage.timesPulled} time${
            selectedPage.timesPulled === 1 ? "" : "s"
          }.`}</p>
        )}
      </div>
    </>
  );
}
