import { TournamentPage } from "../../types/tournamentTypes";
import { getPageDescription } from "../../util/tournamentUtils";
import { TournamentText } from "./TournamentText";

interface Props {
  page: TournamentPage;
}
export function TPageDescription(props: Props) {
  const descriptionText = getPageDescription(props.page);
  return <TournamentText text={descriptionText} />;
}
