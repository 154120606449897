import { useState } from "react";
import { QuizMetadataForm } from "../../../components/SKComponents/QuizMetadataForm";
import { Row } from "../../../components/Row";
import * as skTypes from "../../../types/sk";

interface Props {
  quiz: skTypes.Quiz;
  quizmasterList?: string[];
  onSubmit: (quiz: skTypes.Quiz) => void;
  onDelete: () => void;
}

export function EditDetails(props: Props) {
  const [quiz, setQuiz] = useState<skTypes.Quiz>({ ...props.quiz });
  const [deleting, setDeleting] = useState<boolean>(false);

  return (
    <div className="page">
      <Row style={{ marginBottom: 20 }}>
        <button
          className="clickable"
          onClick={() =>
            props.onSubmit({
              ...quiz,
            })
          }
        >
          Back
        </button>
        <h1 style={{ marginLeft: 10 }}>Edit Quiz Details</h1>
        <button
          className="link"
          style={{ marginLeft: 40 }}
          onClick={() => {
            if (deleting) {
              props.onDelete();
            } else {
              setDeleting(true);
            }
          }}
          key={`delete-${deleting}`}
        >
          {deleting ? "Confirm Delete!?" : "Delete Quiz"}
        </button>
      </Row>
      <QuizMetadataForm
        data={quiz}
        quizmasterList={props.quizmasterList}
        onUpdateDetails={(key: string, value: string | number) => {
          setQuiz({
            ...quiz,
            [key]: value,
          });
        }}
      />
    </div>
  );
}
